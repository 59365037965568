import type { I18nOptions } from 'vue-i18n'
import type { App } from 'vue'
import { createI18n } from 'vue-i18n'
import { VxeUI } from 'vxe-table'
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
import enUS from 'vxe-table/lib/locale/lang/en-US'
import messages from './lang/index'
import { useSettingsStore } from '@/store'

function getLanguage() {
  const { getLanguage } = useSettingsStore(createPinia())
  return getLanguage || 'zh-CN'
}
// 注册语言
VxeUI.setI18n('zh-CN', zhCN)
VxeUI.setI18n('en-US', enUS)

const msg = messages as I18nOptions['messages']
const i18n = createI18n({
  locale: getLanguage(),
  fallbackLocale: 'zh-CN',
  messages: msg,
  sync: true,
  legacy: false,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  missingWarn: false,
  fallbackWarn: false,
})
/** 国际化 */
export function setupI18n(app: App) {
  app.use(i18n)
  VxeUI.setLanguage(getLanguage())
}

export default i18n
