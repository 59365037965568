import { getStorage } from '@sjzy/utils'

const NODE_ENV = import.meta.env.MODE
console.log(`node_env ${NODE_ENV}`)

const VITE_BUILDENV = import.meta.env.VITE_BUILDENV
console.log(`vite_build_env ${VITE_BUILDENV}`)

const config = {
  // 内网配置
  dev: {
    'userApi': '//centerapi.dev.shijizhongyun.com',
    'payApi': '//centerapi.dev.shijizhongyun.com',
    'dataCenterApi': '//centerapi.dev.shijizhongyun.com',
    'interfaceApi': '//centerapi.dev.shijizhongyun.com',
    'reportApi': '//centerapi.dev.shijizhongyun.com',
    'customerApi': '//centerapi.dev.shijizhongyun.com',
    'contractApi': '//centerapi.dev.shijizhongyun.com',
    'financeApi': '//centerapi.dev.shijizhongyun.com',
    'flowApi': '//centerapi.dev.shijizhongyun.com',
    'commerceApi': '//centerapi.dev.shijizhongyun.com',
    'loggerApi': '//centerapi.dev.shijizhongyun.com',
    'processCenter': '//centerapi.dev.shijizhongyun.com',
    'uploadApi': '//uploadapi.dev.shijizhongyun.com',
    'kangaroo': '//apigateway.dev.shijizhongyun.com',
    'camsApi': '//centerapi.dev.shijizhongyun.com',
    'Store': '//camelapi.dev.shijizhongyun.com',
    // 'flowApi': '//centerapi.dev.shijizhongyun.com',
    // 'commerceApi': '//centerapi.dev.shijizhongyun.com',
    // 'loggerApi': '//centerapi.dev.shijizhongyun.com',
    // 'processCenter': '//centerapi.dev.shijizhongyun.com',
    // 'uploadApi': '//Upload.dev.shijizhongyun.com',
    'packetApi': '//smallapi.dev.shijizhongyun.com',
    'processApi': '//smallapi.dev.shijizhongyun.com',
    'customsApi': '//smallapi.dev.shijizhongyun.com',
    'channeltApi': '//smallapi.dev.shijizhongyun.com',
    'channeltApiBd': '//smallapi-bd.dev.shijizhongyun.com',
    'channeltApiBsi': '//smallapi-bsi.dev.shijizhongyun.com',
    'channeltApiYstd': '//smallapi-ylwems.dev.shijizhongyun.com',
    'priceApi': '//smallapi.dev.shijizhongyun.com',
    'priceApiBd': '//smallapi-bd.dev.shijizhongyun.com',
    'guideApiBd': '//smallapi-bd.dev.shijizhongyun.com',
    'warehouseApi': '//smallapi.dev.shijizhongyun.com',
    'problemTypeApi': '//smallapi.dev.shijizhongyun.com',
    'open-apis': '//centerapi.dev.shijizhongyun.com',
    'microAppCams': '//cams.dev.shijizhongyun.com',
    'microAppMid': '//middlebusiness.dev.shijizhongyun.com',
  },
  hbdev: {
    'userApi': '//centerapi.dev.huibaibio.com',
    'payApi': '//centerapi.dev.huibaibio.com',
    'dataCenterApi': '//centerapi.dev.huibaibio.com',
    'interfaceApi': '//centerapi.dev.huibaibio.com',
    'reportApi': '//centerapi.dev.huibaibio.com',
    'customerApi': '//centerapi.dev.huibaibio.com',
    'contractApi': '//centerapi.dev.huibaibio.com',
    'financeApi': '//centerapi.dev.huibaibio.com',
    'flowApi': '//centerapi.dev.huibaibio.com',
    'commerceApi': '//centerapi.dev.huibaibio.com',
    'loggerApi': '//centerapi.dev.huibaibio.com',
    'processCenter': '//centerapi.dev.huibaibio.com',
    'uploadApi': '//uploadapi.dev.huibaibio.com',

    'packetApi': '//smallapi.huibaibio.com',
    'processApi': '//smallapi.huibaibio.com',
    'customsApi': '//smallapi.huibaibio.com',
    'channeltApi': '//smallapi.huibaibio.com',
    'channeltApiBd': '//smallapi-bd.huibaibio.com',
    'priceApi': '//smallapi.huibaibio.com',
    'priceApiBd': '//smallapi-bd.huibaibio.com',
    'warehouseApi': '//smallapi.huibaibio.com',
    'problemTypeApi': '//smallapi.huibaibio.com',
    'open-apis': '//centerapi.dev.huibaibio.com',
    'microAppCams': '//cams.dev.huibaibio.com',
    'microAppMid': '//middlebusiness.dev.huibaibio.com',
  },
  // 测试配置
  test: {
    'userApi': '//centerapi.test.shijizhongyun.com',
    'payApi': '//centerapi.test.shijizhongyun.com',
    'dataCenterApi': '//centerapi.test.shijizhongyun.com',
    'interfaceApi': '//centerapi.test.shijizhongyun.com',
    'reportApi': '//centerapi.test.shijizhongyun.com',
    'customerApi': '//centerapi.test.shijizhongyun.com',
    'contractApi': '//centerapi.test.shijizhongyun.com',
    'financeApi': '//centerapi.test.shijizhongyun.com',
    'flowApi': '//centerapi.test.shijizhongyun.com',
    'commerceApi': '//centerapi.test.shijizhongyun.com',
    'loggerApi': '//centerapi.test.shijizhongyun.com',
    'processCenter': '//centerapi.test.shijizhongyun.com',
    'uploadApi': '//uploadapi.test.shijizhongyun.com',
    'open-apis': '//centerapi.test.shijizhongyun.com',
    'kangaroo': '//apigateway.test.shijizhongyun.com',
    'camsApi': '//centerapi.test.shijizhongyun.com',
    'Store': '//camelapi.test.shijizhongyun.com',
    'packetApi': '//smallapi.test.shijizhongyun.com',
    'processApi': '//smallapi.test.shijizhongyun.com',
    'customsApi': '//smallapi.test.shijizhongyun.com',
    'channeltApi': '//smallapi.test.shijizhongyun.com',
    'channeltApiBd': '//smallapi-bd.test.shijizhongyun.com',
    'channeltApiBsi': '//smallapi-bsi.test.shijizhongyun.com',
    'channeltApiYstd': '//smallapi-ylwems.test.shijizhongyun.com',
    'priceApi': '//smallapi.test.shijizhongyun.com',
    'priceApiBd': '//smallapi-bd.test.shijizhongyun.com',
    'warehouseApi': '//smallapi.test.shijizhongyun.com',
    'problemTypeApi': '//smallapi.test.shijizhongyun.com',
    'microAppCams': '//cams.test.shijizhongyun.com',
    'microAppMid': '//middlebusiness.test.shijizhongyun.com',
  },
  // 测试配置
  hbtest: {
    'userApi': '//centerapi-hb.test.shijizhongyun.com',
    'payApi': '//centerapi-hb.test.shijizhongyun.com',
    'dataCenterApi': '//centerapi-hb.test.shijizhongyun.com',
    'interfaceApi': '//centerapi-hb.test.shijizhongyun.com',
    'reportApi': '//centerapi-hb.test.shijizhongyun.com',
    'customerApi': '//centerapi-hb.test.shijizhongyun.com',
    'contractApi': '//centerapi-hb.test.shijizhongyun.com',
    'financeApi': '//centerapi-hb.test.shijizhongyun.com',
    'flowApi': '//centerapi-hb.test.shijizhongyun.com',
    'commerceApi': '//centerapi-hb.test.shijizhongyun.com',
    'loggerApi': '//centerapi.test.shijizhongyun.com',
    'processCenter': '//centerapi-hb.test.shijizhongyun.com',
    'uploadApi': '//uploadapi-hb.test.shijizhongyun.com',

    'packetApi': '//smallapi.test.shijizhongyun.com',
    'processApi': '//smallapi.test.shijizhongyun.com',
    'customsApi': '//smallapi.test.shijizhongyun.com',
    'channeltApi': '//smallapi.test.shijizhongyun.com',
    'channeltApiBd': '//smallapi-bd.test.shijizhongyun.com',
    'priceApi': '//smallapi.test.shijizhongyun.com',
    'priceApiBd': '//smallapi-bd.test.shijizhongyun.com',
    'warehouseApi': '//smallapi.test.shijizhongyun.com',
    'problemTypeApi': '//smallapi.test.shijizhongyun.com',
    'open-apis': '//centerapi-hb.test.shijizhongyun.com',
    'microAppCams': '//cams-hb.test.shijizhongyun.com',
    'microAppMid': '//middlebusiness-hb.test.shijizhongyun.com',
  },
  // pet配置
  pet: {
    'userApi': '//centerapi.pet.shijizhongyun.com',
    'payApi': '//centerapi.pet.shijizhongyun.com',
    'dataCenterApi': '//centerapi.pet.shijizhongyun.com',
    'interfaceApi': '//centerapi.pet.shijizhongyun.com',
    'reportApi': '//centerapi.pet.shijizhongyun.com',
    'customerApi': '//centerapi.pet.shijizhongyun.com',
    'contractApi': '//centerapi.pet.shijizhongyun.com',
    'financeApi': '//centerapi.pet.shijizhongyun.com',
    'flowApi': '//centerapi.pet.shijizhongyun.com',
    'commerceApi': '//centerapi.pet.shijizhongyun.com',
    'loggerApi': '//centerapi.pet.shijizhongyun.com',
    'processCenter': '//centerapi.pet.shijizhongyun.com',
    'kangaroo': '//apigateway.pet.shijizhongyun.com',
    'camsApi': '//centerapi.pet.shijizhongyun.com',
    'Store': '//camelapi.pet.shijizhongyun.com',
    'uploadApi': '//uploadapi.pet.shijizhongyun.com',

    'packetApi': '//smallapi.pet.shijizhongyun.com',
    'processApi': '//smallapi.pet.shijizhongyun.com',
    'customsApi': '//smallapi.pet.shijizhongyun.com',
    'channeltApi': '//smallapi.pet.shijizhongyun.com',
    'channeltApiBd': '//smallapi-bd.pet.shijizhongyun.com',
    'channeltApiBsi': '//smallapi-bsi.pet.shijizhongyun.com',
    'channeltApiYstd': '//smallapi-ylwems.pet.shijizhongyun.com',
    'priceApi': '//smallapi.pet.shijizhongyun.com',
    'priceApiBd': '//smallapi-bd.pet.shijizhongyun.com',
    'warehouseApi': '//smallapi.pet.shijizhongyun.com',
    'problemTypeApi': '//smallapi.pet.shijizhongyun.com',
    'open-apis': '//centerapi.pet.shijizhongyun.com',
    'microAppCams': '//cams.pet.shijizhongyun.com',
    'microAppMid': '//middlebusiness.pet.shijizhongyun.com',
  },
  // uat配置
  uat: {
    'userApi': '//centerapi.uat.shijizhongyun.com',
    'payApi': '//centerapi.uat.shijizhongyun.com',
    'dataCenterApi': '//centerapi.uat.shijizhongyun.com',
    'interfaceApi': '//centerapi.uat.shijizhongyun.com',
    'reportApi': '//centerapi.uat.shijizhongyun.com',
    'customerApi': '//centerapi.uat.shijizhongyun.com',
    'contractApi': '//centerapi.uat.shijizhongyun.com',
    'financeApi': '//centerapi.uat.shijizhongyun.com',
    'flowApi': '//centerapi.uat.shijizhongyun.com',
    'commerceApi': '//centerapi.uat.shijizhongyun.com',
    'loggerApi': '//centerapi.uat.shijizhongyun.com',
    'processCenter': '//centerapi.uat.shijizhongyun.com',
    'uploadApi': '//uploadapi.uat.shijizhongyun.com',
    'kangaroo': '//apigateway.uat.shijizhongyun.com',
    'camsApi': '//centerapi.uat.shijizhongyun.com',
    'Store': '//camelapi.uat.shijizhongyun.com',
    'packetApi': '//smallapi.uat.shijizhongyun.com',
    'processApi': '//smallapi.uat.shijizhongyun.com',
    'customsApi': '//smallapi.uat.shijizhongyun.com',
    'channeltApi': '//smallapi.uat.shijizhongyun.com',
    'channeltApiBd': '//smallapi-bd.uat.shijizhongyun.com',
    'channeltApiBsi': '//smallapi-bsi.uat.shijizhongyun.com',
    'channeltApiYstd': '//smallapi-ylwems.uat.shijizhongyun.com',
    'priceApi': '//smallapi.uat.shijizhongyun.com',
    'priceApiBd': '//smallapi-bd.uat.shijizhongyun.com',
    'warehouseApi': '//smallapi.uat.shijizhongyun.com',
    'problemTypeApi': '//smallapi.uat.shijizhongyun.com',
    'open-apis': '//centerapi.uat.shijizhongyun.com',
    'microAppCams': '//cams.uat.shijizhongyun.com',
    'microAppMid': '//middlebusiness.uat.shijizhongyun.com',
  }, // uat配置
  sim: {
    'userApi': '//centerapi.sim.shijizhongyun.com',
    'payApi': '//centerapi.sim.shijizhongyun.com',
    'dataCenterApi': '//centerapi.sim.shijizhongyun.com',
    'interfaceApi': '//centerapi.sim.shijizhongyun.com',
    'reportApi': '//centerapi.sim.shijizhongyun.com',
    'customerApi': '//centerapi.sim.shijizhongyun.com',
    'contractApi': '//centerapi.sim.shijizhongyun.com',
    'financeApi': '//centerapi.sim.shijizhongyun.com',
    'flowApi': '//centerapi.sim.shijizhongyun.com',
    'commerceApi': '//centerapi.sim.shijizhongyun.com',
    'loggerApi': '//centerapi.sim.shijizhongyun.com',
    'processCenter': '//centerapi.sim.shijizhongyun.com',
    'uploadApi': '//uploadapi.sim.shijizhongyun.com',
    'kangaroo': '//apigateway.sim.shijizhongyun.com',
    'camsApi': '//centerapi.sim.shijizhongyun.com',
    'Store': '//camelapi.sim.shijizhongyun.com',
    'packetApi': '//smallapi.sim.shijizhongyun.com',
    'processApi': '//smallapi.sim.shijizhongyun.com',
    'customsApi': '//smallapi.sim.shijizhongyun.com',
    'channeltApi': '//smallapi.sim.shijizhongyun.com',
    'channeltApiBd': '//smallapi-bd.sim.shijizhongyun.com',
    'channeltApiBsi': '//smallapi-bsi.sim.shijizhongyun.com',
    'channeltApiYstd': '//smallapi-ylwems.sim.shijizhongyun.com',
    'priceApi': '//smallapi.sim.shijizhongyun.com',
    'priceApiBd': '//smallapi-bd.sim.shijizhongyun.com',
    'warehouseApi': '//smallapi.sim.shijizhongyun.com',
    'problemTypeApi': '//smallapi.sim.shijizhongyun.com',
    'open-apis': '//centerapi.sim.shijizhongyun.com',
    'microAppCams': '//cams.sim.shijizhongyun.com',
    'microAppMid': '//middlebusiness.sim.shijizhongyun.com',
  },
  // uat配置
  hbuat: {
    'userApi': '//centerapi-hb.uat.shijizhongyun.com',
    'payApi': '//centerapi-hb.uat.shijizhongyun.com',
    'dataCenterApi': '//centerapi-hb.uat.shijizhongyun.com',
    'interfaceApi': '//centerapi-hb.uat.shijizhongyun.com',
    'reportApi': '//centerapi-hb.uat.shijizhongyun.com',
    'customerApi': '//centerapi-hb.uat.shijizhongyun.com',
    'contractApi': '//centerapi-hb.uat.shijizhongyun.com',
    'financeApi': '//centerapi-hb.uat.shijizhongyun.com',
    'flowApi': '//centerapi-hb.uat.shijizhongyun.com',
    'commerceApi': '//centerapi-hb.uat.shijizhongyun.com',
    'loggerApi': '//centerapi-hb.uat.shijizhongyun.com',
    'processCenter': '//centerapi-hb.uat.shijizhongyun.com',
    'uploadApi': '//uploadapi-hb.uat.shijizhongyun.com',

    'packetApi': '//smallapi.uat.shijizhongyun.com',
    'processApi': '//smallapi.uat.shijizhongyun.com',
    'customsApi': '//smallapi.uat.shijizhongyun.com',
    'channeltApi': '//smallapi.uat.shijizhongyun.com',
    'channeltApiBd': '//smallapi-bd.uat.shijizhongyun.com',
    'priceApi': '//smallapi.uat.shijizhongyun.com',
    'priceApiBd': '//smallapi-bd.uat.shijizhongyun.com',
    'warehouseApi': '//smallapi.uat.shijizhongyun.com',
    'problemTypeApi': '//smallapi.uat.shijizhongyun.com',
    'open-apis': '//centerapi-hb.uat.shijizhongyun.com',
    'microAppCams': '//cams-hb.uat.shijizhongyun.com',
    'microAppMid': '//middlebusiness-hb.uat.shijizhongyun.com',
  },
  // 生产配置
  hbprod: {
    'userApi': '//centerapi.huibaibio.com',
    'payApi': '//centerapi.huibaibio.com',
    'dataCenterApi': '//centerapi.huibaibio.com',
    'interfaceApi': '//centerapi.huibaibio.com',
    'reportApi': '//centerapi.huibaibio.com',
    'customerApi': '//centerapi.huibaibio.com',
    'contractApi': '//centerapi.huibaibio.com',
    'financeApi': '//centerapi.huibaibio.com',
    'flowApi': '//centerapi.huibaibio.com',
    'commerceApi': '//centerapi.huibaibio.com',
    'loggerApi': '//centerapi.huibaibio.com',
    'processCenter': '//centerapi.huibaibio.com',
    'uploadApi': '//uploadapi.huibaibio.com',

    'packetApi': '//smallapi.huibaibio.com',
    'processApi': '//smallapi.huibaibio.com',
    'customsApi': '//smallapi.huibaibio.com',
    'channeltApi': '//smallapi.huibaibio.com',
    'channeltApiBd': '//smallapi-bd.huibaibio.com',
    'priceApi': '//smallapi.huibaibio.com',
    'priceApiBd': '//smallapi-bd.huibaibio.com',
    'warehouseApi': '//smallapi.huibaibio.com',
    'problemTypeApi': '//smallapi.huibaibio.com',
    'open-apis': '//centerapi.huibaibio.com',
    'microAppCams': '//cams.huibaibio.com',
    'microAppMid': '//middlebusiness.huibaibio.com',
  },
  prod: {
    'userApi': '//centerapi.gotofreight.com',
    'payApi': '//centerapi.gotofreight.com',
    'dataCenterApi': '//centerapi.gotofreight.com',
    'interfaceApi': '//centerapi.gotofreight.com',
    'reportApi': '//centerapi.gotofreight.com',
    'customerApi': '//centerapi.gotofreight.com',
    'contractApi': '//centerapi.gotofreight.com',
    'financeApi': '//centerapi.gotofreight.com',
    'flowApi': '//centerapi.gotofreight.com',
    'commerceApi': '//centerapi.gotofreight.com',
    'loggerApi': '//centerapi.gotofreight.com',
    'processCenter': '//centerapi.gotofreight.com',
    'uploadApi': '//uploadapi.gotofreight.com',
    'kangaroo': '//apigateway.gotofreight.com',
    'camsApi': '//centerapi.gotofreight.com',
    'Store': '//httpgateway.gotofreight.com',
    'packetApi': '//smallapi.gotofreight.com',
    'processApi': '//smallapi.gotofreight.com',
    'customsApi': '//smallapi.gotofreight.com',
    'channeltApi': '//smallapi.gotofreight.com',
    'channeltApiBd': '//smallapi-bd.gotofreight.com',
    'channeltApiBsi': '//smallapi-bsi.gotofreight.com',
    'channeltApiYstd': '//smallapi-ylwems.gotofreight.com',
    'priceApi': '//smallapi.gotofreight.com',
    'priceApiBd': '//smallapi-bd.gotofreight.com',
    'warehouseApi': '//smallapi.gotofreight.com',
    'problemTypeApi': '//smallapi.gotofreight.com',
    'open-apis': '//centerapi.gotofreight.com',
    'microAppCams': '//cams.gotofreight.com',
    'microAppMid': '//middlebusiness.gotofreight.com',
  },
} as { [key: string]: NetConfig }

// 根据环境变量 导出对应配置0
// eslint-disable-next-line import/no-mutable-exports
let envConfig: NetConfig = {}
if (NODE_ENV === 'development' || VITE_BUILDENV === 'dev') {
  /**
   * debug_server -> 调试服务器
   * 在开发模式下 为方便调试  开发者可自行修改debug_server 用本地项目连接不同的目标服务器
   * debug_server：dev  开发服务器(默认)
   * debug_server：test 测试服务器
   * debug_server：prod 生产服务器
   */

  let envkey = getStorage('debug_server') || 'dev'
  if (!['dev', 'test', 'uat', 'prod', 'pet', 'sim'].includes(envkey))
    envkey = 'dev'
  envConfig = config[envkey]

  if (envkey === 'dev') {
    // 本机开发模式默认连接内网服务器
    const centerapi_dev_host = getStorage('centerapi_dev_host') || ''

    if (centerapi_dev_host)
      envConfig.centerapi = centerapi_dev_host
  }
}
else {
  envConfig = config[VITE_BUILDENV]
}
export default envConfig
