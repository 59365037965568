// @ts-ignore
/* eslint-disable */
import request from '@/utils/http/request';

/** 下载导入错误Excel POST /DataAuth/DownloadErrorExcel */
export async function DataAuthDownloadImportTemplatePOST(
  body: API.ExcelKeyInput,
  options?: { [key: string]: any },
) {
  return request<any>({
    url: `/userApi/DataAuth/DownloadErrorExcel`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查询自定义数据权限不分页接口 POST /DataAuth/GetDataAuthDefinedListAsync */
export async function DataAuthGetDataAuthDefinedListPOST(
  body: API.GetDataAuthDefinedListInput,
  options?: { [key: string]: any },
) {
  return request<API.GetDataAuthDefinedListOutput[]>({
    url: `/userApi/DataAuth/GetDataAuthDefinedListAsync`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查询自定义数据权限分页接口 POST /DataAuth/GetDataAuthDefinedPageListAsync */
export async function DataAuthGetDataAuthDefinedPageListPOST(
  body: API.GetDataAuthDefinedPageListInput,
  options?: { [key: string]: any },
) {
  return request<API.GetDataAuthDefinedListOutputUnifyPageResultDto>({
    url: `/userApi/DataAuth/GetDataAuthDefinedPageListAsync`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}

/** <br>40、人员拥有的自定义数据权限查询接口描述
            获取用户的自定义数据权限(包含转移)分页 GET /DataAuth/GetListByPageAsync */
export async function DataAuthGetListByPageGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.DataAuthGetListByPageGETParams,
  options?: { [key: string]: any },
) {
  return request<API.DataAuthOutputUnifyPageResultDto>({
    url: `/userApi/DataAuth/GetListByPageAsync`,
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取角色的自定义数据权限 GET /DataAuth/GetListByRoleId */
export async function DataAuthGetListByRoleIdGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.DataAuthGetListByRoleIdGETParams,
  options?: { [key: string]: any },
) {
  return request<API.GetDataAuthDefinedListOutput[]>({
    url: `/userApi/DataAuth/GetListByRoleId`,
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** <br>
            71、人员拥有的自定义数据权限编辑查询接口描述
            
            获取用户的自定义数据权限(包含转移) GET /DataAuth/GetListByUserIdAsync */
export async function DataAuthGetListByUserIdGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.DataAuthGetListByUserIdGETParams,
  options?: { [key: string]: any },
) {
  return request<API.DataAuthValueObject[]>({
    url: `/userApi/DataAuth/GetListByUserIdAsync`,
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取角色未拥有的自定义数据权限 GET /DataAuth/GetNotAuthListByRoleId */
export async function DataAuthGetNotAuthListByRoleIdGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.DataAuthGetNotAuthListByRoleIdGETParams,
  options?: { [key: string]: any },
) {
  return request<API.GetDataAuthDefinedListOutput[]>({
    url: `/userApi/DataAuth/GetNotAuthListByRoleId`,
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** <br>
            59、权限管理查询人员未授权自定义数据权限接口描述
            
            获取人员未拥有的自定义数据权限 GET /DataAuth/GetNotAuthListByUserIdAsync */
export async function DataAuthGetNotAuthListByUserIdGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.DataAuthGetNotAuthListByUserIdGETParams,
  options?: { [key: string]: any },
) {
  return request<API.DataAuthValueObject[]>({
    url: `/userApi/DataAuth/GetNotAuthListByUserIdAsync`,
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取角色的自定义数据权限分页 GET /DataAuth/GetPageListByRoleId */
export async function DataAuthGetPageListByRoleIdGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.DataAuthGetPageListByRoleIdGETParams,
  options?: { [key: string]: any },
) {
  return request<API.GetDataAuthDefinedListOutputUnifyPageResultDto>({
    url: `/userApi/DataAuth/GetPageListByRoleId`,
    method: 'GET',
    params: {
      // pageIndex has a default value: 1
      pageIndex: '1',
      // pageSize has a default value: 10
      pageSize: '10',
      ...params,
    },
    ...(options || {}),
  });
}

/** 导入自定义数据权限 POST /DataAuth/ImportDataAuth */
export async function DataAuthImportDataAuthPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.DataAuthImportDataAuthPOSTParams,
  body: {},
  options?: { [key: string]: any },
) {
  const formData = new FormData();

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      formData.append(
        ele,
        typeof item === 'object' && !(item instanceof File) ? JSON.stringify(item) : item,
      );
    }
  });

  return request<API.UnifyResultDto>({
    url: `/userApi/DataAuth/ImportDataAuth`,
    method: 'POST',
    params: {
      ...params,
    },
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}

/** 导入正确数据 POST /DataAuth/ImportSuccessList */
export async function DataAuthImportSuccessListPOST(
  body: API.DataAuthExcelImport[],
  options?: { [key: string]: any },
) {
  return request<API.UnifyResultDto>({
    url: `/userApi/DataAuth/ImportSuccessList`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 维护自定义数据权限 信息 POST /DataAuth/MaintainDataAuthDefined */
export async function DataAuthMaintainDataAuthDefinedPOST(
  body: API.MaintainDataAuthDefinedInput,
  options?: { [key: string]: any },
) {
  return request<API.IdBaseDto>({
    url: `/userApi/DataAuth/MaintainDataAuthDefined`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取用户 未转移出去 的自定义数据权限 POST /DataAuthDefinedUser/GetDataAuthNotAgentByUser */
export async function DataAuthGetDataAuthNotAgentByUserPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.DataAuthGetDataAuthNotAgentByUserPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.DataAuthDefinedValueObject[]>({
    url: `/userApi/DataAuthDefinedUser/GetDataAuthNotAgentByUser`,
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
