// @ts-ignore
/* eslint-disable */
import request from '@/utils/http/request';

/** 维护目录页面 POST /Menu/Edit */
export async function MenuEditPOST(body: API.MaintainMenuInput, options?: { [key: string]: any }) {
  return request<API.IdBaseDto>({
    url: `/userApi/Menu/Edit`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 根据子系统查询权限和权限项 POST /Menu/GetAuthClassifyItemTreeList */
export async function MenuGetAuthClassifyItemTreeListPOST(
  body: API.GetAuthClassifyListInput,
  options?: { [key: string]: any },
) {
  return request<API.GetAuthClassifyItemsTreeListOutput[]>({
    url: `/userApi/Menu/GetAuthClassifyItemTreeList`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查询目录页面 POST /Menu/GetListMenu */
export async function MenuGetListMenuPOST(
  body: API.GetMenuListInput,
  options?: { [key: string]: any },
) {
  return request<API.GetMenuListOutput[]>({
    url: `/userApi/Menu/GetListMenu`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查询目录页面父级信息 返回树形结构 POST /Menu/GetListMenuParent */
export async function MenuGetListMenuParentPOST(
  body: API.GetMenuParentListInput,
  options?: { [key: string]: any },
) {
  return request<API.GetMenuParentListOutput[]>({
    url: `/userApi/Menu/GetListMenuParent`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 子系统获取菜单列表 GET /Menu/GetsubSystemMenuTreeList */
export async function MenuGetsubSystemMenuTreeListGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MenuGetsubSystemMenuTreeListGETParams,
  options?: { [key: string]: any },
) {
  return request<API.GetMenuParentListOutputListUnifyResultDto>({
    url: `/userApi/Menu/GetsubSystemMenuTreeList`,
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取登录菜单信息 GET /Menu/GetUserMenuList */
export async function MenuGetUserMenuListGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MenuGetUserMenuListGETParams,
  options?: { [key: string]: any },
) {
  return request<string[]>({
    url: `/userApi/Menu/GetUserMenuList`,
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
