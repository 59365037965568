import microApp from './group/microApp'
import type { RouteRecord } from '@/types/router'
import Layouts from '@/layouts/index.vue'
import MicroApp from '@/views/micro/index.vue'

const asyncRoutes: RouteRecord[] = [
  {
    path: '/',
    name: 'Root',
    component: Layouts,
    redirect: '/index',
    meta: {
      title: '首页',
      icon: 'icon-shouye',
      breadcrumbHidden: true,
      subTitle: '首页',
      levelHidden: true,
    },
    children: [
      {
        path: '/index',
        name: 'Index',
        component: () => import('@/views/index/index.vue'),
        meta: {
          title: '首页',
          noClosable: true,
          icon: 'icon-shouye',
          isLevel1: true,
        },
      },
      {
        path: '/personalCenter',
        name: 'PersonalCenter',
        component: () => import('@/views/personalCenter/index.vue'),
        meta: {
          title: '个人中心',
          hidden: true,
          activeMenu: '/index',
        },
      },
      {
        path: '/trunk/trunkEnterCurtomerInfo',
        name: 'TrunkEnterCurtomerInfo',
        component: () => import('@/views/trunk/trunkCustomerList/trunkEnterCurtomerInfo.vue'),
        meta: {
          title: '新增公司信息',
          hidden: true,
          noKeepAlive: false,
          dynamicNewTab: true,
        },
      },
      {
        path: '/trunk/trunkCustomerDetails',
        // path: '/trunk/trunkCustomerDetails/:id(\\d+)/:from(pack|crm)/:businessSubSystemId(\\d+)?/:externalSubjectId(\\d+)?',
        name: 'TrunkCustomerDetails',
        component: () => import('@/views/trunk/trunkCustomerList/trunkCustomerDetails.vue'),
        meta: {
          title: '客户详情',
          hidden: true,
          noKeepAlive: true,
        },
      },
      {
        // path: '/trunk/newRequest/:id(\\d+)/:infoId?',
        path: '/trunk/newRequest',
        name: 'NewRequest',
        component: () =>
          import('@/views/trunk/trunkCustomerList/components/PeriodLimit/newRequest.vue'),
        meta: {
          title: '提/降额申请',
          hidden: true,
          noKeepAlive: true,
        },
      },
      {
        path: '/trunk/trunkCreateContract',
        name: 'TrunkCreateContract',
        component: () =>
          import('@/views/trunk/trunkCustomerList/components/PeriodLimit/trunkCreateContract.vue'),
        meta: {
          title: '创建合同',
          hidden: true,
          noKeepAlive: true,
        },
      },
      {
        path: '/customerInfo/enterCurtomerInfo',
        name: 'EnterCurtomerInfo',
        component: () => import('@/views/customerInfo/customerList/enterCurtomerInfo.vue'),
        meta: {
          title: '新增公司信息',
          hidden: true,
          noKeepAlive: true,
        },
      },
      {
        path: '/microAppMid/contract/contractApplicationApproval',
        name: 'microAppMidContractApplicationApproval',
        component: MicroApp,
        meta: {
          title: '创建合同',
          hidden: true,
        },
      },
      {
        // path: '/customerInfo/customerDetails/:id(\\d+)/:from(pack|crm)/:businessSubSystemId(\\d+)?/:externalSubjectId(\\d+)?',
        path: '/customerInfo/customerDetails',
        name: 'CustomerDetails',
        component: () => import('@/views/customerInfo/customerList/customerDetails.vue'),
        meta: {
          title: '客户详情',
          hidden: true,
          noKeepAlive: true,
          dynamicNewTab: true,
        },
        props: true,
      },
      {
        path: '/marketing/targetType',
        name: 'TargetType',
        component: () => import('@/views/marketing/targetType.vue'),
        meta: {
          title: '目标类型',
          hidden: true,
        },
      },
      {
        path: '/field/fieldSet',
        name: 'FieldSet',
        component: () => import('@/views/fieldConfig/fieldSet.vue'),
        meta: {
          title: '字段设置',
          hidden: true,
        },
      },
      {
        path: '/field/templateSet',
        name: 'templateSet',
        component: () => import('@/views/fieldConfig/templateSet.vue'),
        meta: {
          title: '模板设置',
          hidden: true,
          noKeepAlive: true,
        },
      },
      {
        path: '/field/editTemplateSet',
        name: 'editTemplateSet',
        component: () => import('@/views/fieldConfig/editTemplateSet.vue'),
        meta: {
          title: '模板编辑',
          hidden: true,
        },
      },
      {
        path: '/sales/clientDetail',
        name: 'ClientDetail',
        component: () => import('@/views/salesLead/clientDetail.vue'),
        meta: {
          title: '客户详情',
          hidden: true,
        },
      },
      {
        path: '/sales/ruleConfig',
        name: 'RuleConfig',
        component: () => import('@/views/publicPool/ruleConfig.vue'),
        meta: {
          title: '公海池规则配置',
          hidden: true,
        },
      },
      {
        // path: '/supplierInfo/detailsSuppliers/:id(\\d+)/:from(pack|crm)',
        path: '/supplierInfo/detailsSuppliers',
        name: 'DetailsSuppliers',
        component: () =>
          import('@/views/supplierInfo/supplierManage/components/supplierList/detailsSupplier.vue'),
        meta: {
          title: '供应商详情',
          hidden: true,
          noKeepAlive: true,
        },
      },
      {
        path: '/supplierInfo/newContractedSuppliersAdded',
        name: 'NewContractedSuppliersAdded',
        component: () =>
          import(
            '@/views/supplierInfo/supplierManage/components/supplierApplicationInformation/newContractedSuppliersAdded.vue'
          ),
        meta: {
          title: '新增签约供应商',
          hidden: true,
          noKeepAlive: true,
        },
      },
      {
        path: '/supplierInfo/addNewSupplier',
        name: 'AddNewSupplier',
        component: () => import('@/views/supplierInfo/supplierManage/addNewSupplier.vue'),
        meta: {
          title: '新增供应商',
          hidden: true,
          noKeepAlive: true,
          dynamicNewTab: true,
        },
      },
      {
        // path: '/supplierInfo/supplierNewRequest/:id(\\d+)/:infoId?',
        path: '/supplierInfo/supplierNewRequest',
        name: 'SupplierNewRequest',
        component: () =>
          import(
            '@/views/supplierInfo/supplierManage/components/supplierList/detailsComponents/periodLimit/addRequest.vue'
          ),
        meta: {
          title: '提/降额申请',
          hidden: true,
          noKeepAlive: true,
        },
      },
      {
        path: '/supplierInfo/supplierCreateContract',
        name: 'SupplierCreateContract',
        component: () =>
          import(
            '@/views/supplierInfo/supplierManage/components/supplierList/detailsComponents/periodLimit/supplierCreateContract.vue'
          ),
        meta: {
          title: '创建合同',
          hidden: true,
          noKeepAlive: true,
        },
      },
    ],
  },
]

export default asyncRoutes

export const microAppRoutes: RouteRecord[] = [...microApp]
