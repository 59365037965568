<script lang="ts" setup>
import { useHead } from '@vueuse/head'
import VabProgress from 'nprogress'
import { SjzyKeepAlive } from '@sjzy/ui'
import config from '@/config'

import { handleActivePath } from '@/utils/routes'
import type { RouteRecord, SjzyRoute } from '@/types/router'

const route = useRoute() as SjzyRoute

const $sub: any = inject('$sub')
const $unsub: any = inject('$unsub')

const settingsStore = useSettingsStore()
const { theme } = storeToRefs(settingsStore)
const tabsStore = useTabsStore()
const { getVisitedRoutes: visitedRoutes } = storeToRefs(tabsStore)

const componentRef = ref()
const routerKey = ref()
const keepAliveKeyList = ref()
const keepAliveNameList = ref()

const siteData = reactive({
  description: '',
})
useHead({
  meta: [
    {
      name: 'description',
      content: computed(() => siteData.description),
    },
  ],
})

function updateKeepAliveNameList(refreshRouteName = null) {
  keepAliveNameList.value = visitedRoutes.value
    .filter((item: RouteRecord) => !item.meta.noKeepAlive && item.name !== refreshRouteName)
    .flatMap((item: RouteRecord) => item.name)

  keepAliveKeyList.value = visitedRoutes.value
    .filter((item: RouteRecord) => !item.meta.noKeepAlive && item.name !== refreshRouteName)
    .flatMap((item: RouteRecord) => item.path)
}

// 更新KeepAlive缓存页面
watchEffect(() => {
  routerKey.value = handleActivePath(route, true)
  updateKeepAliveNameList()
  siteData.description = `${'Vue'} ${'Admin'} ${'Plus'}-${
    route.meta.title
  }简介、官网、首页、文档和下载 - 前端开发框架`
})

$sub('reload-router-view', (refreshRouteName: any = route.name) => {
  if (theme.value.showProgressBar)
    VabProgress.start()
  const cacheActivePath = routerKey.value
  routerKey.value = null
  updateKeepAliveNameList(refreshRouteName)
  nextTick(() => {
    routerKey.value = cacheActivePath
    updateKeepAliveNameList()
  })
  setTimeout(() => {
    if (theme.value.showProgressBar)
      VabProgress.done()
  }, 200)
})

onUnmounted(() => {
  $unsub('reload-router-view')
})
</script>

<template>
  <RouterView v-slot="{ Component }">
    <!--    <Transition -->
    <!--      mode="out-in" -->
    <!--      :name="theme.showPageTransition ? 'fade-transform' : 'no-transform'" -->
    <!--    > -->
    <!--    {{keepAliveNameList}} -->
    <!--    {{keepAliveKeyList}} -->
    <!--    {{routerKey}} -->
    <SjzyKeepAlive
      :include="keepAliveNameList"
      :max="config.keepAliveMaxNum"
      :include-key="keepAliveKeyList"
    >
      <Component :is="Component" v-if="routerKey" :key="routerKey" ref="componentRef" />
    </SjzyKeepAlive>
    <!--    </Transition> -->
  </RouterView>
</template>
