import { ElMessage, ElMessageBox } from 'element-plus'
import { head, toArray } from 'lodash'
import mitt from 'mitt'

// import type { globalPropertiesType } from '@/types/library'
import config from '@/config/index'

// eslint-disable-next-line import/no-mutable-exports
export let gp: globalPropertiesType

export function setup(app: any) {
  const _emitter = mitt()

  gp = {
    /**
     * @description 全局Message
     * @param {string} message 消息文字
     * @param {'success'|'warning'|'info'|'error'} type 主题
     * @param {string} customClass 自定义类名
     * @param {boolean} dangerouslyUseHTMLString 是否将message属性作为HTML片段处理
     */
    $baseMessage: (
      message: string | undefined,
      type: 'warning' | 'success' | 'info' | 'error' = 'info',
      dangerouslyUseHTMLString: boolean,
    ) => {
      ElMessage({
        message: message || '',
        type,
        customClass: `sjzy-hey-message-${type}`,
        duration: config.messageDuration,
        dangerouslyUseHTMLString,
        showClose: true,
      })
    },
    /**
     * @description 全局Confirm
     * @param {string|VNode} content 消息正文内容
     * @param {string} title 标题
     * @param {Function} callback1 确认回调
     * @param {Function} callback2 关闭或取消回调
     * @param {string} confirmButtonText 确定按钮的文本内容
     * @param {string} cancelButtonText 取消按钮的自定义类名
     */
    $baseConfirm: (
      content,
      title,
      callback1,
      callback2,
      confirmButtonText = '确定',
      cancelButtonText = '取消',
      showCancelButton = true,
    ) => {
      ElMessageBox.confirm(content, title || '温馨提示', {
        confirmButtonText,
        cancelButtonText,
        showCancelButton,
        closeOnClickModal: false,
        type: 'warning',
        lockScroll: false,
      })
        .then(() => {
          if (callback1)
            callback1()
        })
        .catch(() => {
          if (callback2)
            callback2()
        })
    },
    $pub: (...args: any[]) => {
      _emitter.emit(head(args), args[1])
    },
    $sub() {
      // eslint-disable-next-line prefer-rest-params
      Reflect.apply(_emitter.on, _emitter, toArray(arguments))
    },
    $unsub() {
      // eslint-disable-next-line prefer-rest-params
      Reflect.apply(_emitter.off, _emitter, toArray(arguments))
    },
  }

  Object.keys(gp).forEach((key) => {
    app.provide(key, gp[key as keyof typeof gp])
    // 允许vue3下继续使用vue2中的this调用vab方法
    app.config.globalProperties[key] = gp[key as keyof typeof gp]
  })

  // if (process.env['NODE_' + 'ENV'] !== `${'deve' + 'lopme' + 'nt'}`) {
  //   const key = 'vab-' + 'icons'
  //   if (!__APP_INFO__['dependencies'][key]) {
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-ignore
  //     app.config.globalProperties = null
  //   }
  //   if (!process.env['VUE_' + 'APP_' + 'SECRET_' + 'KEY']) {
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-ignore
  //     app.config.globalProperties = null
  //   }
  // }
}
