export default {
  '海运运价': 'Ocean Freight Rate',
  '整柜运价': 'FCL Freight Rate',
  '整柜毛利润': 'FCL Gross Profit',
  '整柜驳船费用': 'FCL Feeder Charge',
  '整柜基本港费率维护': 'Maintenance of FCL basic port rate ',
  '版本恢复': 'Version Recovery',
  '航线名称': 'Route name',
  '大船转运港': 'Vessel Transfer Port',
  '基准起运港（X）': 'Base Port of Departure (X)',
  '基准起运港': 'Base Port of Departure',
  '从价港': 'Ad valorem port',
  '从价港（Y）': 'Ad valorem port (Y)',
  '价格公式': 'price formula',
  '发布': 'release',
  '修改整柜毛利润': 'Modify FCL Gross Profit',
  '新增整柜毛利润': 'Add FCL Gross Profit',
  '修改整柜驳船费用': 'Modify FCL Feeder Charge',
  '新增整柜驳船费用': 'Add FCL Feeder Charge',
  '修改整柜基本港费率维护': 'Modify maintenance of FCL basic port rate ',
  '新增整柜基本港费率维护': 'Add maintenance of FCL basic port rate ',
  '公式必须有x(基准港价格)参数': 'The formula must have an x (base port price) parameter',
  '计算结果有误，请从新输入公式':
    'The calculation result is incorrect. Please re-enter the formula',
  '计算结果小于0，请从新输入公式':
    'The calculation result is less than 0, please re-enter the formula',
  '大船起运港': 'POD of Vessel',
  '运价有效期': 'Freight Rate’s Period of Validity',
  '生效时间按照': 'Effective time comparison',
  '驳船开船': 'Feeder Sailing',
  '大船开船': 'Vessel Sailing',
  '大船截关': 'Vessel Closing ',
  '船司服务名称': 'Shipping company service name',
  '出单类型': 'Issuing Type',
  '当前版本号': 'Current version number',
  '历史版本号': 'Historical version number',
  '内外部': 'internal and external',
  '审核人': 'APPROVED BY',
  '目的港所属航线': 'Route of destination port',
  '是否确认恢复？': 'Are you sure to restore?',
  '是否确认发布？': 'Are you sure to publish?',
  '只能勾选一条，进行新增': 'Only one item can be checked for addition',
  '发布成功': 'release succeeded',
  '历史版本': 'Historical version',
  '请先选择船公司或勾选列表数据': 'Please select a shipping company or check the list data first',
  '整柜运价模板': 'FCL Freight Rate  form template',
  '整柜驳船费用模板': 'FCL Feeder Chargeform template',
  '查看从价港': 'View ad valorem port',
  '按钮必须选中一个起运港才能使用，且选中的起运港不能是从价港':
    'The button must select a port of origin to be used, and the selected port of origin cannot be a dependent port.',
  '开始时间': 'Start Time',
  '结束时间': 'End Time',
  '转运点': 'Transshipped at',
  '拼箱直航': 'LCL Direct',
  '修改拼箱直航': 'Modify LCL Direct',
  '新增拼箱直航': 'Add LCL Direct',
  '不限': 'Unlimited',
  '拼箱转运': 'LCL Transshipment',
  '修改拼箱转运': 'Modify LCL Transshipment',
  '新增拼箱转运': 'Add LCL Transshipment',
  '拼箱毛利润': 'LCL Gross Profit',
  '修改拼箱毛利润': 'Modify LCL Gross Profit',
  '新增拼箱毛利润': 'Add LCL Gross Profit',
  '拼箱驳船运价': 'LCL Barge Charge',
  '修改拼箱驳船运价': 'Modify LCL Barge Charge',
  '新增拼箱驳船运价': 'Add LCL Barge Charge',
  '驳船运费': 'Barge Charge',
  '最小体积': 'Minimum Volume',
  '计费类型': 'Billing Type',
  '体积类型': 'Volume Type',
  '轻重货物': 'Low/High Density Cargo',
  '轻货': 'Low Density Cargo',
  '重货': 'High Density Cargo',
  '拼箱驳船运价模板': 'LCL Barge Charge form template',
  '拼箱直航模板': 'LCL Direct form template',
  '开始时间不能大于结束时间': 'The start time cannot be greater than the end time',
  '结束时间不能小于开始时间': 'The end time cannot be less than the start time',
  '限制八位整数': 'Limit octet integers',
  '拼箱转运模板': 'LCL Transshipment form template',
  '请选择开始时间': 'Please select a start time',
  '请选择结束时间': 'Please select the end time',
  '复制': 'copy',
  '粘贴': 'Paste',
  '海运运价查询': 'Sea Freight Inquiry',
  '运价查询': 'Freight Inquiry',
  '拼箱运价查询': 'LCL Freight Rate Inquiry',
  '整柜运价查询': 'FCL Freight Rate Inquiry',
  '整柜客户毛利润': 'FCL Customer Gross Profit',
  '修改整柜客户毛利润': 'Modify FCL Customer Gross Profit',
  '新增整柜客户毛利润': 'Add FCL Customer Gross Profit',
  '拼箱客户毛利润': 'LCL Customer Gross Profit',
  '修改拼箱客户毛利润': 'Modify LCL Customer Gross Profit',
  '新增拼箱客户毛利润': 'Add LCL Customer Gross Profit',
  '大船起运地': 'DEP of Vessel',
  '空运运价维护': 'Air Freight maintenance',
  '海运运价维护': 'Sea Freight maintenance',
  '空运D询价': 'Air Freight D-clause Inquiry ',
  '海运D询价': 'Ocean Freight D-clause Inquiry',
  '空运出口D询价': 'Air Export',
  '整柜出口D询价': 'FCL Export',
  '散货出口D询价': 'LCL Export',
  '整柜进口D询价': 'FCL Import',
  '散货进口D询价': 'LCL Import',
  '编辑成功': 'Modified successfully',
  '目的港国家/地区': 'Destination Port Country',
  '国家/地区名称': 'Country Name',
  '目的国出单注意事项': 'Precautions for issuing orders in the destination country',
  '目的国注意事项': 'Things to note in the destination country',
}
