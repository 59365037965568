export default {
  '航司代码': 'Airline Code',
  '航司': 'Airline ',
  '起运港': 'POL',
  '目的港': 'POD',
  '备注': 'Note',
  '创建人': 'Creation by',
  '创建时间': 'Creation Time',
  '修改人': 'Modified by',
  '名称': 'fileName',
  '大小': 'fileSize',
  '导入数据': 'fileDesc',
  '待导入': 'To be imported',
  '导入中': 'Importing',
  '已导入': 'Imported',
  '完成': 'complete',
  '修改时间': 'Modification Time',
  '空运目的港要求': 'Air POD Comment',
  '空运进口目的港要求': 'POD Requirements- Air Import',
  '海运进口目的港要求': 'POD Requirements- Ocean Import',
  '新增空运目的港要求': 'Add Air POD Comment',
  '修改空运目的港要求': 'Modify Air POD Comment',
  '船公司': 'Shipping Line',
  '新增海运出口目的港要求': 'Add POD Requirements- Ocean Export',
  '修改海运出口目的港要求': 'Modify POD Requirements- Ocean Export',
  '空运出口航线注意事项': 'Air Export Airline Info',
  '新增空运出口航线注意事项': 'Add Air Export Airline Info',
  '修改空运出口航线注意事项': 'Modify Air Export Airline Info',
  '供应商': 'Supplier',
  '供应商中文名': 'Chinese Name of Supplier',
  '服务类型': 'Service Type',
  '供应商备注': 'Supplier Comment',
  '新增供应商备注': 'Add Supplier Comment',
  '修改供应商备注': 'Modify Supplier Comment',
  'M长*宽*高*件数*': '(m)Length*width*heigth*pieces*',
  'CM长*宽*高*件数/': '(cm)Length*width*heigth*pieces/',
  '长*宽*高*件数*：': 'Length*width*heigth*pieces*',
  '长*宽*高*件数/：': 'Length*width*heigth*pieces/',
  '航线人员所在公司': 'Company(Pricing)',
  '签约主体公司': 'Contract Entity',
  '是否生成隐藏互订': 'Generate Hidden Mutual-order',
  '运营公司': 'Operating Company',
  '互订自动化配置': 'Auto Config For Mutual Booking',
  '新增互订自动化配置': 'Add Auto Config For Mutual Booking',
  '修改互订自动化配置': 'Modify Auto Config For Mutual Booking',
  '委托客户': 'Customer',
  '收货人': 'Consignee',
  '注意事项': 'Matters Needing Attention',
  '收货人简称': 'Short for CNEE',
  '收货人代码': 'Consignee Code',
  '主单品名': 'Commodity Description On MAWB',
  '储运注意事项': 'Storage and Transportation Considerations',
  '通知人信息': 'Notifier Information',
  '收货人储运注意事项': 'CNEE Precautions for Storage and Transportation',
  '新增收货人储运注意事项': 'Add CNEE Precautions for Storage and Transportation',
  '修改收货人储运注意事项': 'Modify CNEE Precautions for Storage and Transportation',
  'IATA代码': 'IATACode',
  '起运港代码': 'AirPortCode',
  '起运港中文名': 'AirCnName',
  '起运港英文名': 'AirEnName',
  '航司中文名': 'AirlineCnName',
  '航司简称': 'Airline Abbreviation',
  '航司英文名': 'AirlineEnName',
  '承运人': 'Carrier',
  '代理人账号': 'IATA Code and Account No.',
  '新增代理人账号': 'Add IATA Code and Account No.',
  '修改代理人账号': 'Modify IATA Code and Account No.',
  '财务注意事项': 'Finance Considerations',
  '储运/财务注意事项': 'Finance Considerations',
  '新增储运/财务注意事项': 'Add Attention For W/H And Finance',
  '修改储运/财务注意事项': 'Modify Attention For W/H And Finance',
  '关键字': 'Keyword',
  '品名关键字配置': 'Cargo Name Keyword',
  '新增品名关键字配置': 'Add Cargo Name Keyword',
  '修改品名关键字配置': 'Modify Cargo Name Keyword',
  '公司英文名': 'Shipper’s Company',
  '航空公司': 'Airline',
  '发货人黑名单维护': 'Shipper Blacklist Maintenance',
  '新增发货人黑名单维护': 'Add Shipper Blacklist Maintenance',
  '修改发货人黑名单维护': 'Modify Shipper Blacklist Maintenance',
  '不能输入中文': 'can not type chinese',
  '币种': 'Currency',
  '价格': 'price',
  '更改': 'change',
  '代码': 'code',
  '中文名': 'cnName',
  '英文名': 'enName',
  '客户代码': 'customer code',
  '国家/地区代号': 'country code',
  '空运数据管理': 'Air traffic data management',
  '空运档案': 'Air Archives',
  '上传接口配置': 'Upload interface configuration',
  '供应商机场仓库': 'Supplier’s Airport Warehouse',
  '起运港前置仓库': 'Warehouse ahead of the port of departure',
  '板型管理': 'ULD Type Management',
  '航司板型': 'Airline ULD Type',
  '提单自动化': 'B/L Automation',
  '自动化配置': 'Auto Configuration',
  '空运本地应付': 'Air freight payable locally',
  '空运其他费用配置': 'Allocation of other air freight charges',
  '入闸地勤收费': 'Gate In Charge/ TC',
  '香港代理人': 'Issuing Carrier',
  '相关注意事项': 'Relevant Remarks',
  '打单备注': 'Printing Note',
  '主单号维护': 'Master Order No. Maintenance',
  '空运操作注意事项': 'Precautions for air transportation operation',
  '仓库名称': 'Warehouse name',
  '仓库地址': 'Warehouse address',
  '供应商联系方式': 'Supplier contact information',
  '供应商机场备注': 'Supplier Airport Note',
  '城市': 'City',
  '城市名称': 'City',
  '城市名': 'City',
  '所属城市': 'City',
  '所属城市名': 'City',
  '数字代码': 'Numeric code',
  '人员名称': 'Personnel Name',
  '公司代码': 'Company Code',
  '部门名称': 'Department Name',
  '行政区名称': 'District',
  '前置仓库名称': 'Front warehouse name',
  '天数': 'Days',
  '前置仓库联系方式': 'Contact information of front warehouse',
  '前置仓库备注': 'Note of front warehouse',
  '前置仓库联系备注': 'Front warehouse contact notes',
  '板型名称': 'Plate type name',
  '起运地': 'DEP',
  // '目的地': 'PDD',
  '目的地': 'Destination',
  '目的地类型': 'Destination Type',
  '导出数据': 'export data',
  '下载模板': 'download the template',
  '导入文件': 'Import File',
  '储运/财务注意事项自动化': 'Attention For W/H And Finance',
  '储运/财务注意事项自动化模板': 'Attention For W/H And Finance template',
  '空运目的港要求模板': 'Air POD Comment template',
  '航线注意事项': 'Airline precautions',
  '航线注意事项模板': 'Airline precautions template',
  '先下载模板，按要求填写后上传':
    'Download the template, fill it in as required, and then upload it',
  '下载空模板': 'Download an empty template',
  '将文件拖到此区域或点击': 'Drag the file to this area or click',
  '选择文件上传': 'Select file upload',
  '数据导入中，请耐心等待': 'Data is being imported. Please wait patiently',
  '导入成功': 'Import successfully',
  '总共导入': 'Total import',
  '条数据': 'data',
  '导入失败': 'Import failure',
  '请检查文件格式和内容是否正确': 'Check whether the file format and content are correct',
  '重新选择文件': 'Reselect file',
  '导入异常': 'Import exception',
  '成功导入': 'Successful import',
  '成功': 'success',
  '失败': 'failed',
  '条': 'number of branches',
  '下载错误数据': 'Download error data',
  '是': 'Yes',
  '否': 'No',
  '明细': 'detail',
  '发货人公司': 'Shipper`s company',
  '储运财务注意事项自动化导出数据': 'Attention For W/H And Finance',
  '储运财务注意事项': 'Attention For W/H And Finance',
  '储运财务注意事项自动化模板': 'Attention For W/H And Finance template',
  '只能输入数字和小数点，字数限制八位和小数点后两位数':
    'Only numbers and decimal points can be entered. The word limit is eight and two decimal points',
  '只能输入8位数字和小数点后两位': 'Only 8 digits and two decimal places can be entered',
  '录入多个品名时使用逗号隔开': 'Use commas (,) to separate multiple product names',
  '只能输入正整数': 'Only positive integers can be entered',
  '只能输入数字(保留两位小数)': 'Only digits can be entered (two decimal places reserved)',
  '只能输入数字(保留三位小数)': 'Only digits can be entered (three decimal places reserved)',
  '单号前7位': 'Top 7 digits of the order number',
  '航司人员': 'Airlines personnel',
  '类型': 'Type',
  '状态': 'Status',
  '中性': 'Neutral',
  '电子': 'Electron',
  '可使用': 'Available ',
  '预留': 'Reserve',
  '作废': 'Cancel',
  '已使用': 'Used',
  '主单号': 'MAWB No.',
  '新增主单号': 'New MAWB No.',
  '修改主单号': 'Modify MAWB No.',
  '请输入单号前7位': 'Please enter the first 7 digits of the order number',
  '新增打单备注': 'Add Printing Note',
  '修改打单备注': 'Modify Printing Note',
  '新增空运操作注意事项': 'New Air Transport Operation Precautions',
  '修改空运操作注意事项': 'Precautions for modifying air transportation operations',
  '新增起运港前置仓库': 'Newly added front-end warehouse at the departure port',
  '修改起运港前置仓库': 'Modify the front-end warehouse at the port of departure',
  '新增供应商机场仓库': 'New supplier airport warehouse',
  '修改供应商机场仓库': 'Modify supplier airport warehouse',
  '新增板型': 'New plate type',
  '修改板型': 'Modify plate type',
  '高中低板': 'High, medium, and low board',
  '板型种类': 'Type of plate',
  '板尺寸': 'Plate size',
  '板型': 'Plate type',
  '高板': 'High Plate',
  '中板': 'Medium Plate',
  '低板': 'Low Plate',
  '请输入七位数字': 'Please enter seven digits',
  '最多输入七位数字': 'Enter up to seven digits',
  '请输入整数': 'please enter an integer',
  '最大输入100': 'Maximum input 100',
  '请输入所需数量': 'Please enter the required quantity',
  '所需数量': 'Quantity required',
  '修改成功': 'Modified successfully',
  '中文备注': 'Chinese Note',
  '英文备注': 'English Note',
  '中文注意事项': 'Chinese Attention Info',
  '英文注意事项': 'English Attention Info',
  '结算公司': 'Settlement Company',
  '费用项目': 'Charge Item',
  '新增结算公司': 'New Settlement Company',
  '修改结算公司': 'Modify Settlement Company',
  '费用相关': 'Charges Related',
  '运价': 'Freight rate',
  '海运': 'Ocean Shipping',
  '基础信息': 'Basic information',
  '配置管理': 'Config Management',
  '运价配置': 'Freight rate configuration',
  '运价汇率': 'Exchange Rate of Freight',
  '工作单': 'Order',
  '工作单配置': 'Order Configuration',
  '工作单状态类型': 'Type of Order Status ',
  '修改工作单状态类型': 'Modify Type of Order Status',
  '新增工作单状态类型': 'New Type of Order Status',
  '工作单固定状态': 'Fixed Status of Order',
  '工作单类型': ' Order Type',
  '状态类型中文名': 'Status Type Chinese',
  '状态类型英文名': 'Status Type English',
  '状态类型': 'Status Type',
  '内部互配操作经理配置': 'Internal interoperability manager configuration',
  '修改内部互配操作配置': 'Modify internal interoperability configuration',
  '新增内部互配操作配置': 'New internal interoperability configuration',
  '协助操作配置': 'Assistant Op',
  '海运本地费用': 'Local cost of sea transportation',
  '整柜无关应付本地费用': 'Local fees payable unrelated to the entire container',
  '整柜相关应付本地费用': 'Local fees payable related to the entire cabinet',
  '拼箱应付本地费用': 'Local charges payable for LCL',
  '整柜无关应付本地费用模板': 'Local fees payable unrelated to the entire container form template',
  '整柜相关应付本地费用模板': 'Local fees payable related to the entire cabinet form template',
  '拼箱应付本地费用模板': 'Local charges payable for LCL form template',
  '起运类型': 'Type of shipment',
  '目的类型': 'Purpose Type',
  '费用名称': 'Fee Name',
  '单位': 'Unit',
  '货币': 'money',
  '修改整柜无关应付本地费用': 'Modify local fees payable unrelated to the entire container',
  '新增整柜无关应付本地费用': 'Additional local fees payable unrelated to the entire cabinet',
  '修改整柜相关应付本地费用': 'Modify local fees payable related to the entire cabinet',
  '新增整柜相关应付本地费用': 'Add local fees payable related to the entire cabinet',
  '修改拼箱应付本地费用': 'Modify LCL payable local fees',
  '新增拼箱应付本地费用': 'Local fees payable for new LCL',
  '固定费用': 'Fixed Charges',
  '结算公司与费用项目': 'Settlement Company & Charge Item',
  '固定状态': 'Fixed state',
  '固定状态英文': 'Fixed status English',
  '所属分组': 'Group',
  '排序': 'sort',
  '是否可以删除': 'Delete or not?',
  '客户是否可见': 'Visible to customer',
  '客户': 'Customer',
  '触发规则': 'Trigger Rule',
  '触发规则英文': 'Trigger Rule',
  '订单目标分公司': 'Order Target Branch',
  '空运出口操作': 'Air Export Operations',
  '空运进口操作': 'Air Import Operator',
  '海运出口操作': 'Ocean Export Operator',
  '海运进口操作': 'Ocean Import Operator',
  '用户名': 'user name',
  '用户': 'User',
  '工作单所属公司': 'Work Order Company',
  '操作所属部门': 'Department to which the operator belongs',
  '新增协助操作配置': 'New Assistant Op',
  '修改协助操作配置': 'Modify Assistant Op',
  '状态代码': 'Status Code',
  '可以': 'Yes',
  '不可以': 'No',
  '安检费单价': 'Unit-price of  Security Screening ',
  '安检费币种': 'Currency of security inspection fee',
  '安检费最低收费': 'Security Screening Fees Minimum Charge',
  '提货费单价': 'Unit price of Pick-up ',
  '提货费币种': 'Unit price of delivery fee',
  '提货费最低收费': 'Pick-up Fees Minimum Charge',
  '新增香港安检提货费': 'New HK Security Inspection & Delivery Charge',
  '修改香港安检提货费': 'Modify HK Security Inspection & Delivery Charge',
  '头程起运港': 'First  POL',
  '仓位信息': 'Position information',
  '英文仓位信息': 'English position information',
  '起运城市中文名': 'City of Departure Chinese',
  '起运城市所属省': 'Province of Originating City',
  '目的城市中文名': 'Arrived City Chinese ',
  '目的城市所属省': 'Province of Destination City',
  '时效': 'Aging',
  '行政区': 'District',
  '行政区所属市': 'City to which the administrative region belongs',
  '行政区所属省': 'Province to which the administrative region belongs',
  '始发机场': 'Originating Airport',
  '转运港': 'Transfer Airport',
  '客户类型': 'Customer Type',
  '国家/地区/起运港': 'Country/POL',
  '区域/国家/港口': 'Territory/Country/Port',
  '跨省转运费': 'Trans-provincial Transshipment Charge',
  '新增跨省转运费': 'New Trans-provincial Transshipment Charge',
  '修改跨省转运费': 'Modify Trans-provincial Transshipment Charge',
  '空运转运港相同运费港口': 'Airport with Same Transshipment Charge',
  '新增空运转运港相同运费港口': 'New Airport with Same Transshipment Charge',
  '修改空运转运港相同运费港口': 'Modify Airport with Same Transshipment Charge',
  '吨车报价': 'Quote with Vehicle weight（ton)',
  '空运出口省内提货费': 'Intra-provincial Pick-up Charge- Export',
  '香港安检提货费': 'HK Security Inspection & Delivery Charge',
  '空运毛利润': 'Gross Profit',
  '空运舱位信息': 'Shipping Space Information',
  '航司优先级': 'Airline Priority',
  '空运运价': 'Air Freight',
  '新增吨车报价': 'New Quote with Vehicle weight（ton）',
  '修改吨车报价': 'Modify Quote with Vehicle weight（ton）',
  '等级报价': 'Quote with Garde',
  '新增等级报价': 'New Quote with Garde',
  '修改等级报价': 'Modify Quote with Garde',
  '修改航司优先级': 'Modify airline priority',
  '新增航司优先级': 'Add airline priority',
  '修改空运舱位信息': 'Modify Shipping Space Information',
  '新增空运舱位信息': 'New Shipping Space Information',
  '起运城市': 'City of Departure',
  '目的城市': 'Arrived City',
  '新增费用': 'New Cost',
  '修改费用': 'Modify Cost',
  '费用英文名': 'English Name of Cost',
  '费用代码': 'Charge Code',
  '仅业务': 'Business Only',
  '仅财务': 'Finance Only',
  '公用': 'Public',
  '单价': 'Unit-price',
  '数量': 'Quantity',
  '所属公司': 'Company',
  '所属分公司': 'Company',
  '分公司': 'Company',
  '空/海运': 'Air Freight/Ocean Shipping',
  '是否指定货': 'Nomination Cargo or not',
  '应收/应付': 'AR/AR',
  '是/否海外部': 'Overseas Department or not',
  '进/出口': 'Import/Export',
  '非电商/电商': 'Non-eCommerce/E-commercer',
  '电商/非电商': 'E-commercer/Non E-commerce',
  '空运': 'Air Service',
  '全部': 'All',
  '应收': 'AR',
  '应付': 'AP',
  '海外部': 'Overseas Department',
  '非海外部': 'Non-overseas Department',
  '出口': 'Export',
  '进口': 'Import',
  '非电商小单': 'Non-eCommerce Small Order',
  '电商小单': 'E-commerce small order',
  '新增固定费用': 'New Fixed Charges',
  '修改固定费用': 'Modify Fixed Charges',
  '新增工作单固定状态': 'New Fixed Status of Order',
  '修改工作单固定状态': 'Modify Fixed Status of Order',
  '时效（天）': 'Aging (Days)',
  '新增空运毛利润': 'New Gross Profit',
  '修改空运毛利润': 'Modify Gross Profit',
  '价格设置': 'Price Setting',
  '直客': 'Direct Customer',
  '同行': 'Peers',
  '海外代理': 'Overseas Agent',
  '最低收费': 'Minimum Charge',
  '国家/地区': 'Country',
  '国家代码': 'Country Code',
  '简称': 'Abbreviation',
  '全称': 'FullName',
  '单位名称': 'Unit name',
  '英文全称': 'enFullName',
  '连续添加': 'Add Continuously',
  '只能输入正数和小数点，字数限制十位和小数点后两位数':
    'Only positive numbers and decimal points can be entered, with a word limit of ten and two digits after the decimal point',
  '只能输入数字和小数点，字数限制八位和小数点后三位数':
    'Only positive numbers and decimal points can be entered, with a word limit of ten and three digits after the decimal point',
  '价格至少填一个': 'Fill in at least one price',
  '单价最少填一个': 'Fill in at least one unit price',
  '请选择安检费币种': 'Please select the currency of the security inspection fee',
  '请选择提货费币种': 'Please select the currency for the pickup fee',
  '起运城市不能和目的城市相同！请重新选择':
    'The starting city cannot be the same as the destination city! Please select a new one',
  '限制四位整数': 'Limit four digit integers',
  '价格必须大于0': 'Price must be greater than 0',
  '只能输入正整数和零': 'Only positive integers and zeros can be entered',
  '最少选择一个操作人员': 'Select at least one operator',
  '跨省转运费模板': 'Trans-provincial Transshipment Charge form template',
  '空运舱位信息模板': 'Shipping Space Information form template',
  '吨车报价模板': 'Quote with Vehicle weight (ton) form template',
  '等级报价模板': 'Quote with Garde form template',
  '委托人': 'Customer',
  '业务员': 'Salesman',
  '工作单号': 'Order Code',
  '工作单状态': 'Order Status',
  '工作单时间': 'Order Time',
  '是否电商': 'E-commerce',
  '是否免税': 'Tax-free',
  '工作单及费用管理': 'Work order and expense management',
  '工作单及费用管理详情': 'Work order and expense management Detail',
  '航线': 'Pricing',
  '组织类型': 'OrganizationType',
  '用户名称': 'User name',
  '用户账号': 'User account',
  '国家名': 'Country',

  '生效依据': 'EffectiveBasis',
  '费用类型': 'Fee Type',
  '目的港转运方式': 'POD transport',
  '中转1': 'Transit Airport 1',
  '中转1方式': 'Transit Airport 1 Method',
  '中转2': 'Transit Airport 2',
  '中转2方式': 'Transit Airport 2 Method',
  '货物类型': 'Cargo Type',
  '价格类型': 'PriceType',
  '飞机类型': 'AircraftType',
  '加收打板费': 'AddBoardMakingFee',
  // '开始时间': 'BeginTime',
  // '结束时间': 'EndTime',
  '是否公开': 'Public or not',
  '预计到达天数': 'Expected reach days',
  '直客备注': 'DirectRemarks',
  '直客英文备注': 'DirectEnglishRemarks',
  '同行专用备注': 'PeerSpecificRemarks',
  '利润分成备注': 'ProfitSharingRemarks',
  '利润不分成备注': 'ProfitNotDividedRemarks',
  '空运直飞运价': 'Direct',
  '空运转运运价': 'Transshipment',
  '直飞起运港': 'POL',
  '直飞第一中转港': 'FlightTransitPortCode',
  '转运起运港': 'TransportStartPortCode',
  '转运港1': 'Transfer Airport 1',
  '转运港1方式': 'Transfer Airport 1 Method',
  '转运港2': 'Transfer Airport 2',
  '转运港2方式': 'Transfer Airport 2 Method',
  '转运港3': 'Transfer Airport 3',
  '转运港3方式': 'Transfer Airport 3 Method',
  '机型': 'aircraft type',
  '显示备注': 'Show Notes',
  '转飞起运港': 'TransportStartPortCode',
  '公开价格': 'Open Price',
  '非公开价格': 'Non-public price',
  '参考价格': 'Reference price',
  '含燃油战险': 'Including fuel war insurance',
  '净运费': 'Net shipping cost',
  '头程': 'Cephalic process',
  '二程': 'Ercheng',
  '客机': 'Airliner',
  '货机': 'Cargo Aircraft',
  '客/货机': 'Airliner/Cargo Aircraft',
  '卡车': 'Truck',
  '飞机': 'Aircraft',
  '所有未保存的数据会被清空': 'All unsaved data will be cleared',
  '是否确认删除': 'Are you sure to delete',
  '请不要输入中文': 'Please do not enter Chinese',
  '当前行不允许修改': 'The current line is not allowed to modify',
  '请不要在序号行进行': 'Please do not do it in the serial number line',
  '请不要在选择行进行': 'Please do not choose a line',
  '驳船起运': 'Barge transportation',
  '不能相同': 'Cannot be the same',
  '只能输入小数点前八位和后两位数':
    'Only the first eight digits and the last two digits of the Decimal separator can be entered',
  '不允许都为空': 'Not allowed to be all empty',
  '同行指导报价查询': 'Peer guided quotation inquiry',
  '直客指导报价查询': 'Customer guided quotation inquiry',
  '海外代理指导报价查询': 'Overseas-agent guidance quotation inquiry',
  '指定客户查询': 'Designated Customer guidance inquiry',
  '成本报价查询': 'Cost quotation inquery',
  '转运地': 'Transfer Airport',
  '航程': 'voyage',
  '直航': 'Direct Flight',
  '转运': 'Transport',
  '汇率为空请维护': 'Exchange rate is empty, please maintain it',
  '英文特殊要求': 'Special Requirements',
  '业务类型': 'Business Type',
  '委托客户要求': 'Customer Requests',
  '新增委托客户要求': 'Add Customer Requests',
  '修改委托客户要求': 'Edit Customer Requests',
  '请填写英文特殊要求': 'Please fill in the English special requirements',
  '不允许输入中文': 'Chinese input not allowed',
  '渠道类型': 'Channel Type',
  '整柜保底毛利润': 'FCL Guarantee Gross Profit',
  '新增整柜保底毛利润': 'Add FCL Guarantee Gross Profit',
  '修改整柜保底毛利润': 'Modify FCL Guarantee Gross Profit',
  '拼箱保底毛利润': 'LCL Guarantee Gross Profit',
  '新增拼箱保底毛利润': 'Add LCL Guarantee Gross Profit',
  '修改拼箱保底毛利润': 'Modify LCL Guarantee Gross Profit',
  '空运保底毛利润': 'Guaranteed Gross Profit',
  '新增空运保底毛利润': 'Add Guaranteed Gross Profit',
  '修改空运保底毛利润': 'Modify Guaranteed Gross Profit',
  '以上字段必填一个': 'The above fields must be filled in one',
  '显示来源': 'Show Source',
  '毛利润': 'Gross Profit',
  '保底': 'Minimum guarantee',
  '成本': 'Cost',
  '非注册': 'Unregister',
  '注册': 'Register',
  // '请填写英文特殊要求': 'Please fill in the English special requirements',
  // '不允许输入中文': 'Chinese input not allowed',
  '签约航线': 'Signed route',
  '国家下拉邮编': 'Drop-down Postcode List',
  'DDU产品管理': 'DDU Product Management',
  'DDU菜单管理': 'DDU Menu Management',
  '邮编对应机场': 'Recommended Airport- POD Postcode',
  'DDP门到门交货地': 'Delivery Place of DDP Door to Door',
  'DDU批量运价过滤': 'Batch Freight Rate Filtering',
  'DDU后段费用': 'Charge of Posterior Segment',
  // 邮编: 'Postcode',
  '邮编': 'zipcode',
  '机场代码': 'airportcode',
  '机场中文名称': 'airportCn',
  '机场英文名称': 'airportEn',
  '邮编区间起值': 'zipcodestart',
  '邮编区间止值': 'zipcodeend',
  '生效位数': 'Effective digit',
  'DDP交货地': 'DDP delivery palce',
  '交货地': 'delivery palce',
  'DDU派送费': 'DDU Delivery Charge',
  'DDU本地费': 'DDU Local  Charge',
  '国家代理代码': 'Country Agent Code',
  '代理代码': 'Agent Code',
  '代理备注': 'Agent note',
  '代理英文名': 'Agent English ',
  '港口三字码': 'three code of port',
  '计费重比例': 'Billing weight ratio',
  '金额': 'Amount',
  '最低费用': 'Min',
  '查看配置信息': 'Viewing Config',
  '代理': 'Agent',
  '港口': 'Port',
  '计费模式': 'charging mode',
  '派送范围': 'Delivery scope',
  '代理客户': 'agent',
  '是否禁用': 'Forbidden or not',
  '区间段': 'Config',
  '首续重': 'First weight',
  '产品名称': 'Product Name',
  '产品代码': 'Product Code',
  '材积': 'volume',
  '国家备注': 'country notes',
  '产品备注': 'product  notes',
  '最快时效': 'fast ageing',
  '最慢时效': 'slow ageing',
  '批量处理备注': 'Batch processing notes',
  '暂无数据': 'No data',
  '价格过滤': 'Tariff filtering',
  '最快到达天数': 'ArrviedDays',
  '路径': 'Route',
  '转运备注': 'Transport notes',
  '未签约航线': 'not signed',
  '已签约航线': 'signed',
  '批量运价过滤': 'Batch Freight Rate Filtering',
  'DDU运价过滤': 'Freight Rate Filtering',
  '全部启用': 'all start using',
  '全部禁用': 'all disabled',
  '排仓时间': 'Warehouse arrangement ',
  '重量段': 'Weight segment',
  '单票': 'By ticket',
  '首重': 'First weight',
  '大于': 'more than',
  '等于': 'equal',
  '小于': 'less than',
  '时限': 'time limit',
  '产品名称备注': 'product notes',
  '产品': 'Product',
  '价格有效日期': 'Pricing Validity',
  '另行通知': 'until  further notice',
  '以上报价已含燃油、战险、地勤，不含杂费':
    'The above quotation includes fuel, war insurance, ground handling, and does not include miscellaneous fees',
  '至': 'To',
  '第': 'No.',
  '有输入项': 'has entry',
  '请不要输入非数字': 'Please do not enter non-numbers',
  '请不要输入非法字符': 'Please do not enter illegal characters',
  '有必填项未填写': 'There are required items not filled',
  '启用': 'using',
  '禁用': 'disabled',
  '空运运价查询': 'Air freight rate inquiry',
  '航线人员维护': 'Airline personnel maintenance',
  '空运客户毛利润': 'Gross profit of air freight customers',
  '同行指导报价': 'Peer guided quotation',
  '直客指导报价': 'Designated Customer guidance',
  '海外代理指导报价': 'Overseas-agent guidance quotation',
  '成本指导报价': 'Cost quotation',
  'DDU空运运价查询': 'DDU Air freight rate inquiry',
  '单寻': 'single inquiry',
  '出货记录': 'Shipping Recode',
  '显示明细信息': 'Details',
  '价格公开': 'Open Price',
  '是否签约': 'Sign Contract',
  '价格来源': 'Price Source',
  '时刻表': 'Schedule',
  '货站信息及要求': 'Information and demand of Cargo Station',
  '价格组成': 'Price Composition',
  '注意事项及杂费': 'points for attention and sundry fees',
  '航线负责人': 'Airline head',
  '仓位情况': 'Position Situation',
  '本地费用': 'Local Fee',
  '航空/班次': 'Flight',
  '起始时间': 'Start Time',
  '到达时间': 'Arrive Time',
  '程数': 'First Carrier/Second Carrier',
  '频率': 'rate',
  '飞机名称': 'Name of Aircraft',
  '载重': 'Carrying Capacity',
  '单件限重': 'Piece Weight Limit',
  '单件最大尺寸（长*宽*高）': 'Max size of a piece',
  '说明': 'Description',
  '货站名称': 'Cargo Terminal',
  '计费标准': 'Charging Standard',
  '英文名称': 'en-US',
  '杂费': 'Sundry Fees',
  '运费': 'Freight',
  '利润': 'Profit',
  '有效时间': 'Effective time',
  '战险价格': 'War risk Price',
  '燃油价格': 'Fuel  Price',
  '地勤费': 'Ground handling fee',
  '打板费': 'Unit Loading Device Fee',
  '安检费': 'security fee',
  '提货费': 'Pick-up Charge',
  '最低金额': 'Minimum',
  '负责人': 'Person in charge',
  '手机': 'Mobile Phone',
  '电话': 'Telephone',
  '传真': 'Fax',
  '航线人员': 'Airline personnel',
  '录入人': 'Entered by',
  '修改航线人员维护': 'Modify Airline personnel maintenance',
  '新增航线人员维护': 'Add Airline personnel maintenance',
  '修改空运客户毛利润': 'Modify Gross profit of air freight customers',
  '新增空运客户毛利润': 'Add Gross profit of air freight customers',
  '指定客户报价': 'Designated Customer guidance',
  '邮箱': 'E-mail',
  '保存失败，没有维护价格': 'Save failed, price not maintained',
}
