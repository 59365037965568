<template>
  <div class="sjzy-layout-Micro">
    <LayoutTabsLabel v-show="false" />
    <div class="vertival-body">
      <LayoutRouterView />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sjzy-layout-Micro {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;

  /* 默认背景透明 */
  .vertival-body {
    width: 100%;
    height: 100%;

    :deep() {
      & > div {
        height: 100%;
      }

      & > div[class*='-container'] {
        height: 100%;
      }

      // 灰色背景
      > [class*='-trb-container'] {
        height: 100%;
      }
    }
  }
}
</style>
