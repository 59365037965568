import cookie from 'js-cookie'
import {
  SystemGetAuthSystemListByLoginPOST,
  SystemGetSubSystemIdBykeyPOST,
} from '@/api/user-system/System'

export async function useBusiness() {
  const userStore = useUserStore()
  const mode = import.meta.env.MODE
  const { setSysBusinessType, setBusinessSubSystemId, setSysBusinessKey, setSystemObj } = userStore
  const { businessSubSystemId } = toRefs(userStore)

  const { setSubSysId } = useUserStore()

  const token = cookie.get(`${mode}_gtf_token`)
  console.log(import.meta.env.VITE_DEV_LOGIN, 'VITE_DEV_LOGIN')
  if (token) {
    const systemGetAuthData = await SystemGetAuthSystemListByLoginPOST({
      isBusinessType: true,
    })
    if (systemGetAuthData.code === '200') {
      // 如果微前端环境就重置，重新设置
      if (window?.__MICRO_APP_ENVIRONMENT__) {
        const obj = systemGetAuthData.data?.find(i => i.id === Number(getMicroData()?.systemId))
        if (obj) {
          setSysBusinessType(obj.sysBusinessType!)
          setBusinessSubSystemId(obj.id!)
          setSysBusinessKey(obj.key!)
        }
      }
      function isBusinessSubSystemId() {
        if (!businessSubSystemId.value) {
          setSysBusinessType(systemGetAuthData.data[0].sysBusinessType!)
          setBusinessSubSystemId(systemGetAuthData.data[0].id!)
          setSysBusinessKey(systemGetAuthData.data[0].key!)
        }
      }
      isBusinessSubSystemId()
      setSystemObj(
        systemGetAuthData.data.map((i) => {
          return {
            id: i.id,
            key: i.key,
          }
        }),
      )
    }
    const { data } = await SystemGetSubSystemIdBykeyPOST({
      key: 'CMC',
    })
    setSubSysId(Number(data))
  }
}
