export const useCostomerInfoStore = defineStore('costomerInfo', {
  state: (): CustomerInfoType => ({
    customerInfo: {},
    salesOpportunityId: undefined,
    salesOpportunityName: undefined,
    salesLeadId: undefined,
    massiveCustomerPoolId: undefined,
  }),
  persist: {
    key: 'customerInfo',
    paths: ['customerId', 'customerName', 'customerInfo'],
    storage: localStorage,
  },
  getters: {
    getCustomerInfo: state => state.customerInfo,
    getSalesLeadId: state => state.salesLeadId,
    getMassiveCustomerPoolId: state => state.massiveCustomerPoolId,
  },
  actions: {
    setMassiveCustomerPoolId(massiveCustomerPoolId: any) {
      this.massiveCustomerPoolId = massiveCustomerPoolId
    },
    setCustomerInfo(customerInfo: API.GetCustomerInfoOutput) {
      this.customerInfo = customerInfo
    },
    setSalesName(salesOpportunityName: string | undefined) {
      this.salesOpportunityName = salesOpportunityName
    },
    setSalesId(salesOpportunityId: string | undefined) {
      this.salesOpportunityId = salesOpportunityId
    },
    clearSalesName() {
      this.salesOpportunityName = undefined
    },
    clearSalesId() {
      this.salesOpportunityId = undefined
    },
    setSalesLeadId(salesLeadId: string) {
      this.salesLeadId = salesLeadId
    },
    clearSalesLeadId() {
      this.salesLeadId = undefined
    },
  },
})
export default useCostomerInfoStore
