import cookie from 'js-cookie'
import config from '@/config'

/**
 * @description 获取token
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getToken() {
  if (config.storage) {
    if (config.storage === 'localStorage') {
      return localStorage.getItem(config.tokenTableName)
    } else if (config.storage === 'sessionconfig.storage') {
      return sessionStorage.storage.getItem(config.tokenTableName)
    } else if (config.storage === 'cookie') {
      return cookie.get(config.tokenTableName)
    } else {
      return localStorage.getItem(config.tokenTableName)
    }
  } else {
    return localStorage.getItem(config.tokenTableName)
  }
}

/**
 * @description 存储token
 * @param token
 * @returns {void|*}
 */
export function setToken(token: string) {
  if (config.storage) {
    if (config.storage === 'localStorage') {
      return localStorage.setItem(config.tokenTableName, token)
    } else if (config.storage === 'sessionconfig.storage') {
      return sessionStorage.storage.setItem(config.tokenTableName, token)
    } else if (config.storage === 'cookie') {
      return cookie.set(config.tokenTableName, token)
    } else {
      return localStorage.setItem(config.tokenTableName, token)
    }
  } else {
    return localStorage.setItem(config.tokenTableName, token)
  }
}

/**
 * @description 移除token
 * @returns {void|Promise<void>}
 */
export function removeToken() {
  if (config.storage) {
    if (config.storage === 'localStorage') {
      return localStorage.removeItem(config.tokenTableName)
    } else if (config.storage === 'sessionconfig.storage') {
      return sessionStorage.storage.clear()
    } else if (config.storage === 'cookie') {
      return cookie.remove(config.tokenTableName)
    } else {
      return localStorage.removeItem(config.tokenTableName)
    }
  } else {
    return localStorage.removeItem(config.tokenTableName)
  }
}
