// @ts-ignore
/* eslint-disable */
import request from '@/utils/http/request';

/** 查询分类下权限项 POST /Auth/GetAuthByClassIdsList */
export async function AuthGetAuthByClassIdsListPOST(
  body: API.GetAuthByClassIdsListInput,
  options?: { [key: string]: any },
) {
  return request<API.GetAuthListOutputValueObjectUnifyPageResultDto>({
    url: `/userApi/Auth/GetAuthByClassIdsList`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 角色拥有的权限 GET /Auth/GetAuthByRoleIds */
export async function AuthGetAuthByRoleIdsGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.AuthGetAuthByRoleIdsGETParams,
  options?: { [key: string]: any },
) {
  return request<API.AuthValueObject[]>({
    url: `/userApi/Auth/GetAuthByRoleIds`,
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询权限项 分页 POST /Auth/GetAuthList */
export async function AuthGetAuthListPOST(
  body: API.GetAuthListInput,
  options?: { [key: string]: any },
) {
  return request<API.GetAuthListOutputValueObjectUnifyPageResultDto>({
    url: `/userApi/Auth/GetAuthList`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 角色未拥有的权限 GET /Auth/GetNotAuthByRoleIds */
export async function AuthGetNotAuthByRoleIdsGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.AuthGetNotAuthByRoleIdsGETParams,
  options?: { [key: string]: any },
) {
  return request<API.AuthValueObject[]>({
    url: `/userApi/Auth/GetNotAuthByRoleIds`,
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取角色拥有的权限 GET /Auth/GetPageListByRoleId */
export async function AuthGetPageListByRoleIdGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.AuthGetPageListByRoleIdGETParams,
  options?: { [key: string]: any },
) {
  return request<API.AuthValueObjectUnifyPageResultDto>({
    url: `/userApi/Auth/GetPageListByRoleId`,
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取用户通过角色转移拥有的权限 GET /Auth/GetTransferToUserRoleOfAuth */
export async function AuthGetTransferToUserRoleOfAuthGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.AuthGetTransferToUserRoleOfAuthGETParams,
  options?: { [key: string]: any },
) {
  return request<API.GetUserAuthListOutput[]>({
    url: `/userApi/Auth/GetTransferToUserRoleOfAuth`,
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取用户拥有的权限 POST /Auth/GetUserAllocatedOfAuthListAsync */
export async function AuthGetUserAllocatedOfAuthListPOST(
  body: API.GetUserAllocatedOfAuthTreeListInput,
  options?: { [key: string]: any },
) {
  return request<API.GetUserAuthListOutput[]>({
    url: `/userApi/Auth/GetUserAllocatedOfAuthListAsync`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取用户拥有的权限 分页 POST /Auth/GetUserAuthListAsync */
export async function AuthGetUserAuthListPOST(
  body: API.GetUserAuthListInput,
  options?: { [key: string]: any },
) {
  return request<API.GetUserAuthListOutputUnifyPageResultDto>({
    url: `/userApi/Auth/GetUserAuthListAsync`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取用户拥有的目录权限 POST /Auth/GetUserMenuList */
export async function AuthGetUserMenuListPOST(
  body: API.GetUserMenuListInput,
  options?: { [key: string]: any },
) {
  return request<API.GetMenuAuthInfo[]>({
    url: `/userApi/Auth/GetUserMenuList`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取用户拥有的权限编码 POST /Auth/GetUserPermissionLinkCode */
export async function AuthGetUserPermissionLinkCodePOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.AuthGetUserPermissionLinkCodePOSTParams,
  options?: { [key: string]: any },
) {
  return request<string[]>({
    url: `/userApi/Auth/GetUserPermissionLinkCode`,
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取用户未拥有的权限 POST /Auth/GetUserUnassignedOfAuthAsync */
export async function AuthGetUserUnassignedOfAuthPOST(
  body: API.GetUserAllocatedOfAuthTreeListInput,
  options?: { [key: string]: any },
) {
  return request<API.GetUserAuthListOutput[]>({
    url: `/userApi/Auth/GetUserUnassignedOfAuthAsync`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取用户未分配的权限 POST /Auth/GetUserUnassignedOfAuthListAsync */
export async function AuthGetUserUnassignedOfAuthListPOST(
  body: API.GetUserAllocatedOfAuthTreeListInput,
  options?: { [key: string]: any },
) {
  return request<API.GetUserAuthListOutput[]>({
    url: `/userApi/Auth/GetUserUnassignedOfAuthListAsync`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 维护权限项 POST /Auth/MatainAuth */
export async function AuthMatainAuthPOST(
  body: API.MaintainAuthInput,
  options?: { [key: string]: any },
) {
  return request<API.IdBaseDto>({
    url: `/userApi/Auth/MatainAuth`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}
