export default {
  '航司代码': '航司代码',
  '起运港': '起运港',
  '起运地': '起运地',
  '目的港': '目的港',
  '目的地': '目的地',
  '备注': '备注',
  '创建人': '创建人',
  '创建时间': '创建时间',
  '修改人': '修改人',
  '修改时间': '修改时间',
  '空运目的港要求': '空运目的港要求',
  '新增空运目的港要求': '新增空运目的港要求',
  '修改空运目的港要求': '修改空运目的港要求',
  '空运出口航线注意事项': '空运出口航线注意事项',
  '新增空运出口航线注意事项': '新增空运出口航线注意事项',
  '修改空运出口航线注意事项': '修改空运出口航线注意事项',
  '供应商': '供应商',
  '供应商中文名': '供应商中文名',
  '服务类型': '服务类型',
  '供应商备注': '供应商备注',
  '新增供应商备注': '新增供应商备注',
  '修改供应商备注': '修改供应商备注',
  'M长*宽*高*件数*': 'M长*宽*高*件数*',
  'CM长*宽*高*件数/': 'CM长*宽*高*件数/',
  '航线人员所在公司': '航线人员所在公司',
  '签约主体公司': '签约主体公司',
  '是否生成隐藏互订': '是否生成隐藏互订',
  '运营公司': '运营公司',
  '互订自动化配置': '互订自动化配置',
  '新增互订自动化配置': '新增互订自动化配置',
  '修改互订自动化配置': '修改互订自动化配置',
  '委托客户': '委托客户',
  '收货人简称': '收货人简称',
  '收货人代码': '收货人代码',
  '主单品名': '主单品名',
  '储运注意事项': '储运注意事项',
  '通知人信息': '通知人信息',
  '收货人储运注意事项': '收货人储运注意事项',
  '新增收货人储运注意事项': '新增收货人储运注意事项',
  '修改收货人储运注意事项': '修改收货人储运注意事项',
  '收货人': '收货人',
  'IATA代码': 'IATA代码',
  '起运港代码': '起运港代码',
  '起运港中文名': '起运港中文名',
  '起运港英文名': '起运港英文名',
  '航司中文名': '航司中文名',
  '航司简称': '航司简称',
  '航司英文名': '航司英文名',
  '承运人': '承运人',
  '代理人账号': '代理人账号',
  '新增代理人账号': '新增代理人账号',
  '修改代理人账号': '修改代理人账号',
  '注意事项': '注意事项',
  '财务注意事项': '财务注意事项',
  '储运/财务注意事项': '储运/财务注意事项',
  '新增储运/财务注意事项': '新增储运/财务注意事项',
  '修改储运/财务注意事项': '修改储运/财务注意事项',
  '关键字': '关键字',
  '品名关键字配置': '品名关键字配置',
  '新增品名关键字配置': '新增品名关键字配置',
  '修改品名关键字配置': '修改品名关键字配置',
  '公司英文名': '公司英文名',
  '航空公司': '航空公司',
  '发货人黑名单维护': '发货人黑名单维护',
  '新增发货人黑名单维护': '新增发货人黑名单维护',
  '修改发货人黑名单维护': '修改发货人黑名单维护',
  '不能输入中文': '不能输入中文',
  '币种': '币种',
  '价格': '价格',
  '更改': '更改',
  '代码': '代码',
  '中文名': '中文名',
  '英文名': '英文名',
  '客户代码': '客户代码',
  '国家/地区代号': '国家/地区代号',
  '空运数据管理': '空运数据管理',
  '空运档案': '空运档案',
  '上传接口配置': '上传接口配置',
  '供应商机场仓库': '供应商机场仓库',
  '起运港前置仓库': '起运港前置仓库',
  '板型管理': '板型管理',
  '航司板型': '航司板型',
  '提单自动化': '提单自动化',
  '自动化配置': '自动化配置',
  '空运本地应付': '空运本地应付',
  '空运其他费用配置': '空运其他费用配置',
  '入闸地勤收费': '入闸地勤收费',
  '香港代理人': '香港代理人',
  '相关注意事项': '相关注意事项',
  '导出数据': '导出数据',
  '下载模板': '下载模板',
  '导入文件': '导入文件',
  '储运/财务注意事项自动化': '储运/财务注意事项自动化',
  '储运/财务注意事项自动化模板': '储运/财务注意事项自动化模板',
  '空运目的港要求模板': '空运目的港要求模板',
  '航线注意事项': '航线注意事项',
  '航线注意事项模板': '航线注意事项模板',
  '先下载模板，按要求填写后上传': '先下载模板，按要求填写后上传',
  '下载空模板': '下载空模板',
  '将文件拖到此区域或点击': '将文件拖到此区域或点击',
  '选择文件上传': '选择文件上传',
  '数据导入中，请耐心等待': '数据导入中，请耐心等待',
  '导入成功': '导入成功',
  '总共导入': '总共导入',
  '条数据': '条数据',
  '导入失败': '导入失败',
  '请检查文件格式和内容是否正确': '请检查文件格式和内容是否正确',
  '重新选择文件': '重新选择文件',
  '导入异常': '导入异常',
  '成功导入': '成功导入',
  '失败': '失败',
  '条': '条',
  '下载错误数据': '下载错误数据',
  '是': '是',
  '否': '否',
  '明细': '明细',
  '发货人公司': '发货人公司',
  '储运财务注意事项自动化导出数据': '储运财务注意事项自动化导出数据',
  '储运财务注意事项': '储运财务注意事项',
  '储运财务注意事项自动化模板': '储运财务注意事项自动化模板',
  '只能输入数字和小数点，字数限制八位和小数点后两位数':
    '只能输入数字和小数点，字数限制八位和小数点后两位数',
  '只能输入8位数字和小数点后两位': '只能输入8位数字和小数点后两位',
  '录入多个品名时使用逗号隔开': '录入多个品名时使用逗号隔开',
  '只能输入正整数': '只能输入正整数',
  '只能输入数字(保留两位小数)': '只能输入数字(保留两位小数)',
  '只能输入数字(保留三位小数)': '只能输入数字(保留三位小数)',
  '生效依据': '生效依据',
  '费用类型': '费用类型',
  '目的港转运方式': '目的港转运方式',
  '中转1': '中转1',
  '中转1方式': '中转1方式',
  '中转2': '中转2',
  '中转2方式': '中转2方式',
  '货物类型': '货物类型',
  '价格类型': '价格类型',
  '飞机类型': '飞机类型',
  '加收打板费': '加收打板费',
  '开始时间': '开始时间',
  '结束时间': '结束时间',
  '是否公开': '是否公开',
  '预计到达天数': '预计到达天数',
  '英文备注': '英文备注',
  '直客备注': '直客备注',
  '直客英文备注': '直客英文备注',
  '同行专用备注': '同行专用备注',
  '利润分成备注': '利润分成备注',
  '利润不分成备注': '利润不分成备注',
  '直飞起运港': '直飞起运港',
  '直飞第一中转港': '直飞第一中转港',
  '转运起运港': '转运起运港',
  '转运港1': '转运港1',
  '转运港1方式': '转运港1方式',
  '转运港2': '转运港2',
  '转运港2方式': '转运港2方式',
  '转运港3': '转运港3',
  '转运港3方式': '转运港3方式',
  '机型': '机型',
  '显示备注': '显示备注',
  '转飞起运港': '转飞起运港',
  '公开价格': '公开价格',
  '非公开价格': '非公开价格',
  '参考价格': '参考价格',
  '含燃油战险': '含燃油战险',
  '净运费': '净运费',
  '头程': '头程',
  '二程': '二程',
  '客机': '客机',
  '货机': '货机',
  '客/货机': '客/货机',
  '卡车': '卡车',
  '飞机': '飞机',
  '所有未保存的数据会被清空': '所有未保存的数据会被清空',
  '是否确认删除': '是否确认删除',
  '请不要输入中文': '请不要输入中文',
  '当前行不允许修改': '当前行不允许修改',
  '请不要在序号行进行': '请不要在序号行进行',
  '请不要在选择行进行': '请不要在选择行进行',

  '驳船起运': '驳船起运',
  '不能相同': '不能相同',
  '新增整柜客户毛利润': '新增整柜客户毛利润',
  '修改整柜客户毛利润': '修改整柜客户毛利润',
  '新增拼箱客户毛利润': '新增拼箱客户毛利润',
  '修改拼箱客户毛利润': '修改拼箱客户毛利润',
  '只能输入小数点前八位和后两位数': '只能输入小数点前八位和后两位数',
  '不允许都为空': '不允许都为空',
  '同行指导报价查询': '同行指导报价查询',
  '直客指导报价查询': '直客指导报价查询',
  '海外代理指导报价查询': '海外代理指导报价查询',
  '指定客户报价查询': '指定客户报价查询',
  '成本报价查询': '成本报价查询',

  '渠道类型': '渠道类型',
  '整柜保底毛利润': '整柜保底毛利润',
  '新增整柜保底毛利润': '新增整柜保底毛利润',
  '修改整柜保底毛利润': '修改整柜保底毛利润',
  '拼箱保底毛利润': '拼箱保底毛利润',
  '新增拼箱保底毛利润': '新增拼箱保底毛利润',
  '修改拼箱保底毛利润': '修改拼箱保底毛利润',
  '空运保底毛利润': '空运保底毛利润',
  '新增空运保底毛利润': '新增空运保底毛利润',
  '修改空运保底毛利润': '修改空运保底毛利润',
  '以上字段必填一个': '以上字段必填一个',
  '显示来源': '显示来源',
  '毛利润': '毛利润',
  '保底': '保底',
  '成本': '成本',
  '非注册': '非注册',
  '注册': '注册',
  '运价查询': '运价查询',
  '空运运价查询': '空运运价查询',
  '航线人员维护': '航线人员维护',
  '空运客户毛利润': '空运客户毛利润',
  '同行指导报价': '同行指导报价',
  '直客指导报价': '直客指导报价',
  '海外代理指导报价': '海外代理指导报价',
  '成本指导报价': '成本指导报价',
  'DDU空运运价查询': 'DDU空运运价查询',
  '单寻': '单寻',
  '出货记录': '出货记录',
  '显示明细信息': '显示明细信息',
  '价格公开': '价格公开',
  '是否签约': '是否签约',
  '价格来源': '价格来源',
  '时刻表': '时刻表',
  '货站信息及要求': '货站信息及要求',
  '价格组成': '价格组成',
  '注意事项及杂费': '注意事项及杂费',
  '航线负责人': '航线负责人',
  '仓位情况': '仓位情况',
  '本地费用': '本地费用',
  '航空/班次': '航空/班次',
  '起始时间': '起始时间',
  '到达时间': '到达时间',
  '程数': '程数',
  '频率': '频率',
  '飞机名称': '飞机名称',
  '载重': '载重',
  '单件限重': '单件限重',
  '单件最大尺寸（长*宽*高）': '单件最大尺寸（长*宽*高）',
  '说明': '说明',
  '货站名称': '货站名称',
  '计费标准': '计费标准',
  '英文名称': '英文名称',
  '杂费': '杂费',
  '运费': '运费',
  '利润': '利润',
  '有效时间': '有效时间',
  '战险价格': '战险价格',
  '燃油价格': '燃油价格',
  '地勤费': '地勤费',
  '打板费': '打板费',
  '安检费': '安检费',
  '提货费': '提货费',
  '最低金额': '最低金额',
  '负责人': '负责人',
  '手机': '手机',
  '电话': '电话',
  '传真': '传真',
  '航线人员': '航线人员',
  '录入人': '录入人',
  '修改航线人员维护': '修改航线人员维护',
  '新增航线人员维护': '新增航线人员维护',
  '修改空运客户毛利润': '修改空运客户毛利润',
  '新增空运客户毛利润': '新增空运客户毛利润',
  '指定客户报价': '指定客户报价',
  '邮箱': '邮箱',
  '保存失败，没有维护价格': '保存失败，没有维护价格',
}
