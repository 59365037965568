import type { TranslationArgs } from '@sjzy/ui'
import type { App } from 'vue'
import SjzyUi, { clickOutside, permission } from '@sjzy/ui'
import i18n from '@/i18n'
import '@sjzy/ui/es/style.css'
import 'viewerjs/dist/viewer.css'
import request from '@/utils/http/request'

export function setupSjzyUi(app: App) {
  SjzyUi.setup({
    i18n: (key: string, args?: TranslationArgs) => (i18n as any).global.t(key, args),
    // table: {
    //   currentPageField: 'pageIndex',
    //   pageSizeField: 'pageSize',
    //   dataField: 'data.list',
    //   totalField: 'data.pagination.total',
    //   pagerConfig: {
    //     pageSize: 100,
    //     pageSizes: [50, 100, 500, 1000, 2000, 5000, 10000],
    //   },
    //   tableRowId: 'id',
    //   showSetting: true,
    // } as any,
  })
  SjzyUi.setXHR(request as any)
  app.use(SjzyUi)

  app.directive('clickOutside', clickOutside)
  app.directive('permission', permission)
}
