// @ts-ignore
/* eslint-disable */
import request from '@/utils/http/request';

/** 获取用户信息 POST /User/GetCurrentUserBasicInfoV2 */
export async function UserBaseInfoGetCurrentUserBasicInfoPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: UserApi.UserBaseInfoGetCurrentUserBasicInfoPOSTParams,
  options?: { [key: string]: any },
) {
  return request<UserApi.CurrentUserInfoUnifyResultDto>({
    url: `/userApi/User/GetCurrentUserBasicInfoV2`,
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取用户信息，数据量多别用 POST /User/GetUserInformation */
export async function UserBaseInfoGetUserInformationPOST(
  body: UserApi.UserInformationRequestModel,
  options?: { [key: string]: any },
) {
  return request<UserApi.UserInformationModelUnifyPageResultDtoUnifyResultDto>({
    url: `/userApi/User/GetUserInformation`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}
