// useFormSlot
import { useFormSlot } from './form/useFormSlot'

// useFormat
import { useFormat } from './formats/useFormat'

import { useClearDirty, useFormDirty, useGetPagesFormDirty, useIsDirty } from './form/useFormDirty'

// useContentHeight
import { useContentHeight, useLayoutHeight } from './table/useContentHeight'
import { useResetData } from './table/useResetData'
import { useTableSlot } from './table/useTableSlot'
import { useDriver } from './table/useDriver'

// useWindowSizeFn
import { useWindowSizeFn } from './event/useWindowSizeFn'

// useMicroApp
import { getMicroData, sendMicroData } from './event/useMicroApp'

// onMountedOrActivated
import { onMountedOrActivated } from './core/useMountedOrActived'

// $baseMessage
import { $baseConfirm, $baseMessage, $baseNotify } from './core/useMessage'

// useDesign
import { useDesign } from './web/useDesign'
import { useOrderCopy } from './web/useOrderCopy'
import { usePrinter } from './web/usePrinter'

// useTabs
import { useTabs } from './web/useTabs'
import { useIsScroll } from './web/useIsScroll'

// form 验证
// import { useFormValidator } from './form/useFormValidator'

// 字典
import { useDict } from './dict/useDict'
import { useDictGroup } from './dict/useDictGroup'
import { useDictTree } from './dict/useDictTree'
import { useValidRulesModelHooks } from './form/useValidRulesModelHooks'
// 多语言
import { translateTitle } from '@/utils'
import { setDownTemplateName } from '@/utils/file/download'
import { getLablesInLanguage } from '@/utils/language'

const t = translateTitle

export {
  useContentHeight,
  useWindowSizeFn,
  onMountedOrActivated,
  useLayoutHeight,
  $baseConfirm,
  $baseMessage,
  $baseNotify,
  useDesign,
  useFormat,
  useTableSlot,
  useResetData,
  useOrderCopy,
  useIsScroll,
  usePrinter,
  t,
  useTabs,
  useDict,
  useDictGroup,
  useDictTree,
  useDriver,
  setDownTemplateName,
  useIsDirty,
  useClearDirty,
  useFormDirty,
  useGetPagesFormDirty,
  getMicroData,
  sendMicroData,
  useFormSlot,
  useValidRulesModelHooks,
  getLablesInLanguage,
}
