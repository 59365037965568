<script lang="ts" setup>
import { Icon } from '@iconify/vue'
// import logo from '@/assets/logo .png';

defineOptions({
  name: 'SjzyIcon',
})

const props = defineProps({
  /** 图标地址 */
  icon: {
    type: String,
    default: '',
  },
  /** 是否是自定义svg */
  isCustomSvg: {
    type: Boolean,
    default: false,
  },
  /** 标题 */
  title: {
    type: String,
    default: '',
  },
  /** 是否是iconfont */
  iconFont: {
    type: String,
    default: '',
  },
  /** size */
  size: {
    type: Number,
    default: 16,
  },
  /** 是否禁用 */
  disabled: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits<{
  click: []
}>()
const slots = defineSlots<{
  default?: () => any
  prefix?: () => any
}>()
// const slots = useSlots()
function click() {
  if (props.disabled)
    return
  emit('click')
}
</script>

<template>
  <template v-if="slots.default || slots.prefix">
    <div
      v-bind="$attrs"
      :class="[{ 'cursor-not-allowed': props.disabled }]"
      class="flex items-center"
      :title="props.title"
      @click="click"
    >
      <slot name="prefix" />
      <i
        v-if="isCustomSvg"
        v-bind="$attrs"
        class="iconSvg inline-flex"
        :style="`fill:currentColor;width:${size}px;height:${size}px;`"
        v-html="icon"
      />
      <i
        v-else-if="props.iconFont"
        class="iconfont"
        :class="[props.iconFont]"
        :style="`font-size:${size}px;width:${size}px;height:${size}px;line-height:${size}px;`"
        :width="size"
      />
      <i v-else class="icon-btn" v-bind="$attrs">
        <Icon
          :icon="props.icon"
          :title="props.title"
          :style="`font-size:${size}px;`"
          :width="size"
        />
      </i>
      <slot />
    </div>
  </template>
  <template v-else>
    <i
      v-if="isCustomSvg"
      v-bind="$attrs"
      class="iconSvg inline-flex"
      :style="`fill:currentColor;width:${size}px;height:${size}px;`"
      v-html="icon"
    />
    <i
      v-else-if="props.iconFont"
      class="iconfont"
      :class="[props.iconFont, { 'cursor-not-allowed': props.disabled }]"
      :style="`font-size:${size}px;width:${size}px;height:${size}px;line-height:${size}px;`"
      :width="size"
      :title="props.title"
      v-bind="$attrs"
      @click="click"
    />
    <i v-else class="icon-btn" v-bind="$attrs" @click="click">
      <Icon :icon="props.icon" :title="props.title" :style="`font-size:${size}px;`" :width="size" />
    </i>
  </template>
</template>

<style scoped lang="scss">
.cursor-not-allowed {
  color: (--el-button-disabled-text-color);
  opacity: 0.5;

  &:hover {
    color: var(--el-button-disabled-text-color);
    opacity: 0.5;
  }
}

.icon-btn {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-user-select: none;
  user-select: none;
}
</style>

<style>
.iconSvg {
  svg {
    width: inherit !important;
    height: inherit !important;
  }
}
</style>
