/*
 * @Author: 孔成明 983551019@qq.com
 * @Date: 2022-09-12 21:23:09
 * @LastEditors: 丁彪 673589747@qq.com
 * @LastEditTime: 2023-10-09 16:15:32
 * @FilePath: \Gotofreight-UC\src\i18n\lang\en.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  '请先': 'Please first',
  '下载导入模版': 'Download Import Template',
  '按要求填写后上传': 'Fill in as required and upload',
  '将文件拖到此区域或': 'Drag files to this area or',
  '点击上传': 'Click to upload',
  '首页': 'Home',
  '看板': 'Dashboard',
  '工作台': 'Workbench',
  '仓库': 'Store',
  '更新日志': 'Change log',
  '配置': 'Settings',
  '无分栏': 'No column',
  '个人中心': 'User center',
  '用户管理': 'User management',
  '角色管理': 'Role management',
  '部门管理': 'Department management',
  '菜单管理': 'Menu management',
  '任务管理': 'Task management',
  '系统日志': 'System log',
  '组件': 'Part',
  '其他': 'Other',
  '角色权限': 'Roles',
  '外链': 'External links',
  '图标': 'Icon',
  '常规图标': 'Awesome icon',
  '小清新图标': 'Remix icon',
  '图标选择器': 'Icon selector',
  '表格': 'Table',
  '综合表格': 'Comprehensive table',
  '行内编辑表格': 'Inline edit table',
  '自定义表格': 'Custom table',
  '动态表格': 'Dynamic table',
  '描述': 'Description',
  '日历': 'Calendar',
  '表单': 'Form',
  '综合表单': 'Comprehensive form',
  '分步表单': 'Step form',
  '按钮': 'Button',
  '文字链接': 'Link',
  '单选框': 'Radip',
  '多选框': 'Checkbox',
  '输入框': 'Input',
  '计数器': 'Input number',
  '选择器': 'Select',
  '开关': 'Switch',
  '滑块': 'Slider',
  '时间选择器': 'Time picker',
  '日期选择器': 'Date picker',
  '日期时间选择器': 'Date time picker',
  '评分': 'rate',
  '工作流': 'Workflow',
  '图表': 'Echarts',
  '打印': 'Print',
  '手机预览': 'Mobile preview',
  '头像裁剪': 'Head cropper',
  '时间线': 'Timeline',
  '数字自增长': 'Count',
  '多标签': 'Tabs',
  '分享': 'Share',
  '动态锚点': 'Dynamic anchor',
  '动态Meta': 'Dynamic meta',
  '动态路径参数': 'Dynamic segment',
  '列表': 'List',
  '多级路由缓存': 'Menu1',
  '多级路由1-1': 'Menu1-1',
  '多级路由1-1-1': 'Menu1-1-1',
  '拖拽': 'Drag',
  '卡片拖拽': 'Card drag',
  '无框': 'No layout',
  '加载': 'Loading',
  '视频播放器': 'Player',
  '编辑器': 'Editor',
  '富文本编辑器': 'Rich text editor',
  '腾讯文档': 'Wang editor',
  '上传': 'Upload',
  '错误日志模拟': 'Log',
  'Excel': 'Excel',
  '导出Excel': 'Export excel',
  '导出选中行Excel': 'Export selected excel',
  '导出合并Excel': 'Export merge header excel',
  'Css动画': 'Cssfx',
  '第三方登录': 'Social login',
  '物料源': 'Material',
  '物料市场': 'Material market',
  '工具': 'Tools',
  '错误页': 'Error',
  '403': '403',
  '404': '404',
  '邮件': 'Email',
  '清空消息': 'Clear message',
  '更多': 'More',
  '关闭其他': 'Close other',
  '关闭左侧': 'Close left',
  '关闭右侧': 'Close right',
  '关闭全部': 'Close all',
  '退出登录': 'Logout',
  '登录': 'Login',
  '验证码': 'Verification code',
  '注册': 'register',
  '主题配置': 'Theme',
  '主题': 'Theme',
  '常用设置': 'Common settings',
  '其它设置': 'Other settings',
  '分栏布局时生效': 'Column layout takes effect',
  '标签开启时生效': 'Effective when the label is opened',
  '布局配置仅在电脑视窗下生效，手机视窗时将默认锁定为纵向布局':
    'The layout configuration only takes effect in the computer window,the vertical layout will be locked in the mobile window by default',
  '支持纵向布局、分栏布局、综合布局、常规布局，不支持横向布局、浮动布局':
    'Vertical layout, column layout, comprehensive layout and general layout are supported, while horizontal layout and floating layout are not supported',
  '默认': 'Default',
  '海洋之心': 'Ocean',
  '绿荫草场': 'Green',
  '碰触纯白': 'White',
  '月上重火': 'Red',
  '蓝黑': 'Blue black',
  '蓝白': 'Blue white',
  '绿黑': 'Green black',
  '绿白': 'Green white',
  '红黑': 'Red black',
  '红白': 'Red white',
  '渐变': 'Ocean',
  '布局': 'Layouts',
  '分栏': 'Column',
  '纵向': 'Vertical',
  '横向': 'Horizontal',
  '综合': 'Comprehensive',
  '常规': 'Common',
  '浮动': 'Float',
  '菜单背景': 'Background',
  '菜单宽度': 'Menu width',
  '分栏风格': 'Column style',
  '箭头': 'Arrow',
  '头部固定': 'Header',
  '固定': 'Fixed',
  '不固定': 'No fixed',
  '标签': 'Tabs',
  '标签风格': 'Tabs style',
  '标签图标': 'Tabs icon',
  '卡片': 'Card',
  '灵动': 'Smart',
  '圆滑': 'Smooth',
  '国际化': 'Language',
  '进度条': 'Progress',
  '刷新': 'Refresh',
  '通知': 'Notice',
  '全屏': 'Full screen',
  '锁屏': 'Lock screen',
  '页面动画': 'Page transition',
  '搜索': 'Search',
  '开启': 'Open',
  '关闭': 'Close',
  '随机换肤': 'Random',
  '购买源码': 'Buy',
  '拷贝源码': 'Code',
  '恢复默认': 'Defalut',
  '清理缓存': 'Claer',
  '保存': 'Save',
  '欢迎来到': 'Welcome to',
  '请输入用户名': 'Please enter one user name',
  '请输入密码': 'Please input a password',
  '用户名不能为空': 'The user name cannot be empty',
  '密码不能少于6位': 'The password cannot be less than 6 digits',
  '请输入正确的手机号': 'Please enter the correct mobile phone number',
  '请输入手机号': 'Please enter your mobile phone number',
  '请输入手机验证码': 'Please input the mobile phone verification code',
  '获取验证码': 'Get captcha',
  '屏幕已锁定': 'Screen already locked',
  '解锁': 'Unlock',
  '切换壁纸': 'Switch wallpaper',
  '列表高度': 'Height',
  '新增': 'Add',
  '删除': 'Delete',
  '导入': 'Import',
  '导出': 'Export',
  '下载模板': 'Download Template',
  '查询': 'Query',
  '重置': 'Reset',
  '操作': 'Action Column',
  '修改': 'Modify',
  '件数': 'Pieces',
  '重量': 'Weight',
  '体积': 'Volume',
  '体积重': 'Volume Weight',
  '分泡比例': 'Bulky Rate',
  '分泡计费重': 'Bulky Charge Weight',
  '添加': 'Add',
  '清空': 'Empty',
  '长(CM)': 'Length(cm)',
  '宽(CM)': 'width(cm)',
  '高(CM)': 'height(cm)',
  '算泡工具': 'Computing Tool',
  '已选中': 'Selected',
  '条': '',
  '数据加载中': 'Data loading',
  '已选择': 'Selected',
  '空数据': 'No data',
  '请输入': 'Please input',
  '请选择': 'Please select',
  '列设置': 'Column setting',
  '宽松': 'medium',
  '舒适': 'small',
  '紧凑': 'mini',
  '反选': 'Invert',
  '展开': 'Expand',
  '新增成功': 'Add succeeded',
  '修改成功': 'Modification succeeded',
  '保存成功': 'Submit succeeded',
  '收起': 'Collapse',
  '最多打开20个标签页': 'A maximum of 20 tabs can be opened',
  '删除成功': 'successfully delete',
  '是否确认删除？': 'Confirm deletion？',
  '暂无搜索结果': 'No search result is available',
  '英文备注': 'English remarks',
  '初始录入人': 'Initial entry person',
  '录入时间': 'Entry time',
  '最终修改人': 'Final modifier',
  '字段信息': '字段信息',
  '系统字段': 'Field information',
  '已选字段': 'Selected Fields',
  '自定义列清空': 'Clear',
  '自定义列删除': 'Del',
  '自定义列置顶': 'Top',
  '自定义列固定': 'Lock',
  '最多可固定7列': 'Fixed up to 7 col',
  '置顶': 'Top',
  '自定义列': 'Custom column',
  '全选': 'check all',
  '加载中': 'loading',
  '无数据': 'no data',
  '计算结果': 'result',
  '计算方式': 'formula mode',
  '请先勾选数据并且价格只能输入小数点前十位，后两位！':
    'Please check the data first and the price can only enter the first ten decimal points, the last two!',
  '更新提示': 'Update prompt',
  '重新加载': 'Reload',
  '新内容可用，点击重新加载按钮更新': 'New content available, click Reload button to update',
  '地域': ' Region',
  '区域': 'Area',
  '省份': 'Province',
  '铁运': 'Railway',
  '铁运档案': 'Railway Archives',
  '铁运站点': 'Railway Station',
  '铁运航线': 'Railway Route',
  '海运档案': 'Ocean  Archives',
  '海港': 'Seaport',
  '海运航线': 'Shipping Route',
  '海运整柜柜型': 'FCL Container Type',
  '城市附近港口': 'Nearby Port Query',
  '空港': 'Airport',
  '航司': 'Airline Supplier',
  '机型': 'Aircraft Type',
  '航班': 'Flight',
  '货站': 'Cargo Station',
  '航司货站': 'Airline Cargo Station',
  '航空卡车时刻': 'Truck Time',
  '货物类型': 'Cargo Type',
  '航司货物类型': 'Airline Cargo Type',
  '空运航线': 'Airline Route',
  '所有航司路径维护': 'Route Information',
  '城市附近机场': 'Nearby Airport Query',
  '燃油': 'Fuel Surcharge',
  '战险': 'War Risk Surcharge',
  '打板费': 'Unit Loading Device Fee',
  '序号': 'Index',
  '二字代码': 'digit2Code',
  '录入人': 'Entered by',
  '海运出口目的港要求': 'POD Requirements- Ocean Export',
}
