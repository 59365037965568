import type { FieldType } from '@/types/store'

export const useFieldStore = defineStore('field', {
  state: (): FieldType => ({
    chance: 0,
    clientId: '',
    /**
     * clientName 首页业务关注本周新线索点击名字点击销售过程管理销售线索的公司名称
     *  opportunityName  首页业务关注本周新商机点击名字点击销售过程管理销售商机的公司名称
     */
    clientName: '',
    opportunityName: '',
    newClientDev: '',
    isEnd: undefined,
  }),
  persist: {
    key: 'field',
    paths: ['chance', 'clientId'],
    storage: localStorage,
  },
  getters: {
    getClientName: state => state.clientName,
    getOpportunityName: state => state.opportunityName,
    getNewClientDev: state => state.newClientDev,
    getIsEnd: state => state.isEnd,
  },
  actions: {
    setChance(chance: number) {
      this.chance = chance
    },
    setClientId(id: string) {
      this.clientId = id
    },
    setClientName(clientName: string) {
      this.clientName = clientName
    },
    setOpportunityName(opportunityName: string) {
      this.opportunityName = opportunityName
    },
    setNewClientDev(newClientDev: string, isEnd?: boolean) {
      this.newClientDev = newClientDev
      this.isEnd = isEnd
    },
  },
})

export default useFieldStore
