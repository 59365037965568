// @ts-ignore
/* eslint-disable */
import request from '@/utils/http/request';

/** <br>20、角色接口描述
            角色编辑 POST /Role/Edit */
export async function RoleEditPOST(body: API.RoleInput, options?: { [key: string]: any }) {
  return request<API.IdBaseDto>({
    url: `/userApi/Role/Edit`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 用户添加/删除个人角色 POST /Role/EditRoleWithUserId */
export async function RoleEditRoleWithUserIdPOST(
  body: API.UserRoleInput,
  options?: { [key: string]: any },
) {
  return request<boolean>({
    url: `/userApi/Role/EditRoleWithUserId`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    data: body,
    ...(options || {}),
  });
}

/** <br>21、角色查询接口描述
            获取Role分页查询记录 GET /Role/GetListByPage */
export async function RoleGetListByPageGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.RoleGetListByPageGETParams,
  options?: { [key: string]: any },
) {
  return request<API.RoleOutputUnifyPageResultDto>({
    url: `/userApi/Role/GetListByPage`,
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** <br>
            61、权限转移查询人员未转移的个人角色接口描述
            
            获取未转移的个人类型角色 GET /Role/GetNotAgentRoleByUserId */
export async function RoleGetNotAgentRoleByUserIdGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.RoleGetNotAgentRoleByUserIdGETParams,
  options?: { [key: string]: any },
) {
  return request<API.RoleValueObject[]>({
    url: `/userApi/Role/GetNotAgentRoleByUserId`,
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** <br>21、角色查询接口描述
            获取Role不分页查询记录 GET /Role/GetRoleList */
export async function RoleGetRoleListGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.RoleGetRoleListGETParams,
  options?: { [key: string]: any },
) {
  return request<API.RoleOutput[]>({
    url: `/userApi/Role/GetRoleList`,
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取子系统中的角色 GET /Role/GetRoleSystemInfoBySystemId */
export async function RoleGetRoleSystemInfoBySystemIdGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.RoleGetRoleSystemInfoBySystemIdGETParams,
  options?: { [key: string]: any },
) {
  return request<API.RoleValueObject[]>({
    url: `/userApi/Role/GetRoleSystemInfoBySystemId`,
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取用户拥有的角色 GET /Role/GetRoleSystemInfoByUserId */
export async function RoleGetRoleSystemInfoByUserIdGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.RoleGetRoleSystemInfoByUserIdGETParams,
  options?: { [key: string]: any },
) {
  return request<API.RoleValueSystemObject[]>({
    url: `/userApi/Role/GetRoleSystemInfoByUserId`,
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** <br>
            25、角色人员筛选接口描述
            
            角色筛选员工信息(包含转移用户) GET /Role/QueryEmployeeByRoleId */
export async function RoleQueryEmployeeByRoleIdGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.RoleQueryEmployeeByRoleIdGETParams,
  options?: { [key: string]: any },
) {
  return request<API.EmployeeOutput[]>({
    url: `/userApi/Role/QueryEmployeeByRoleId`,
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
