type showAccountBalanceModel = {
  showAccountBalance: boolean
}

export const useAccountBalanceStore = defineStore('accountBalance', {
  state: (): showAccountBalanceModel => ({
    showAccountBalance: true,
  }),

  actions: {
    updateShowAccountBalance(status: boolean) {
      this.showAccountBalance = status
    },
  },
})
export default useAccountBalanceStore
