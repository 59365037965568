<script lang="ts" setup>
import SjzyLayoutColumn from './SjzyLayoutColumn/index.vue'
import SjzyLayoutHorizontal from './SjzyLayoutHorizontal/index.vue'
import SjzyLayoutVertical from './SjzyLayoutVertical/index.vue'
import SjzyLayoutMicro from './SjzyLayoutMicro/index.vue'
import SjzyLayoutIframe from './SjzyLayoutIframe/index.vue'
import { SystemGetAuthSystemListByLoginPOST } from '@/api/user-system/System'
import { handleActivePath } from '@/router'

defineOptions({
  name: 'Layouts',
})

/** 布局组件 */
const layoutComponents: {
  [key: string]:
    | typeof SjzyLayoutColumn
    | typeof SjzyLayoutHorizontal
    | typeof SjzyLayoutVertical
    | typeof SjzyLayoutMicro
} = {
  column: SjzyLayoutColumn,
  horizontal: SjzyLayoutHorizontal,
  vertical: SjzyLayoutVertical,
  iframe: SjzyLayoutIframe,
  Micro: SjzyLayoutMicro,
}

const route = useRoute()
const settingsStore = useSettingsStore()
const { device, collapse, theme } = storeToRefs(settingsStore)
const { toggleCollapse } = settingsStore
const routesStore = useRoutesStore()
const { tab, activeMenu } = storeToRefs(routesStore)
const userStore = useUserStore()
const { setSysBusinessType, setBusinessSubSystemId, setSysBusinessKey, setSystemObj } = userStore
const { businessSubSystemId } = toRefs(userStore)

/** 自适应切换 */
const resizeBody = useDebounceFn(() => {
  if (theme.value.layout !== 'vertical')
    return
  const width = document.body.getBoundingClientRect().width - 1
  if (!collapse.value && width < 1600)
    toggleCollapse()
  if (collapse.value && width > 1600)
    toggleCollapse()
}, 150)

/** 清除监听 */
const cleanup = useEventListener('resize', () => {
  resizeBody()
})

watch(
  route,
  () => {
    if (tab.value.data !== route.matched[0].name)
      tab.value.data = route.matched[0].name?.toString()
    // console.log(route, 'route')
    activeMenu.value.data = handleActivePath(route)
  },
  { immediate: true },
)

watchEffect(() => {
  const isIframe = route.query.type && route.query.type === 'iframe'
  if (isIframe) {
    theme.value.layout = 'iframe'
  }
  else if (window?.__MICRO_APP_ENVIRONMENT__) {
    theme.value.layout = 'Micro'
  }
})

resizeBody()

onBeforeMount(async () => {
  const data = await SystemGetAuthSystemListByLoginPOST({
    // sysBusinessTypeList: [1, 2, 3],
    isBusinessType: true,
  })
  if (data.code === '200') {
    // 如果微前端环境就重置，重新设置
    if (window?.__MICRO_APP_ENVIRONMENT__) {
      const obj = data.data?.find(i => i.id === Number(getMicroData()?.systemId))
      if (obj) {
        setSysBusinessType(obj.sysBusinessType!)
        setBusinessSubSystemId(obj.id!)
        setSysBusinessKey(obj.key!)
      }
    }
    if (!businessSubSystemId.value) {
      const id = Number(route.query.businessSubSystemId || 0)
      let item = data.data[0]
      if (id) {
        item = data.data.filter(item => item.id === id)?.[0]
      }
      setSysBusinessType(item.sysBusinessType!)
      setBusinessSubSystemId(item.id!)
      setSysBusinessKey(item.key!)

      // nameStr.value = data.data[0].cnName!
    }
    setSystemObj(
      data.data.map((i) => {
        return {
          id: i.id,
          key: i.key,
        }
      }),
    )
  }
})
onUnmounted(() => {
  cleanup()
})
</script>

<template>
  <div class="sjzy-wrapper dark:text-white">
    <Component
      :is="layoutComponents[theme.layout]"
      :collapse="collapse"
      :device="device"
      :fixed-header="theme.fixedHeader"
      :show-tabs="theme.showTabs"
    />
    <ElBacktop target="#micro-crm-app" />
    <ThemeDrawer />
  </div>
</template>

<style lang="scss" scoped>
.sjzy-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  [class*='sjzy-layout-'] {
    height: 100%;

    :deep() {
      .sjzy-layout-header {
        box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
      }

      .fixed-header {
        position: fixed;
      }

      .sjzy-layout-header,
      [class*='-bar-container'] {
        transition: var(--sjzy-layout-transition);
      }
    }
  }
}
</style>
