/**
 * vxe-table-extend-cell-area v2.1.11
 * Purchase authorization: https://vxetable.cn/plugins/
 * @copyright x_extends@163.com
 */
/* eslint-disable */
/* eslint-disable */
import XEUtils from 'xe-utils'
import { h, ref, nextTick, onMounted, onUnmounted, resolveComponent } from 'vue'
import { VXETable } from 'vxe-table'
;(function () {
  function t(e) {
    return 'vxe.pro.' + e
  }
  function l(e) {
    return 'vxe.plugins.extendCellArea.' + e
  }
  function r(e, t, l) {
    return e.replace(t, l)
  }
  const o = VXETable
  let n = null
  const a = o.hooks,
    s = window,
    i = '\n'
  let d = !1
  const c = s[`a${d ? '' : 'to'}b`],
    u = document,
    f = 'main',
    g = 'copy',
    m = 'extend',
    w = 'multi',
    v = 'active',
    p = s[c('Y29uc29sZQ==')],
    C = {
      F2: 'F2',
      F10: 'F10',
      ESCAPE: 'Escape',
      ENTER: 'Enter',
      TAB: 'Tab',
      DELETE: 'Delete',
      BACKSPACE: 'Backspace',
      SPACEBAR: ' ',
      CONTEXT_MENU: 'ContextMenu',
      ARROW_UP: 'ArrowUp',
      ARROW_DOWN: 'ArrowDown',
      ARROW_LEFT: 'ArrowLeft',
      ARROW_RIGHT: 'ArrowRight',
      PAGE_UP: 'PageUp',
      PAGE_DOWN: 'PageDown',
      HOME: 'Home',
      END: 'End',
      A: 'a',
      X: 'x',
      C: 'c',
      V: 'v',
      F: 'f',
      H: 'h',
      M: 'm',
    },
    A = {
      ' ': 'Spacebar',
      'Apps': C.CONTEXT_MENU,
      'Del': C.DELETE,
      'Up': C.ARROW_UP,
      'Down': C.ARROW_DOWN,
      'Left': C.ARROW_LEFT,
      'Right': C.ARROW_RIGHT,
    }
  let S = !1,
    E = !1
  const x = 'pro-' + 'auth',
    R = 'Enter',
    b = 'error',
    I = 'operErr',
    y = b,
    D = 4,
    T = S ? '' : b,
    B = 'warn',
    V = T ? 'log' : '',
    F = 'o' + (V ? '' : 'x') + ['n', 'mo', 'use', p ? 'm' : '', 'ov', 'e'].join(''),
    M = 'o' + (V ? '' : 'x') + ['n', 'm', p ? 'ouse' : '', 'up'].join(''),
    $ = 'checkbox',
    O = 'radio',
    W = c && p ? 'getSetupOptions' : '',
    _ = S ? '' : 'Event',
    L = d ? '' : 'message',
    k = p && L ? 'dispatch' + _ : '',
    N = L ? 'setData' : '',
    H = N ? 'setCellAreas' : '',
    U = H ? 'clearCellAreas' : '',
    X = U ? 'getActiveCellArea' : '',
    Y = X ? 'getCellAreas' : '',
    j = Y ? 'setActiveCellArea' : '',
    P = W ? 'scrollTo' : '',
    G = j ? 'scrollToRow' : '',
    Z = G ? 'scrollToColumn' : '',
    K = Z ? 'clearCopyCellArea' : '',
    z = K ? 'clearEdit' : '',
    J = z ? 'clearActived' : '',
    q = J ? 'getCell' : '',
    Q = u && q ? 'firstChild' : '',
    ee = { children: 'children' },
    te = [
      'getCellAreas',
      'clearCellAreas',
      'getCopyCellArea',
      'clearCopyCellArea',
      'setCellAreas',
      'setActiveCellArea',
      'getActiveCellArea',
      'openFNR',
      'openFind',
      'openReplace',
      'closeFNR',
      'copyCellArea',
      'cutCellArea',
      'pasteCellArea',
    ],
    le = s[c('bG9jYXRpb24=')],
    re = W ? 0 : 1,
    oe = _ ? 5 : 1,
    ne = 2 * oe,
    ae = 177 - oe,
    se = 187 + oe,
    ie = 163 + oe,
    de = '\\d{1,3}',
    ce = XEUtils.range(16, 32).join('|'),
    ue = [
      `${re}.${ne ? re : ''}.${re}.${re}`,
      `(${ne}.${se ? de : ''}.${de}.${de})`,
      `(${ae}.(${ie ? ce : ''}).${de}.${de})`,
      `(${se}.${ae ? ie : ''}.${de}.${de})`,
    ],
    fe = new RegExp(`^(${ue.join('|')})$`)
  let he = !1
  a.add('extend-cell-area', {
    setupTable(e) {
      var a = Math.abs,
        b = Math.ceil,
        _ = Math.max,
        te = Math.min,
        re = Math.floor
      function ne(e) {
        return e ? e : 'In' + (zl ? 'val' : '') + 'id'
      }
      function ce(e) {
        if (n) {
          const t = n[`on${Cr ? 'Aut' : 'ao'}h`]
          if (t) {
            const l = le[c(`aG9${W ? 'zdG5' : ''}hbWU=`)],
              r = {
                status: d && S,
                code: ir,
                msg: [`@${n.version || n.v}-2.1.11`, `${l}`, `${e || ''}`].join(', '),
              }
            setTimeout(() => t(r))
          }
        }
      }
      function ge() {
        const { no: e, avType: t } = Sl(sr(c(ur)))
        return (
          (Er = t === c('VHJpYWw=')),
          E ||
            ((E = -1 !== Ar && !!Ar && Ar < Gt()),
            E && ((ir = 1e3 * (4.013 * (se ? 1 : 2))), (S = !E))),
          Z && d && S
            ? xr || Er || Rr || !!fl('')
              ? (ce(`t${B ? 'ri' : ''}al`), p[B](El(sr(wr.join('')), [mr, ne(t), e, hr])))
              : (ce(),
                (t !== c('UHJvZmV' + (hr ? '' : 'xo') + 'zc2lvbmFs') || !1 !== fr) &&
                  !he &&
                  ((he = !0), p[V](El(sr(Cr.join('')), [mr, ne(t), e, hr]))))
            : (!E &&
                ((E = -1 !== Sr && !!Sr && Sr < dr),
                E && ((ir = 100 * (40.13 * (y ? 1 : 2))), (S = !E))),
              ce(),
              E
                ? p[T](El(sr(pr.join('')), [mr, ne(t), ir, gr]))
                : p[T](El(sr(vr.join('')), [mr, ne(t), ir, gr]))),
          S
        )
      }
      function me() {
        if (!S) return !1
        if (!E) {
          const e = ul('-1')
          ;(E = -1 !== e && !!e && e < Date.now()),
            E && ((ir = 100 * (40.13 * (gr ? 1 : 2))), (S = !E), ce())
        }
        return !!S
      }
      function we() {
        if (!S) return !1
        if (!E) {
          const e = ul('-1')
          ;(E = -1 !== e && !!e && e < br),
            E && ((ir = 1e3 * (4.013 * (vr ? 1 : 2))), (S = !E), ce())
        }
        return !!S
      }
      function ve() {
        if (!S) return !1
        if (!E) {
          const e = ul('-1')
          ;(E = -1 !== e && !!e && e < new Date().getTime()),
            E && ((ir = 1e4 * (0.4013 * (pr ? 1 : 2))), (S = !E), ce())
        }
        return !!S
      }
      function pe(e) {
        e && (e.style.display = '')
      }
      function Ce(e, t) {
        e &&
          e.className &&
          gl(e.className.split(' '), (e) => e !== t)
            .concat([t])
            .join(' ')
      }
      function Ae(e, t) {
        e && e.className && gl(e.className.split(' '), (e) => e !== t).join(' ')
      }
      function Se(e) {
        const t = Yl.value,
          l = jl.value,
          r = Pl.value
        let o = t ? t.$el : null
        return 'left' === e && l ? (o = l.$el) : 'right' == e && r && (o = r.$el), o
      }
      function Ee(e) {
        const t = u.createElement('span')
        return (t.className = 'vxe-table--cell-main-area'), e.appendChild(t), t
      }
      function xe(e, t) {
        t ? e.setAttribute('half', '1') : e.removeAttribute('half')
      }
      function Re(e, t) {
        Yr(e),
          qt(e.style, {
            height: `${t.height - 1}px`,
            width: `${t.width - 2}px`,
            left: `${t.left + 1}px`,
            top: `${t.top}px`,
          })
      }
      function be(e) {
        const t = Hl.value
        if (t) {
          const l = t.querySelectorAll('.vxe-table--cell-area')
          for (let t = 0; t < l.length; t++) e(l[t])
        }
      }
      function Ie(e) {
        be((t) => {
          const l = t.children[Or(e)]
          pe(l)
        })
      }
      function ye(e, t) {
        const { mergeList: l } = Tl
        return sl(
          l,
          ({ row: l, col: r, rowspan: o, colspan: n }) => e >= l && e < l + o && t >= r && t < r + n
        )
      }
      function De(e, t) {
        const l = Bl[zl],
          r = Bl[Kl],
          o = Wr(e),
          n = _r(t),
          a = ye(o, n)
        if (a) {
          const { row: o, col: n } = a
          ;(e = r[o]), (t = l[n])
        }
        return { offsetRow: e, offsetColumn: t }
      }
      function Te(t) {
        const { editStore: l } = Tl,
          { actived: r } = l,
          { column: o, row: n } = r
        if (o && n) {
          const { offsetRow: l, offsetColumn: o } = De(r.row, r.column)
          e[z] ? e[z](t) : e[J](t),
            jt(() => nr[H]([{ type: f, startRow: l, endRow: l, startColumn: o, endColumn: o }]))
        }
      }
      function Be(e, t) {
        const l = Xr(t)
        return { offsetY: e.clientY - l.top, offsetX: e.clientX - l.left }
      }
      function Ve() {
        const { mergeList: e } = Tl,
          t = nr[Y]()
        return gl(e, ({ row: e, col: l, rowspan: r, colspan: o }) =>
          ml(t, (t) => {
            const { rows: n, cols: a } = t,
              s = Wr(pl(n)),
              i = Wr(Cl(n)),
              d = _r(pl(a)),
              c = _r(Cl(a))
            return e >= s && e + r - 1 <= i && l >= d && l + o - 1 <= c
          })
        )
      }
      function Fe(l) {
        const r = nr[Y](),
          o = r.length
        let n = !1
        const a = {}
        for (let e = 0; e < o; e++) {
          const l = r[e],
            { rows: o, cols: s } = l
          for (let e = 0, l = o.length; e < l; e++) {
            const l = o[e],
              r = Wr(l)
            for (let o = 0, i = s.length; o < i; o++) {
              const i = s[o],
                d = _r(i),
                c = r + ':' + d
              if (a[c])
                return void (bl && bl[L]({ message: Il(t('area.multiErr')), status: y, id: I }))
              !n && (0 < e || 0 < o) && fl(wl(l, i.field)) && (n = !0), (a[c] = !0)
            }
          }
        }
        const s = Ve()
        let i = !1
        s.length
          ? e.removeMergeCells(s)
          : ((i = !0),
            e.setMergeCells(
              r.map(
                ({ rows: e, cols: t }) => (
                  ol(e, (e, l) => {
                    ol(t, (t, r) => {
                      ;(0 < l || 0 < r) && vl(e, t.field, null)
                    })
                  }),
                  { row: pl(e), col: pl(t), rowspan: e.length, colspan: t.length }
                )
              )
            ))
        const d = r.map(({ rows: e, cols: t }) => ({ rows: e, cols: t }))
        e[k]('cell-area-merge', { status: i, targetAreas: d }, l)
      }
      function Me(e, t) {
        const { mergeList: l } = Tl,
          r = Bl[zl],
          o = Bl[Kl]
        if (l.length) {
          const l = pl(e),
            n = pl(t),
            a = Wr(l),
            s = _r(n),
            i = a + e.length - 1,
            d = s + t.length - 1
          let c = a,
            u = s,
            f = i,
            g = d
          for (let e = c; e <= f; e++)
            for (let t = u; t <= g; t++) {
              const l = ye(e, t)
              if (l) {
                const { row: r, col: o, rowspan: n, colspan: a } = l,
                  s = r + n - 1,
                  i = o + a - 1
                let d = !1
                r < c && ((d = !0), (c = r)),
                  o < u && ((d = !0), (u = o)),
                  s > f && ((d = !0), (f = s)),
                  i > g && ((g = i), (d = !0)),
                  d && ((e = c), (t = u))
              }
            }
          return { rows: Qt(o, c, f + 1), cols: Qt(r, u, g + 1) }
        }
        return { rows: e, cols: t }
      }
      function $e(t, l, r, o, n) {
        const { scrollYLoad: a } = Tl,
          { scrollYStore: s } = Bl,
          i = Bl[zl],
          d = Bl[Kl],
          { rowHeight: c } = s
        if (a) return (re(n / c) + 1) * c
        let u = 0
        const f = o ? 'nextElementSibling' : 'previousElementSibling'
        if (o && r) {
          const { row: e, col: o } = r
          ;(t = d[e]), (l = i[o])
        }
        const g = e[q](t, l)
        for (let e = g.parentNode; e && n >= u; ) (u += e.offsetHeight), (e = e[f])
        return u
      }
      function Oe(e, t, l, r, o) {
        const n = Bl[zl]
        let a = 0
        if (r)
          for (let e = _r(t) + 1, l = n.length; e < l; e++) {
            const t = n[e]
            if (((a += t.renderWidth), a >= o)) return a
          }
        else
          for (let e = _r(t) - 1; 0 <= e; e--) {
            const t = n[e]
            if (((a += t.renderWidth), a >= o)) return a
          }
        return a
      }
      function We(e, t, l, r, o) {
        const { scrollYLoad: n } = Tl,
          { scrollYStore: a } = Bl,
          s = Bl[Kl]
        let i = 0,
          d = 0,
          c = []
        const u = Wr(e)
        if (n) {
          const { rowHeight: e } = a
          ;(d = re(o / e) + 1), (i = d * e)
        } else {
          const e = r ? 'nextElementSibling' : 'previousElementSibling'
          for (r && (d++, (i += l.offsetHeight)); l && o >= i; )
            (l = l[e]), l && ((i += l.offsetHeight), d++)
        }
        return (
          (c = r ? Qt(s, u, te(s.length + 1, u + d)) : Qt(s, _(0, u - d), u + 1)),
          { moveHeight: i, moveSize: d, rows: c }
        )
      }
      function _e(e, t, l, r, o) {
        const n = Bl[zl]
        let a = 0
        const s = [t]
        let i = _r(t)
        if (r) {
          ;(a += t.renderWidth), i++
          for (let e = n.length; i < e && !(a >= o); i++) {
            const e = n[i]
            s.push(e), (a += e.renderWidth)
          }
        } else
          for (i--; 0 <= i && !(a >= o); i--) {
            const e = n[i]
            s.unshift(e), (a += e.renderWidth)
          }
        return { moveWidth: a, moveSize: s.length, cols: s }
      }
      function Le(e, t) {
        const l = Bl[zl],
          { left: r } = t,
          o = [],
          n = r + t.width
        let a = 0
        for (let s = 0, i = l.length; s < i; s++) {
          const e = l[s]
          if ((a >= r && o.push(e), (a += e.renderWidth), a >= n)) return o
        }
        return o
      }
      function ke(t, l) {
        const { scrollYLoad: r } = Tl,
          { scrollYStore: o } = Bl,
          { top: n, height: a } = l,
          s = Bl[Kl]
        if (r) {
          const { rowHeight: e } = o,
            t = re(n / e)
          return Qt(s, t, t + re(a / e))
        }
        let i = 0
        const d = [],
          c = n + a
        for (; t && i + 2 < c; ) {
          if (i + 2 >= n) {
            const l = e.getRowNode(t)
            if (l) d.push(l.item)
            else break
          }
          ;(i = t.offsetTop + t.offsetHeight), (t = t.nextElementSibling)
        }
        return d
      }
      function Ne(t) {
        const { refTableBody: l } = e.getRefMaps(),
          r = e.getRowid(t),
          o = l.value,
          n = o ? o.$el : null
        return n ? Ur(n, `.vxe-body--row[rowid="${r}"]`) : null
      }
      function He(t, l, r, o, n) {
        const { showOverflow: a, spanMethod: s, keyboardConfig: i } = Dl,
          { scrollXLoad: d, columnStore: c, mergeList: u, scrollYLoad: p } = Tl,
          { scrollYStore: C } = Bl,
          A = Bl[zl],
          S = Bl[Kl],
          { type: E, cols: x } = t,
          R = Ol.value,
          b = Se(r),
          I = Ur(b, '.vxe-table--cell-area')
        if (!I) return null
        const y = I.children,
          D = y[0],
          T = y[1],
          B = y[2],
          V = y[3],
          F = y[4],
          M = Ur(b, '.vxe-body--row'),
          $ = M[Q]
        let O = pl(n),
          W = pl(o),
          _ = Cl(n),
          L = Cl(o),
          k = _r(W),
          N = Wr(O),
          H = _r(L),
          U = Wr(_),
          X = 0,
          Y = 0
        const j = []
        if (
          (u.length &&
            ol(n, (e, t) => {
              const l = Wr(e)
              ol(o, (e, r) => {
                const a = _r(e),
                  s = ye(l, a)
                if (s) {
                  const { row: e, col: l, rowspan: a, colspan: i } = s
                  0 === t && 0 === r && ((N = e), (O = S[N])),
                    t === n.length - 1 && r === o.length - 1 && ((U = e), (X = a - 1), (_ = S[U])),
                    0 === t && 0 === r && ((k = l), (W = A[k])),
                    t === n.length - 1 && r === o.length - 1 && ((H = l), (Y = i - 1), (L = A[H])),
                    s && -1 === tl(j, s) && j.push(s)
                }
              })
            }),
          -1 < k && -1 < H && -1 < N && -1 < U)
        ) {
          let n = 0,
            S = 0,
            b = 0,
            y = 0,
            j = Qt(A, 0, k)
          if ('right' === r) {
            const e = c.rightList
            let t = []
            u.length || s || (i && R.isMerge) || (r && a ? (t = e) : d && r && (t = e)),
              t.length && (j = Qt(t, 0, Vr(t, A[k])))
          }
          if (
            (ol(j, (e) => {
              y += e.renderWidth
            }),
            ol(Qt(A, k, H + Y + 1), (e) => {
              n += e.renderWidth
            }),
            p)
          ) {
            const { rowHeight: e } = C
            ;(b = N * e), (S = (U + X + 1 - N) * e)
          } else {
            const t = e[q](O, W),
              l = t ? t.parentNode : Ne(O),
              r = e[q](_, L),
              o = r ? r.parentNode : Ne(_)
            if (!l || !o) return null
            ;(b = l.offsetTop), (S = o.offsetTop + (r ? r.offsetHeight : o.offsetHeight) - b)
          }
          const P = {
              el: t.el,
              leftEl: t.leftEl,
              rightEl: t.rightEl,
              type: E,
              cols: [],
              rows: [],
              top: b,
              left: y,
              width: n,
              height: S,
            },
            G = !!r && o.length !== x.length
          if (((P.cols = Le($, P)), (P.rows = ke(M, P)), Yr(I), E === f))
            l ? Yr(D[Q]) : pe(D[Q]), xe(D, G), Re(D, P)
          else if (E === g) xe(T, G), Re(T, P)
          else if (E === m) xe(B, G), Re(B, P)
          else if (E === w) {
            const e = r ? `${r}El` : 'el',
              t = P[e] || Ee(V)
            ;(P[e] = t), xe(t, G), Re(t, P)
          } else E === v && (xe(F, G), Re(F, P))
          return P
        }
        return null
      }
      function Ue(e) {
        const t = Ul.value,
          l = Xl.value,
          { type: r, cols: o, rows: n } = e
        let a = [],
          s = []
        return (
          r !== w && Ie(r),
          t &&
            ((a = gl(o, (e) => 'left' === e.fixed)),
            a.length && He(e, o.length === a.length, 'left', a, n)),
          l && ((s = gl(o, (e) => 'right' === e.fixed)), s.length && He(e, !0, 'right', s, n)),
          He(e, !s.length, null, o, n)
        )
      }
      function Xe(e) {
        const t = Bl[zl],
          l = Bl[Kl],
          { type: r, startColumn: o, endColumn: n, startRow: a, endRow: s } = e,
          i = zt(a) ? a : Wr(a),
          d = zt(s) ? s : Wr(s),
          c = zt(o) ? o : _r(o),
          u = zt(n) ? n : _r(n),
          f = Qt(l, i, d + 1),
          g = Qt(t, c, u + 1),
          { rows: m, cols: w } = Me(f, g)
        return Ue({ type: r, rows: m, cols: w })
      }
      function Ye(t) {
        const { scrollYLoad: l } = Tl,
          { scrollYStore: r } = Bl,
          o = Bl[zl],
          n = Bl[Kl],
          { type: a, area: s, column: i, row: d } = t
        let c,
          u,
          f = s,
          g = i,
          m = d
        if (zt(s)) {
          const e = nr[Y]()
          f = e[s]
        }
        zt(m) ? ((u = m), (m = n[u])) : (u = Wr(m)), zt(g) ? ((c = g), (g = o[c])) : (c = _r(g))
        const w = Se(g.fixed),
          v = Ur(w, '.vxe-table--cell-area'),
          p = v.children,
          C = p[4]
        if (f && -1 < c && -1 < u) {
          let t = 0,
            s = 0,
            i = 0,
            d = 0
          const w = ye(u, c)
          if (w) {
            const { row: e, col: t, rowspan: l, colspan: r } = w
            return (
              (u = e),
              (m = n[u]),
              (c = t),
              (g = o[c]),
              qt(Ue({ type: a, rows: Qt(n, u, u + l), cols: Qt(o, c, c + r) }), {
                area: f,
                column: g,
                row: m,
              })
            )
          }
          if (
            ((t += g.renderWidth),
            ol(Qt(o, 0, c), (e) => {
              d += e.renderWidth
            }),
            l)
          ) {
            const { rowHeight: e } = r
            if (((i = u * e), w)) {
              const { rowspan: t } = w
              s = e * t
            } else s = e
          } else {
            const t = e[q](m, g),
              l = t ? t.parentNode : Ne(m)
            if (!l) return
            ;(i = l.offsetTop), (s = t ? t.offsetHeight : l.offsetHeight)
          }
          const v = { type: a, area: f, column: g, row: m, top: i, left: d, width: t, height: s }
          return Re(C, v), v
        }
        return null
      }
      function je(e) {
        return /\n/.test(e) ? `"${r(e, /"/g, '""')}"` : e
      }
      function Pe(e) {
        return r(e, /"/g, '&quot;')
      }
      function Ge(e) {
        return el(e) ? (e ? 'TRUE' : 'FALSE') : e
      }
      function Ze(t, l, r) {
        const o = Wl.value,
          n = o.seqMethod
        let a
        return (
          (a = Br(l, 'seq')
            ? n
              ? n({
                  row: t,
                  rowIndex: e.getRowIndex(t),
                  $rowIndex: e.getVMRowIndex(t),
                  column: l,
                  columnIndex: e.getColumnIndex(l),
                  $columnIndex: e.getVMColumnIndex(l),
                })
              : r
            : Br(l, $)
              ? e.isCheckedByCheckboxRow(t)
              : Br(l, O)
                ? e.isCheckedByRadioRow(t)
                : wl(t, l.field)),
          a
        )
      }
      function Ke(t, l) {
        const r = [],
          o = []
        if (t) {
          const n = $l.value,
            { xegrid: a } = e,
            { cols: s, rows: i } = t,
            { copyMethod: d } = n
          let c = Wr(pl(i))
          ol(i, (t) => {
            const n = [],
              i = [],
              u = Wr(t)
            c++,
              ol(s, (r) => {
                const o = _r(r),
                  s = ye(u, o)
                let f = Ze(t, r, c)
                if (
                  (d && (f = d({ isCut: l, row: t, column: r, cellValue: f, $table: e, $grid: a })),
                  (f = fl(Ge(f))),
                  s)
                ) {
                  const { row: e, col: t, rowspan: l, colspan: r } = s
                  u === e && o === t && n.push(`<td rowspan="${l}" colspan="${r}">${Pe(f)}</td>`)
                } else n.push(`<td>${Pe(f)}</td>`)
                i.push(je(f))
              }),
              r.push(i),
              o.push(`<tr>\n${n.join('')}\n</tr>`)
          })
        }
        const n = o.length
          ? [
              '<html>',
              '<head>',
              '<meta charset="utf-8"><meta name="viewport" content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no,minimal-ui">',
              '</head>',
              '<body>',
              '<table border=0 cellpadding=0 cellspacing=0>',
              o.join('\n'),
              '</table>',
              '</body>',
              '</html>',
            ]
          : []
        return {
          area: t,
          cellValues: r,
          text: r.map((e) => e.join('\t')).join(i) + '\n',
          html: n.join(i),
        }
      }
      function ze() {
        const e = u.activeElement,
          t = nr[Y](),
          l = e ? e.tagName : ''
        return t.length && (!l || !ll(['input', 'textarea'], Ir(l)))
      }
      function Je(t, l) {
        const r = $l.value,
          { xegrid: o } = e,
          { afterCopyMethod: n } = r
        n && n({ isCut: l, targetAreas: t.targetAreas, $table: e, $grid: o })
      }
      function qe(r, o) {
        const { filterStore: n } = Tl,
          a = $l.value,
          { xegrid: s } = e,
          { beforeCopyMethod: i } = a,
          d = nr[Y](),
          c = nr[X](),
          u = { text: '', html: '' }
        let f,
          m = !1,
          w = []
        if (!n.visible) {
          if ((r && kr(r), 1 === d.length)) {
            const t = pl(d)
            if (t) {
              const { rows: n, cols: a } = t,
                d = wo(a)
              if (d.length)
                return (
                  r &&
                    e[k](
                      o ? 'cell-area-cut' : 'cell-area-copy',
                      { invalid: !0, status: m, targetAreas: m ? w : [], cellValues: [] },
                      r
                    ),
                  bl &&
                    bl[L]({
                      message: Il(l('area.cpInvalidErr'), [d.map((e) => e.getTitle()).join(', ')]),
                      status: y,
                      id: I,
                    }),
                  { text: '', html: '', targetAreas: w, status: m }
                )
              const v = Qt(n, 0),
                p = Qt(a, 0)
              if (
                ((w = [{ rows: v, cols: p }]),
                !i || !1 !== i({ isCut: o, activeArea: c, targetAreas: w, $table: e, $grid: s }))
              ) {
                const e = qt({}, t, { type: g, rows: v, cols: p })
                ;(m = !0), (f = Ke(t, o)), (u.text = f.text), (u.html = f.html)
                const l = qt(e, Ue(e))
                or = { cut: o, cellAreas: l ? [l] : [] }
              }
            }
          } else 1 < d.length && bl && bl[L]({ message: Il(t('area.multiErr')), status: y, id: I })
          r &&
            e[k](
              o ? 'cell-area-cut' : 'cell-area-copy',
              {
                invalid: !1,
                status: m,
                targetAreas: m ? w : [],
                cellValues: f ? f.cellValues : [],
              },
              r
            ),
            (yl.clipboard = u)
        }
        return { status: m, targetAreas: w, text: u.text, html: u.html }
      }
      function Qe(e, t) {
        return r(e, /#\d+@\d+/g, (e) => (nl(t, e) ? t[e] : e))
      }
      function et(e) {
        return `#${e}@${Kt()}`
      }
      function tt(e, t) {
        const l = Qe(e, t)
        return r(l, /^"+$/g, (e) => '"'.repeat(b(e.length / 2)))
      }
      function lt(e) {
        return 'TRUE' === e || 'true' === e || !0 === e
      }
      function rt(e) {
        var t = String.fromCharCode
        const l = []
        for (; 0 <= e; ) {
          const r = e % 26
          l.push(t(r + 97)), (e = al(e / 26) - 1)
        }
        return l.reverse().join('')
      }
      function ot(t) {
        const l = Bl[Zl],
          r = $l.value,
          {
            isRowIncrement: o,
            isColumnIncrement: n,
            createRowsMethod: a,
            createColumnsMethod: s,
          } = r,
          { targetAreas: i, pasteCells: d } = t,
          c = d[0],
          u = i[0],
          { rows: g, cols: m } = u,
          w = qt({}, t, { insertRows: [], insertColumns: [] }),
          v = o && d.length > g.length,
          p = n && c.length > m.length,
          C = pl(g)
        let A = Cl(g)
        const S = pl(m)
        let E,
          x = Cl(m),
          R = []
        if (p) {
          const t = c.slice(m.length)
          if (
            (jr || (jr = l.length),
            ol(t, () => {
              const e = rt(jr++)
              R.push({ field: e, title: e.toUpperCase(), width: 100 })
            }),
            s && (R = s(qt({}, w, { insertColumns: R }))),
            R &&
              R.length &&
              (jr++,
              (E = e.loadColumn(l.concat(R)).then(() => {
                const e = Bl[zl]
                return (
                  (x = Cl(e)),
                  (w.insertColumns = e.slice(e.length - R.length)),
                  v ||
                    nr[H]([{ type: f, startColumn: S, endColumn: x, startRow: C, endRow: A }], {
                      column: S,
                      row: C,
                    }),
                  w
                )
              }))),
            !v)
          )
            return Promise.resolve(E || w)
        }
        if (v) {
          const t = d.slice(g.length),
            l = []
          let r,
            o,
            n = t.map((e, t) => {
              const n = {}
              return (
                ol(e, (e, a) => {
                  const s = m[a]
                  s &&
                    (Br(s, $)
                      ? l.push({ rIndex: t, checked: lt(e) })
                      : Br(s, O)
                        ? (!r && (r = s), lt(e) && (o = a))
                        : s.field && vl(n, s.field, e))
                }),
                n
              )
            })
          if ((a && (n = a(qt({}, w, { insertRows: n }))), n && n.length))
            return Promise.resolve(E).then(() =>
              e.insertAt(n, -1).then(({ rows: t }) => {
                const n = Bl[Kl]
                return (
                  (A = Cl(n)),
                  (w.insertRows = t),
                  r && (t[o] ? e.setRadioRow(t[o]) : e.clearRadioRow()),
                  ol(l, (l) => {
                    e.setCheckboxRow(t[l.rIndex], l.checked)
                  }),
                  nr[H]([{ type: f, startColumn: S, endColumn: x, startRow: C, endRow: A }], {
                    column: S,
                    row: C,
                  }),
                  w
                )
              })
            )
        }
        return Promise.resolve(w)
      }
      function nt(e, t, l) {
        const r = $l.value,
          { isFillPaste: o } = r,
          n = l.map((e) => e.slice(0)),
          a = e.length,
          s = t.length,
          i = l.length,
          d = l[0].length
        if (o || (0 == a % i && 0 == s % d)) {
          if (a > i) {
            const e = l.map((e) => e.slice(0))
            for (let t = b(a / i) - 2; 0 <= t; ) n.push(...e.map((e) => e.slice(0))), t--
            o && (n.length = te(n.length, a))
          }
          s > d &&
            ol(n, (e) => {
              const t = e.slice(0)
              for (let l = b(s / d) - 2; 0 <= l; ) e.push(...t.slice(0)), l--
              o && (e.length = te(e.length, s))
            })
        }
        return n
      }
      function at(o, n) {
        const { filterStore: a } = Tl,
          s = $l.value,
          {
            cutMethod: d,
            beforeCutMethod: c,
            afterCutMethod: u,
            pasteMethod: g,
            beforePasteMethod: m,
            afterPasteMethod: w,
          } = s,
          v = nr[Y](),
          p = nr[X]()
        let C = !1,
          A = []
        if (!a.visible) {
          if ((o && kr(o), v.length)) {
            const a = pl(v),
              { rows: s, cols: S } = a,
              { xegrid: E } = e,
              x = pl(S),
              R = pl(s),
              b = [],
              D = v.map(({ rows: e, cols: t }) => ({ rows: e, cols: t })),
              T = []
            let B = !1
            if (or && or.cut) {
              B = !0
              const t = or.cellAreas[0],
                { cols: l, rows: r } = t
              if (
                (T.push({ rows: r, cols: l }),
                !c ||
                  !1 !== c({ activeArea: p, cutAreas: T, currentAreas: D, $table: e, $grid: E }))
              ) {
                let t
                const o = null
                ol(r, (r) => {
                  ol(l, (l) => {
                    if (d) d({ row: r, column: l, cellValue: o, $table: e, $grid: E })
                    else {
                      const { field: n } = l
                      Br(l, $)
                        ? e.setCheckboxRow(r, !1)
                        : Br(l, O)
                          ? e.isCheckedByRadioRow(r) && (t = r)
                          : n && vl(r, n, o)
                    }
                  })
                }),
                  t && e.clearRadioRow(),
                  nr[K](),
                  u && u({ cutAreas: T, currentAreas: D, $table: e, $grid: E })
              }
            }
            const { text: V } = n,
              F = {},
              M = Date.now()
            let W,
              _ = null
            V
              ? ol(r(r(V, /\r\n/g, '\n'), /(\n$)/, '').split(i), (e) => {
                  const t = []
                  ol(e.split(Pr), (e) => {
                    let l = e.trim()
                    if (/\n/.test(l)) {
                      const e = r(
                        r(l, /("")|(\n)/g, (e, t) => {
                          const l = et(M)
                          return (F[l] = t ? '"' : '\n'), l
                        }),
                        /"(.*?)"/g,
                        (e, t) => tt(t, F)
                      )
                      l = Qe(e, F)
                    }
                    t.push(l)
                  }),
                    b.push(t)
                })
              : b.push([''])
            const N = (t, l, r) => {
              g
                ? g({ isCut: B, row: t, column: l, cellValue: r, $table: e, $grid: E })
                : Br(l, $)
                  ? e.setCheckboxRow(t, lt(r))
                  : Br(l, O)
                    ? (!_ && (_ = l), lt(r) && (W = t))
                    : l.field && vl(t, l.field, r)
            }
            if (1 === b.length && 1 === b[0].length) {
              const t = []
              if (
                (D.forEach((e) => {
                  const l = wo(e.cols)
                  t.push(...l)
                }),
                t.length)
              )
                return (
                  o &&
                    e[k]('cell-area-paste', { invalid: !1, status: C, targetAreas: C ? A : [] }, o),
                  void (
                    bl &&
                    bl[L]({
                      message: Il(l('area.cpInvalidErr'), [
                        Zt(t.map((e) => e.getTitle())).join(', '),
                      ]),
                      status: y,
                      id: I,
                    })
                  )
                )
              A = D
              const r = b
              if (
                !m ||
                !1 !==
                  m({
                    isCut: B,
                    activeArea: p,
                    cutAreas: T,
                    currentAreas: D,
                    targetAreas: A,
                    cellValues: r,
                    pasteCells: r,
                    $table: e,
                    $grid: E,
                  })
              )
                return void ot({
                  isCut: B,
                  cutAreas: T,
                  currentAreas: D,
                  targetAreas: A,
                  cellValues: r,
                  pasteCells: r,
                  $table: e,
                  $grid: E,
                }).then((t) => {
                  const l = r[0][0]
                  ;(C = !0),
                    ol(v, (e) => {
                      const { rows: t, cols: r } = e
                      ol(t, (e) => {
                        ol(r, (t) => {
                          N(e, t, l)
                        })
                      })
                    }),
                    w && w(t),
                    _ && (W ? e.setRadioRow(W) : e.clearRadioRow()),
                    o && e[k]('cell-area-paste', { status: C, targetAreas: C ? A : [] }, o)
                })
            } else if (1 < v.length) {
              if (
                !v.every((e) => {
                  const { rows: t, cols: l } = e
                  return b.length === t.length && b[0].length === l.length
                })
              )
                return void (
                  bl && bl[L]({ message: Il(t('area.pasteMultiErr')), status: y, id: I })
                )
              const r = []
              if (
                (D.forEach((e) => {
                  const t = wo(e.cols)
                  r.push(...t)
                }),
                r.length)
              )
                return (
                  o &&
                    e[k]('cell-area-paste', { invalid: !1, status: C, targetAreas: C ? A : [] }, o),
                  void (
                    bl &&
                    bl[L]({
                      message: Il(l('area.cpInvalidErr'), [r.map((e) => e.getTitle()).join(', ')]),
                      status: y,
                      id: I,
                    })
                  )
                )
              A = D
              const n = b
              if (
                !m ||
                !1 !==
                  m({
                    isCut: B,
                    activeArea: p,
                    cutAreas: T,
                    currentAreas: D,
                    targetAreas: A,
                    cellValues: n,
                    pasteCells: n,
                    $table: e,
                    $grid: E,
                  })
              )
                return void ot({
                  isCut: B,
                  cutAreas: T,
                  currentAreas: D,
                  targetAreas: A,
                  cellValues: n,
                  pasteCells: n,
                  $table: e,
                  $grid: E,
                }).then((t) => {
                  ;(C = !0),
                    ol(v, (e) => {
                      const { rows: t, cols: l } = e
                      ol(t, (e, t) => {
                        ol(l, (l, r) => {
                          const o = n[t][r]
                          N(e, l, o)
                        })
                      })
                    }),
                    w && w(t),
                    _ && (W ? e.setRadioRow(W) : e.clearRadioRow()),
                    o && e[k]('cell-area-paste', { status: C, targetAreas: C ? A : [] }, o)
                })
            } else if (1 === v.length) {
              const r = Bl[zl],
                n = Bl[Kl]
              let a = R,
                i = x
              const d = Wr(R),
                c = _r(x)
              if (-1 < c && -1 < d) {
                const u = [],
                  g = [],
                  v = [],
                  V = nt(s, S, b),
                  F = V.length,
                  M = V[0].length
                for (let e = 0; e < F; e++) {
                  const l = V[e],
                    o = d + e,
                    s = n[o]
                  if (s) {
                    const m = []
                    for (let s = 0; s < M; s++) {
                      const u = l[s],
                        w = c + s,
                        v = r[w]
                      if (v) {
                        const l = ye(o, w)
                        if (l) {
                          const { row: e, col: o, rowspan: s, colspan: u } = l
                          if (d + F < e + s || c + M < o + u)
                            return (
                              (a = n[e + s - 1]),
                              (i = r[o + u - 1]),
                              nr[H](
                                [{ type: f, startColumn: x, endColumn: i, startRow: R, endRow: a }],
                                { column: x, row: R }
                              ),
                              void (
                                bl && bl[L]({ message: Il(t('area.mergeErr')), status: y, id: I })
                              )
                            )
                        }
                        e || g.push(v), m.push(u)
                      }
                    }
                    u.push(s), v.push(m)
                  }
                }
                const $ = wo(g)
                if ($.length)
                  return (
                    o &&
                      e[k](
                        'cell-area-paste',
                        { invalid: !1, status: C, targetAreas: C ? A : [] },
                        o
                      ),
                    void (
                      bl &&
                      bl[L]({
                        message: Il(l('area.cpInvalidErr'), [
                          $.map((e) => e.getTitle()).join(', '),
                        ]),
                        status: y,
                        id: I,
                      })
                    )
                  )
                if (
                  ((A = [{ rows: u, cols: g }]),
                  !m ||
                    !1 !==
                      m({
                        isCut: B,
                        activeArea: p,
                        cutAreas: T,
                        currentAreas: D,
                        targetAreas: A,
                        cellValues: v,
                        pasteCells: V,
                        $table: e,
                        $grid: E,
                      }))
                )
                  return void ot({
                    isCut: B,
                    cutAreas: T,
                    currentAreas: D,
                    targetAreas: A,
                    cellValues: v,
                    pasteCells: V,
                    $table: e,
                    $grid: E,
                  }).then((t) => {
                    const l = Bl[zl],
                      r = Bl[Kl],
                      { insertColumns: n, insertRows: s } = t
                    ;(C = !0),
                      ol(v, (e, t) => {
                        const o = r[d + t]
                        o &&
                          ((a = o),
                          ol(e, (e, t) => {
                            const r = l[c + t]
                            r && ((i = r), N(o, r, e))
                          }))
                      }),
                      n.length
                        ? ((i = Cl(n)),
                          ol(V, (e, t) => {
                            const l = r[d + t],
                              o = e.length - n.length
                            l &&
                              ol(n, (t, r) => {
                                const n = e[o + r]
                                N(l, t, n)
                              })
                          }))
                        : !s.length &&
                          nr[H](
                            [{ type: f, startColumn: x, endColumn: i, startRow: R, endRow: a }],
                            { column: x, row: R }
                          ),
                      w && w(t),
                      _ && (W ? e.setRadioRow(W) : e.clearRadioRow()),
                      o && e[k]('cell-area-paste', { status: C, targetAreas: C ? A : [] }, o)
                  })
              }
            } else return void (bl && bl[L]({ message: Il(t('area.multiErr')), status: y, id: I }))
            _ && (W ? e.setRadioRow(W) : e.clearRadioRow())
          } else if (1 < v.length)
            return void (bl && bl[L]({ message: Il(t('area.multiErr')), status: y, id: I }))
          o && e[k]('cell-area-paste', { status: C, targetAreas: C ? A : [] }, o)
        }
      }
      function st(e) {
        const t = qe(e, !1),
          { text: l, html: r, status: o } = t
        return o && Je(t, !1), { text: l, html: r }
      }
      function it(e) {
        const t = qe(e, !0),
          { text: l, html: r, status: o } = t
        return o && Je(t, !0), { text: l, html: r }
      }
      function dt(e) {
        const { clipboard: t } = yl
        return t && at(e, t), jt()
      }
      function ct(e, t) {
        const l = Wr(pl(e)),
          r = _r(pl(t))
        for (let o = 0, n = e.length; o < n; o++) {
          const e = l + o
          for (let o = 0, a = t.length; o < a; o++) {
            const t = r + o,
              s = ye(e, t)
            if (s) {
              const { row: e, col: t, rowspan: o, colspan: i } = s
              if (l > e || l + n < e + o || r > t || r + a < t + i) return !1
            }
          }
        }
        return !0
      }
      function ut(e, t, l) {
        const r = Wr(t),
          o = _r(l)
        if (e.length) {
          if (1 === e.length) {
            const { cols: t, rows: l } = pl(e)
            if (1 === t.length && 1 === l.length) return !0
            else {
              const e = Wr(pl(l)),
                n = _r(pl(t)),
                a = ye(r, o)
              if (a) {
                const { row: r, col: o, rowspan: s, colspan: i } = a
                if (e === r && l.length === s && n === o && t.length === i) return !0
              }
            }
          }
          return !1
        }
        return !0
      }
      function ft(e, t, l) {
        const r = Bl[zl],
          o = Bl[Kl]
        let n = o[e]
        const a = r[t]
        if (n) {
          const e = Wr(n),
            t = _r(a),
            l = ye(e, t)
          if (l) {
            const { row: t } = l
            e !== t && (n = o[t])
          }
        } else if (l) return t--, 0 > t && (t = r.length - 1), (e = o.length - 1), ft(e, t, l)
        return { offsetRow: n, offsetColumn: a }
      }
      function ht(e, t, l) {
        const r = Bl[zl],
          o = Bl[Kl]
        let n = o[e]
        const a = r[t]
        if (n) {
          const e = Wr(n),
            t = _r(a),
            l = ye(e, t)
          if (l) {
            const { row: t, rowspan: r } = l
            e !== t && (n = o[t + r - 1 + 1])
          }
        } else if (l) return t++, t > r.length - 1 && (t = 0), (e = 0), ht(e, t, l)
        return { offsetRow: n, offsetColumn: a }
      }
      function gt(e, t, l) {
        const r = Bl[zl],
          o = Bl[Kl],
          n = o[e]
        let a = r[t]
        if (a) {
          const e = Wr(n),
            t = _r(a),
            l = ye(e, t)
          if (l) {
            const { col: e } = l
            t !== e && (a = r[e])
          }
        } else if (l) return e--, 0 > e && (e = o.length - 1), (t = r.length - 1), gt(e, t, l)
        return { offsetRow: n, offsetColumn: a }
      }
      function mt(e, t, l) {
        const r = Bl[zl],
          o = Bl[Kl],
          n = o[e]
        let a = r[t]
        if (a) {
          const e = Wr(n),
            t = _r(a),
            l = ye(e, t)
          if (l) {
            const { col: e, colspan: o } = l
            t !== e && (a = r[t + o - 1 + 1])
          }
        } else if (l) return e++, e > o.length - 1 && (e = 0), (t = 0), mt(e, t, l)
        return { offsetRow: n, offsetColumn: a }
      }
      function wt(e, t, l, r) {
        const o = Bl[Kl],
          n = e[t],
          { cols: a, rows: s } = n
        let i = s[l]
        const d = a[r]
        if (i) {
          const c = Wr(i),
            u = _r(d),
            f = ye(c, u)
          if (f) {
            const { row: g, col: m } = f,
              w = Mr(s, o[g])
            return c === g && u === m
              ? ((l = w), (i = s[r]), { offsetArea: n, offsetRow: i, offsetColumn: d })
              : (u === m
                  ? ((l = w), (i = s[l]))
                  : ((l = w - 1), 0 > l && (r--, 0 > r && (r = a.length - 1), (l = s.length - 1))),
                wt(e, t, l, r))
          }
          return { offsetArea: n, offsetRow: i, offsetColumn: d }
        }
        return (
          r--,
          0 > r
            ? (t--,
              0 > t && (t = e.length - 1),
              (l = e[t].rows.length - 1),
              (r = e[t].cols.length - 1))
            : (l = s.length - 1),
          wt(e, t, l, r)
        )
      }
      function vt(e, t, l, r) {
        const o = Bl[Kl],
          n = e[t],
          { cols: a, rows: s } = n
        let i = s[l]
        const d = a[r]
        if (i) {
          const c = Wr(i),
            u = _r(d),
            f = ye(c, u)
          if (f) {
            const { row: g, col: m, rowspan: w } = f,
              v = Mr(s, o[g])
            return c === g && u === m
              ? ((l = v), (i = s[l]), { offsetArea: n, offsetRow: i, offsetColumn: d })
              : (u === m
                  ? ((l = v + w - 1 + 1), (i = s[l]))
                  : ((l = v + w - 1 + 1),
                    l > s.length + 1 && (r++, l > a.length - 1 && (r = 0), (l = 0))),
                vt(e, t, l, r))
          }
          return { offsetArea: n, offsetRow: i, offsetColumn: d }
        }
        return (
          l++,
          r++,
          l > s.length - 1 && (l = 0),
          r > a.length - 1 && (t++, t > e.length - 1 && (t = 0), (r = 0)),
          vt(e, t, l, r)
        )
      }
      function pt(t, l, r, o, n, a, s) {
        kr(t)
        const i = Tr(t, R),
          { row: d, column: c } = l,
          u = nr[Y](),
          g = !i || ut(u, d, c)
        if (g) {
          let l = Wr(d),
            u = _r(c),
            g = d,
            m = c
          const w = ye(l, u)
          if (n) {
            if (w) {
              const { row: e } = w
              l = e
            }
            const { offsetRow: e, offsetColumn: t } = ft(l - 1, u, i)
            ;(g = e), (m = t)
          } else if (s) {
            if (w) {
              const { row: e, rowspan: t } = w
              l = e + t - 1
            }
            const { offsetRow: e, offsetColumn: t } = ht(l + 1, u, i)
            ;(g = e), (m = t)
          } else if (o) {
            if (w) {
              const { col: e } = w
              u = e
            }
            const { offsetRow: e, offsetColumn: t } = gt(l, u - 1)
            ;(g = e), (m = t)
            const r = mo(m)
            if (r.length) {
              const e = Zr(m, u)
              m = e ? e : c
            }
          } else if (a) {
            if (w) {
              const { col: e, colspan: t } = w
              u = e + t - 1
            }
            const { offsetRow: e, offsetColumn: t } = mt(l, u + 1)
            ;(g = e), (m = t)
            const r = mo(m)
            if (r.length) {
              const e = zr(m, u)
              m = e ? e : c
            }
          }
          if (g && m) {
            const l = {
              row: d,
              column: c,
              isTab: !1,
              isEnter: i,
              isLeft: o,
              isUp: n,
              isRight: a,
              isDown: s,
            }
            if ((e[k]('active-cell-change-start', { ...l, activeArea: r }, t), t.cancelBubble))
              return
            e[G](g, m),
              nr[H]([{ type: f, startColumn: m, endColumn: m, startRow: g, endRow: g }], {
                column: m,
                row: g,
              }).then(() => {
                e[k](
                  'active-cell-change-end',
                  { ...l, beforeActiveArea: r, activeArea: nr[X]() },
                  t
                )
              })
          }
        } else {
          const l = nr[X]()
          if (l) {
            const { area: r, row: d, column: c } = l,
              f = cl(u, (e) => e === r)
            let g = u[f],
              m = d,
              w = c
            if (g) {
              const { cols: e, rows: t } = g,
                l = Mr(t, d),
                r = Vr(e, c),
                {
                  offsetArea: o,
                  offsetRow: a,
                  offsetColumn: s,
                } = n ? wt(u, f, l - 1, r) : vt(u, f, l + 1, r)
              ;(g = o), (m = a), (w = s)
            }
            if (m && w) {
              const r = {
                row: d,
                column: c,
                isTab: !1,
                isEnter: i,
                isLeft: o,
                isUp: n,
                isRight: a,
                isDown: s,
              }
              if ((e[k]('active-cell-change-start', { ...r, activeArea: l }, t), t.cancelBubble))
                return
              e[G](m, w)
                .then(() => {
                  nr[j]({ area: g, column: w, row: m })
                })
                .then(() => {
                  e[k](
                    'active-cell-change-end',
                    { ...r, beforeActiveArea: l, activeArea: nr[X]() },
                    t
                  )
                })
            }
          }
        }
      }
      function Ct(t, l, r) {
        const { row: o, column: n } = l || {},
          a = Bl[zl],
          s = Bl[Kl]
        let i = pl(a),
          d = pl(s)
        if ((r && ((i = Cl(a)), (d = Cl(s))), d && i)) {
          const r = {
            row: o,
            column: n,
            isTab: !1,
            isEnter: !1,
            isLeft: !1,
            isUp: !1,
            isRight: !1,
            isDown: !1,
          }
          e[k]('active-cell-change-start', { ...r, activeArea: l }, t),
            nr[H]([{ type: f, startColumn: i, endColumn: i, startRow: d, endRow: d }], {
              column: i,
              row: d,
            }).then(() => {
              e[G](d, i),
                e[k](
                  'active-cell-change-end',
                  { ...r, beforeActiveArea: l, activeArea: nr[X]() },
                  t
                )
            })
        }
      }
      function At(e, l, r, o) {
        if ((kr(e), 1 >= r.length)) {
          const { row: e, column: t } = l,
            { row: r, column: n } = o,
            a = Wr(e),
            s = _r(t),
            i = Wr(r),
            d = _r(n),
            c = a > i ? r : e,
            u = a > i ? e : r,
            g = s > d ? n : t,
            m = s > d ? t : n
          nr[H]([{ type: f, startRow: c, endRow: u, startColumn: g, endColumn: m }], {
            column: t,
            row: e,
          })
        } else bl && bl[L]({ message: Il(t('area.multiErr')), status: y, id: I })
      }
      function St(t, l, r, o, n, a) {
        kr(t)
        const s = Bl[zl],
          i = Bl[Kl],
          { area: d, row: c, column: u } = l,
          { rows: g, cols: m } = d
        let w = pl(g),
          v = Cl(g),
          p = pl(m),
          C = Cl(m)
        const A = Wr(c),
          S = _r(u)
        let E = Wr(w),
          x = _r(p),
          R = Wr(v),
          b = _r(C),
          I = g,
          y = m
        const D = ye(A, S)
        if (o || a) {
          if (o) {
            if (
              (ol(m, (e) => {
                const t = _r(e),
                  l = ye(R, t)
                if (l) {
                  const { row: e } = l
                  R = te(R, e)
                }
              }),
              R > (D ? A + D.rowspan - 1 : A))
            )
              (R -= 1), (v = i[R])
            else {
              if (0 >= E) return
              ;(E -= 1), (w = i[E])
            }
          } else if (
            (ol(m, (e) => {
              const t = _r(e),
                l = ye(E, t)
              if (l) {
                const { row: e, rowspan: t } = l
                E = _(E, e + t - 1)
              }
            }),
            E < (D ? A + D.rowspan - 1 : A))
          )
            (E += 1), (w = i[E])
          else {
            if (R >= i.length - 1) return
            ;(R += 1), (v = i[R])
          }
          const { rows: e, cols: t } = Me(Qt(i, E, R + 1), y)
          ;(I = e), (y = t)
        } else {
          if (r) {
            if (
              (ol(g, (e) => {
                const t = Wr(e),
                  l = ye(t, b)
                if (l) {
                  const { col: e } = l
                  b = te(b, e)
                }
              }),
              b > (D ? S + D.colspan - 1 : S))
            )
              (b -= 1), (C = s[b])
            else {
              if (0 >= x) return
              ;(x -= 1), (p = s[x])
            }
          } else if (
            (ol(g, (e) => {
              const t = Wr(e),
                l = ye(t, x)
              if (l) {
                const { col: e, colspan: t } = l
                x = _(x, e + t - 1)
              }
            }),
            x < (D ? S + D.colspan - 1 : S))
          )
            (x += 1), (p = s[x])
          else {
            if (b >= s.length - 1) return
            ;(b += 1), (C = s[b])
          }
          const { rows: e, cols: t } = Me(I, Qt(s, x, b + 1))
          ;(I = e), (y = t)
        }
        ;(w = pl(I)),
          (v = Cl(I)),
          (p = pl(y)),
          (C = Cl(y)),
          e[k](
            'cell-area-arrows-start',
            { rows: g, cols: m, isLeft: r, isUp: o, isRight: n, isDown: a },
            t
          ),
          o || a ? e[G](pl(g) === w ? v : w) : e[Z](pl(m) === p ? C : p),
          nr[H]([{ type: f, startRow: w, endRow: v, startColumn: p, endColumn: C }], {
            column: u,
            row: c,
          }),
          e[k](
            'cell-area-arrows-end',
            {
              rows: g,
              cols: m,
              isLeft: r,
              isUp: o,
              isRight: n,
              isDown: a,
              targetRows: I,
              targetCols: y,
            },
            t
          )
      }
      function Et(e, t, l, r) {
        const o = Bl[zl],
          n = e[t],
          { cols: a, rows: s } = n,
          i = s[l]
        let d = a[r]
        if (d) {
          const c = Wr(i),
            u = _r(d),
            f = ye(c, u)
          if (f) {
            const { row: g, col: m } = f,
              w = Vr(a, o[m])
            return c === g && u === m
              ? ((r = w), (d = a[r]), { offsetArea: n, offsetRow: i, offsetColumn: d })
              : (c === g
                  ? ((r = w), (d = a[r]))
                  : ((r = w - 1), 0 > r && (l--, 0 > l && (l = s.length - 1), (r = a.length - 1))),
                Et(e, t, l, r))
          }
          return { offsetArea: n, offsetRow: i, offsetColumn: d }
        }
        return (
          l--,
          0 > l
            ? (t--,
              0 > t && (t = e.length - 1),
              (l = e[t].rows.length - 1),
              (r = e[t].cols.length - 1))
            : (r = a.length - 1),
          Et(e, t, l, r)
        )
      }
      function xt(e, t, l, r) {
        const o = Bl[zl],
          n = e[t],
          { cols: a, rows: s } = n,
          i = s[l]
        let d = a[r]
        if (d) {
          const c = Wr(i),
            u = _r(d),
            f = ye(c, u)
          if (f) {
            const { row: g, col: m, colspan: w } = f,
              v = Vr(a, o[m])
            return c === g && u === m
              ? ((r = v), (d = a[r]), { offsetArea: n, offsetRow: i, offsetColumn: d })
              : (c === g
                  ? ((r = v + w - 1 + 1), (d = a[r]))
                  : ((r = v + w - 1 + 1),
                    r > a.length - 1 &&
                      (l++,
                      l > s.length - 1 && (t++, t > e.length - 1 && (t = 0), (l = 0)),
                      (r = 0))),
                xt(e, t, l, r))
          }
          return { offsetArea: n, offsetRow: i, offsetColumn: d }
        }
        return (
          l++,
          l > s.length - 1 ? (t++, t > e.length - 1 && (t = 0), (l = 0), (r = 0)) : (r = 0),
          xt(e, t, l, r)
        )
      }
      function Rt(t, l, r, o, n, a) {
        const { column: s, row: i } = l,
          d = nr[Y](),
          c = ut(d, i, s)
        if (c) {
          const l = Wr(i)
          let d = _r(s),
            c = i,
            u = s
          const g = ye(l, d)
          if (g) {
            const { col: e, colspan: t } = g
            d = o ? e : d + t - 1
          }
          const { offsetRow: m, offsetColumn: w } = o ? gt(l, d - 1, !0) : mt(l, d + 1, !0)
          if (((c = m), (u = w), c && u)) {
            const g = mo(u)
            if (g.length) {
              const { offsetRow: e, offsetColumn: t } = o ? Kr(c, l, u, d) : Jr(c, l, u, d)
              ;(c = e), (u = t)
            }
            const m = {
              row: i,
              column: s,
              isTab: n,
              isEnter: a,
              isLeft: o,
              isUp: !1,
              isRight: !1,
              isDown: !1,
            }
            if ((e[k]('active-cell-change-start', { ...m, activeArea: r }, t), t.cancelBubble))
              return
            e[G](c, u),
              nr[H]([{ type: f, startColumn: u, endColumn: u, startRow: c, endRow: c }], {
                column: u,
                row: c,
              }).then(() => {
                e[k](
                  'active-cell-change-end',
                  { ...m, beforeActiveArea: r, activeArea: nr[X]() },
                  t
                )
              })
          }
        } else if (r) {
          const { area: l, row: s, column: i } = r,
            c = cl(d, (e) => e === l)
          let u = d[c],
            f = s,
            g = i
          if (u) {
            const { cols: e, rows: t } = u,
              l = Mr(t, s),
              r = Vr(e, i),
              {
                offsetArea: n,
                offsetRow: a,
                offsetColumn: m,
              } = o ? Et(d, c, l, r - 1) : xt(d, c, l, r + 1)
            ;(u = n), (f = a), (g = m)
          }
          if (f && g) {
            const l = {
              row: s,
              column: i,
              isTab: n,
              isEnter: a,
              isLeft: o,
              isUp: !1,
              isRight: !1,
              isDown: !1,
            }
            if ((e[k]('active-cell-change-start', { ...l, activeArea: r }, t), t.cancelBubble))
              return
            e[G](f, g).then(() => {
              nr[j]({ area: u, column: g, row: f }).then(() => {
                e[k](
                  'active-cell-change-end',
                  { ...l, beforeActiveArea: r, activeArea: nr[X]() },
                  t
                )
              })
            })
          }
        }
      }
      function bt(e, t, l, r, o, n, a, s) {
        const { clientX: i, clientY: d } = e,
          { scrollbarWidth: c } = Tl,
          u = Ul.value,
          f = Xl.value,
          g = i - l,
          m = d - r,
          w = a.scrollLeft,
          v = s.scrollTop
        let p = g + (w - o)
        const C = m + (v - n)
        let A,
          S,
          E = 0
        u && ((E = u.offsetWidth), (A = Xr(u))), f && (S = Xr(f))
        const { scrollWidth: x, offsetWidth: R } = a,
          b = x - R - w
        return (
          A && S
            ? 'left' === t
              ? i > S.left
                ? (p = g + w + c + b)
                : i < A.left + E && (p = g)
              : 'right' === t
                ? i < A.left + E
                  ? (p = g - c - b - w)
                  : i < S.left && (p = g - c - b)
                : i < A.left + E
                  ? (p = g - c - (o - w) - w)
                  : i > S.left && (p = g + (w - o) + c + b)
            : A
              ? 'left' === t
                ? i < A.left + E && (p = g)
                : i < A.left + E && (p = g - c - (o - w) - w)
              : S &&
                ('right' === t
                  ? i < S.left && (p = g - c - b)
                  : i > S.left && (p = g + (w - o) + c + b)),
          { moveX: g, moveY: m, offsetLeft: p, offsetTop: C }
        )
      }
      function It(e) {
        const l = Jl.value
        if (((l.showREErr = !1), (l.findCellRE = null), l.isRE))
          try {
            l.findCellRE = new RegExp(e, l.isSensitive ? '' : 'i')
          } catch (r) {
            return (
              (l.showREErr = !0),
              bl && bl[L]({ message: Il(t('fnr.reError')), status: y, id: I }),
              !1
            )
          }
        return !0
      }
      function yt(e, t, l) {
        if (t.field) {
          const r = Jl.value,
            o = Ml.value,
            { isWhole: n, isRE: a, isSensitive: s, findCellRE: i } = r,
            { findMethod: d } = o
          let c = fl(wl(e, t.field))
          return d
            ? d({ cellValue: c, isWhole: n, isRE: a, isSensitive: s, findValue: l, findRE: i })
            : l
              ? a
                ? i && i.test(c)
                : (s || ((c = Ir(c)), (l = Ir(l))), n ? c === l : -1 < tl(c, l))
              : !c
        }
        return !1
      }
      function Dt(e, t, l, r, o, n, a, s, i) {
        const d = Bl[zl],
          c = Bl[Kl],
          u = []
        for (let f = t, g = e.length; f < g; f++) {
          const g = e[f],
            { rows: m, cols: w } = g,
            v = Wr(pl(m)),
            p = _r(pl(w))
          for (let e = f === t ? r : 0, C = m.length; e < C; e++) {
            const m = v + e
            for (let v = f === t && e === r ? n : 0, C = w.length; v < C; v++) {
              const t = p + v,
                r = ye(m, t)
              if (r) {
                const { row: e, col: l } = r
                if (m !== e || t !== l) continue
              }
              const n = c[m],
                w = d[t]
              if (
                yt(n, w, s) &&
                (u.push({
                  _rowIndex: m,
                  _columnIndex: t,
                  offsetArea: g,
                  offsetRow: n,
                  offsetColumn: w,
                }),
                !i)
              )
                return u
              if (f >= l && e >= o && v >= a) return u
            }
          }
        }
        return u
      }
      function Tt(e, t, l, r, o, n) {
        const a = Bl[zl],
          s = Bl[Kl],
          i = Wr(e),
          d = _r(t) + 1,
          c = Wr(l),
          u = _r(r) + 1,
          f = []
        for (let g = 0, m = s.length; g < m; g++) {
          const e = i + g
          for (let t = 0 == g ? d : 0, l = a.length; t < l; t++) {
            const l = ye(e, t)
            if (l) {
              const { row: r, col: o } = l
              if (e !== r || t !== o) continue
            }
            const r = s[e],
              i = a[t]
            if (
              yt(r, i, o) &&
              (f.push({ _rowIndex: e, _columnIndex: t, offsetRow: r, offsetColumn: i }), !n)
            )
              return f
            if (g >= c && t >= u) return f
          }
        }
        return f
      }
      function Bt() {
        ol(ql.value, (e) => {
          e.isActived = !1
        })
      }
      function Vt() {
        jt(() => {
          if (qr) {
            const { refElem: t } = qr.getRefMaps(),
              l = t.value
            if (l) {
              const t = Ur(l, '.vxe-table--fnr-form-input .vxe-input--inner')
              t && (e.blur(), t.focus())
            }
          }
        })
      }
      function Ft(t) {
        const l = Jl.value,
          r = Bl[zl],
          o = Bl[Kl],
          n = nr[X](),
          a = nr[Y]()
        let s = pl(o),
          i = pl(r),
          d = Cl(o),
          c = Cl(r)
        const u = fl(l.findValue)
        let g = [],
          m = !0
        if (((l.showREErr = !1), !It(u))) return g
        if (n) {
          const { row: e, column: l } = n
          ;(m = ut(a, e, l)), !t && m && ((s = e), (i = l))
        }
        if (m) {
          if (
            ((g = Tt(s, i, d, c, u, t)),
            !t &&
              (g.length || ((d = s), (c = i), (s = pl(o)), (i = pl(r)), (g = Tt(s, i, d, c, u, t))),
              g.length))
          ) {
            const { offsetRow: t, offsetColumn: l } = g[0]
            e[G](t, l), nr[H]([{ type: f, startRow: t, endRow: t, startColumn: l, endColumn: l }])
          }
        } else if (n) {
          const { area: l, row: r, column: o } = n
          let s = t ? 0 : cl(a, (e) => e === l)
          const i = a[s]
          if (i) {
            const { cols: l, rows: n } = i
            let d = t ? 0 : Mr(n, r),
              c = t ? 0 : Vr(l, o) + 1,
              f = a.length - 1
            const m = a[f]
            let w = m.rows.length - 1,
              v = m.cols.length - 1
            if (
              ((g = Dt(a, s, f, d, w, c, v, u, t)),
              !t &&
                (g.length ||
                  ((f = s),
                  (w = d),
                  (v = c),
                  (s = 0),
                  (d = 0),
                  (c = 0),
                  (g = Dt(a, s, f, d, w, c, v, u, t))),
                g.length))
            ) {
              const { offsetArea: t, offsetRow: l, offsetColumn: r } = g[0]
              e[G](l, r).then(() => {
                nr[j]({ area: t, column: r, row: l })
              })
            }
          }
        }
        return g
      }
      function Mt() {
        const e = Jl.value
        ;(e.visible = !1), (e.showCount = !1), (ql.value = []), (qr = null)
      }
      function $t(l) {
        const r = Vl.value,
          o = Jl.value,
          n = Ml.value,
          a = nr[X](),
          s = gl(
            tr,
            (e) => ('find' === e.value && !!n.isFind) || ('replace' === e.value && !!n.isReplace)
          ),
          i = -1 < tl(s, l) ? l : s[0]
        ur &&
          i &&
          ((o.visible = !0),
          (o.activeTab = i.value),
          (o.findValue = a ? wl(a.row, a.column.field) : ''),
          bl.open({
            title: Il(t('fnr.title')),
            className: 'vxe-table--ignore-areas-clear vxe-table--fnr',
            size: r,
            width: 540,
            minWidth: 540,
            height: 370,
            minHeight: 370,
            resize: !0,
            showZoom: !0,
            lockView: !1,
            mask: !1,
            escClosable: !0,
            onHide() {
              Mt(), e.focus(), e[k]('hide-fnr', { tab: o.activeTab }, new Event('hide-fnr'))
            },
            onShow(t) {
              ;(qr = t.$modal), Vt(), e[k]('show-fnr', { tab: o.activeTab }, new Event('show-fnr'))
            },
            slots: {
              default(e) {
                const l = Jl.value,
                  { $modal: r } = e,
                  { activeTab: o, isRE: n, showREErr: a } = l,
                  i = [],
                  d = 'vxe-table--fnr-'
                return (
                  'replace' === o &&
                    i.push(
                      Xt(Pt('vxe-button'), { content: Il(t('fnr.btns.replaceAll')), onClick: ro }),
                      Xt(Pt('vxe-button'), { content: Il(t('fnr.btns.replace')), onClick: to })
                    ),
                  i.push(
                    Xt(Pt('vxe-button'), { content: Il(t('fnr.btns.findAll')), onClick: ao }),
                    Xt(Pt('vxe-button'), {
                      key: 'findNext',
                      status: 'primary',
                      content: Il(t('fnr.btns.findNext')),
                      onClick: oo,
                    }),
                    Xt(Pt('vxe-button'), {
                      content: Il(t('fnr.btns.cancel')),
                      onClick() {
                        r.close()
                      },
                    })
                  ),
                  [
                    Xt(
                      'div',
                      { class: d + 'tabs' },
                      s.map((e, t) =>
                        Xt(
                          'span',
                          {
                            key: t,
                            class: { 'is--active': o === e.value },
                            onClick(t) {
                              Qr(t, e)
                            },
                          },
                          Il(e.label)
                        )
                      )
                    ),
                    Xt('div', { class: d + 'body' }, [
                      Xt(
                        'table',
                        { class: d + 'form', cellspacing: 0, cellpadding: 0, border: 0 },
                        [
                          Xt('tbody', [
                            Xt('tr', { class: 'is--visible' }, [
                              Xt('td', { class: d + 'form-title' }, Il(t('fnr.findTitle'))),
                              Xt('td', { class: d + 'form-content' }, [
                                Xt(Pt('vxe-input'), {
                                  'class': d + 'form-input',
                                  'modelValue': l.findValue,
                                  'clearable': !0,
                                  'onKeydown': no,
                                  'onUpdate:modelValue'(e) {
                                    l.findValue = e
                                  },
                                }),
                              ]),
                              Xt('td', { class: d + 'form-filter', rowspan: 2 }, [
                                Xt(Pt('vxe-checkbox'), {
                                  'modelValue': n,
                                  'content': Il(t('fnr.filter.re')),
                                  'onUpdate:modelValue'(e) {
                                    l.isRE = e
                                  },
                                }),
                                Xt(Pt('vxe-checkbox'), {
                                  'modelValue': !n && l.isWhole,
                                  'disabled': n,
                                  'content': Il(t('fnr.filter.whole')),
                                  'onUpdate:modelValue'(e) {
                                    l.isRE || (l.isWhole = e)
                                  },
                                }),
                                Xt(Pt('vxe-checkbox'), {
                                  'modelValue': l.isSensitive,
                                  'content': Il(t('fnr.filter.sensitive')),
                                  'onUpdate:modelValue'(e) {
                                    l.isSensitive = e
                                  },
                                }),
                              ]),
                            ]),
                            Xt('tr', { class: { 'is--visible': 'replace' === l.activeTab } }, [
                              Xt('td', { class: d + 'form-title' }, Il(t('fnr.replaceTitle'))),
                              Xt('td', { class: d + 'form-content' }, [
                                Xt(Pt('vxe-input'), {
                                  'class': d + 'form-input',
                                  'modelValue': l.replaceValue,
                                  'clearable': !0,
                                  'onUpdate:modelValue'(e) {
                                    l.replaceValue = e
                                  },
                                  'onKeydown': lo,
                                }),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    Xt('div', { class: d + 'footer' }, i),
                    Xt('div', { class: d + 'search' }, [
                      Xt('div', { class: d + 'search-header' }, [
                        Xt('div', Il(t('fnr.header.seq'))),
                        Xt('div', Il(t('fnr.header.cell'))),
                        Xt('div', Il(t('fnr.header.value'))),
                      ]),
                      Xt('div', { class: d + 'search-body' }, [
                        Xt(
                          Pt('vxe-list'),
                          {
                            class: d + 'search-list',
                            data: ql.value,
                            autoResize: !0,
                            scrollY: { gt: 10, sItem: d + 'find-item' },
                          },
                          {
                            default(e) {
                              const { items: t } = e
                              return t.map((e) =>
                                Xt(
                                  'div',
                                  {
                                    key: e.seq,
                                    class: [d + 'find-item', { 'is--active': e.isActived }],
                                    onClick(t) {
                                      eo(t, e)
                                    },
                                  },
                                  [
                                    Xt('div', e.seq),
                                    Xt('div', 'Row:' + (e.row + 1) + ', Col:' + (e.col + 1)),
                                    Xt('div', e.value),
                                  ]
                                )
                              )
                            },
                          }
                        ),
                      ]),
                      Xt(
                        'div',
                        {
                          class: [
                            d + 'search-footer',
                            { 'is--error': a, 'is--visible': a || l.showCount },
                          ],
                        },
                        a ? Il(t('fnr.reError')) : Il(t('fnr.recordCount'), [l.findCount])
                      ),
                    ]),
                  ]
                )
              },
            },
          }))
      }
      function Ot(e) {
        const { mouseConfig: t } = Dl,
          l = _l.value,
          r = Jl.value
        return (
          t &&
            l.area &&
            (r.visible ? e.value !== r.activeTab && ((r.activeTab = e.value), Vt()) : $t(e)),
          jt()
        )
      }
      function Wt(l) {
        const { keyboardConfig: r, editConfig: o, highlightCurrentRow: n } = Dl,
          { editStore: a, filterStore: s, ctxMenuStore: i } = Tl,
          d = Bl[zl],
          { xegrid: c } = e,
          u = Ol.value,
          f = Fl.value,
          g = Ml.value,
          m = kl.value,
          w = Nl.value,
          { actived: v, focused: p } = a,
          { keyCode: A } = l,
          S = Tr(l, C.SPACEBAR),
          E = Tr(l, C.ESCAPE),
          x = Tr(l, C.ENTER),
          R = Tr(l, C.ARROW_LEFT),
          b = Tr(l, C.ARROW_UP),
          D = Tr(l, C.ARROW_RIGHT),
          T = Tr(l, C.ARROW_DOWN),
          B = Tr(l, C.TAB),
          V = Tr(l, C.BACKSPACE),
          F = Tr(l, C.DELETE),
          M = Tr(l, C.A),
          W = Tr(l, C.X),
          _ = Tr(l, C.C),
          k = Tr(l, C.V),
          N = Tr(l, C.F),
          H = Tr(l, C.H),
          U = Tr(l, C.M),
          j = Tr(l, C.F2),
          P = Tr(l, C.F10),
          G = Tr(l, C.HOME),
          Z = Tr(l, C.END),
          Q = Tr(l, C.CONTEXT_MENU),
          ee = R || b || D || T,
          te = l.metaKey,
          le = l.ctrlKey,
          re = l.shiftKey,
          oe = nr[X](),
          ne = Dr(o) && v.column && v.row,
          ae = f.beforeEditMethod || f.activeMethod
        if (S && oe && r && u.isChecked) {
          const t = oe.column,
            r = { row: oe.row, column: t }
          Br(t, $)
            ? (kr(l), e.handleToggleCheckRowEvent(l, r))
            : Br(t, O) && (kr(l), e.triggerRadioRowEvent(l, r))
        }
        if (E)
          s.visible && (Nr(l), e.closeFilter()),
            i.visible && (Nr(l), e.closeMenu()),
            or ? (Nr(l), nr[K]()) : Te(l)
        else if (j && Dr(o)) {
          if (ne) u.arrowCursorLock && 'cell' === f.mode && ((p.row = v.row), (p.column = v.column))
          else if (oe) {
            const { offsetRow: t, offsetColumn: r } = De(oe.row, oe.column)
            if (Dr(r.editRender)) {
              const o = { row: t, column: r, cell: e[q](t, r) }
              kr(l), e.handleActived(o, l), (p.row = v.row), (p.column = v.column)
            }
          }
        } else if (Q)
          (Bl._keyCtx = oe && oe.row && oe.column && m.length),
            clearTimeout(uo),
            (uo = setTimeout(() => {
              Bl._keyCtx = !1
            }, 1e3))
        else if (P && re && !i.visible && oe && oe.row && oe.column) {
          const t = 'body',
            r = { type: 'body', $grid: c, $table: e, keyboard: !0, columns: d.slice(0), $event: l }
          ;(r.row = oe.row),
            (r.column = oe.column),
            e.handleOpenMenuEvent && e.handleOpenMenuEvent(l, 'body', r)
        } else if (le && U && r && u.isMerge && oe) ne || (kr(l), Fe(l), nr[K]())
        else if (le && r && u.isFNR && ((N && g.isFind) || (H && g.isReplace))) {
          kr(l)
          const e = Jl.value,
            t = tr[N ? 0 : 1]
          e.visible ? Qr(l, t) : so(l, t.value), Te(l)
        } else if (x && r && u.isEnter) {
          const { column: t, row: r } = v
          if (t && r) {
            const { offsetRow: o, offsetColumn: n } = De(r, t)
            kr(l),
              e[z] ? e[z](l) : e[J](l),
              u.enterToTab
                ? Rt(l, { row: o, column: n }, oe, re, !1, !0)
                : pt(l, { row: o, column: n }, oe, !1, re, !1, !re)
          } else
            oe &&
              (kr(l),
              u.enterToTab
                ? Rt(l, { row: oe.row, column: oe.column }, oe, re, !1, !0)
                : pt(l, { row: oe.row, column: oe.column }, oe, !1, re, !1, !re))
        } else if (ee && r && u.isArrow) {
          debugger
          if (ne) {
            if (u.arrowCursorLock && 'cell' === f.mode && (!p.column || !p.row)) {
              const r = { row: v.row, column: v.column, area: { cols: [v.column], rows: [v.row] } }
              if ((e.clearEdit(l), !re)) pt(l, { row: r.row, column: r.column }, r, R, b, D, T)
              else if (u.isShift) {
                const e = nr[Y]()
                1 >= e.length
                  ? St(l, r, R, b, D, T)
                  : bl && bl[L]({ message: Il(t('area.multiErr')), status: y, id: I })
              }
            }
          } else if (!oe) (b || T) && (w.isCurrent || n) && e.moveCurrentRow(b, T, l)
          else if (!re) pt(l, { row: oe.row, column: oe.column }, oe, R, b, D, T)
          else if (u.isShift) {
            const e = nr[Y]()
            1 >= e.length
              ? St(l, oe, R, b, D, T)
              : bl && bl[L]({ message: Il(t('area.multiErr')), status: y, id: I })
          }
        } else if (B && r && u.isTab) {
          const { column: t, row: r } = v
          if (r && t) {
            const { offsetRow: o, offsetColumn: n } = De(r, t)
            kr(l), e[z] ? e[z](l) : e[J](l), Rt(l, { row: o, column: n }, oe, re, !0, !1)
          } else oe && (kr(l), Rt(l, { row: oe.row, column: oe.column }, oe, re, !0, !1))
        } else if ((F || V) && Dr(o) && r && u.isDel) {
          const { delMethod: t, backMethod: r } = u
          if (!ne)
            if (V && oe) {
              const { column: t, row: o } = oe
              if (o && t) {
                const { offsetRow: n, offsetColumn: a } = De(o, t)
                if (Dr(a.editRender)) {
                  const t = {
                    row: n,
                    rowIndex: e.getRowIndex(n),
                    column: a,
                    columnIndex: e.getColumnIndex(a),
                    cell: e[q](n, a),
                    $table: e,
                    $grid: c,
                  }
                  ;(!ae || ae(t)) &&
                    (r
                      ? r(t)
                      : ((t.cell = e[q](n, a)), vl(n, a.field, null), e.handleActived(t, l)))
                }
              }
            } else if (F && oe) {
              const r = nr[Y](),
                o = {
                  row: oe.row,
                  rowIndex: e.getRowIndex(oe.row),
                  column: oe.column,
                  columnIndex: e.getColumnIndex(oe.column),
                  activeArea: oe,
                  cellAreas: r,
                  $table: e,
                }
              ol(r, (l) => {
                const { rows: r, cols: o } = l
                ol(r, (l) => {
                  ol(o, (r) => {
                    if (Dr(r.editRender)) {
                      const o = e.getRowIndex(l),
                        n = e.getColumnIndex(r),
                        a = {
                          row: l,
                          rowIndex: o,
                          column: r,
                          columnIndex: n,
                          cell: e[q](l, r),
                          $table: e,
                          $grid: c,
                        }
                      ;(!ae || ae(a)) && (t ? t(a) : vl(l, r.field, null))
                    }
                  })
                })
              }),
                e.dispatchEvent('cell-delete-value', o, l)
            }
        } else if (r && le && M) ne || io(l)
        else if (r && u.isClip && le && Rl.msie && (W || _ || k));
        else if (r && le && (G || Z)) Ct(l, oe, Z)
        else if (
          r &&
          Dr(o) &&
          u.isEdit &&
          !le &&
          !te &&
          (S ||
            (48 <= A && 57 >= A) ||
            (65 <= A && 90 >= A) ||
            (96 <= A && 111 >= A) ||
            (186 <= A && 192 >= A) ||
            (219 <= A && 222 >= A)) &&
          !ne &&
          oe
        ) {
          const { column: t, row: r } = oe
          if (r && t) {
            const { offsetRow: o, offsetColumn: n } = De(r, t),
              { editMethod: a } = u
            if (Dr(n.editRender)) {
              const t = {
                row: o,
                rowIndex: e.getRowIndex(o),
                column: n,
                columnIndex: e.getColumnIndex(n),
                cell: e[q](o, n),
                $table: e,
                $grid: c,
              }
              ;(!ae || ae(t)) && (a ? a(t) : (vl(o, n.field, null), e.handleActived(t, l)))
            }
          }
        }
      }
      function _t(e) {
        return e.map((e) =>
          e.map((e) => {
            let t = 0
            const l = !isNaN(e)
            let o = '{0}'
            return (
              l ? (t = e) : (o = r(fl(e), /\d+(?!.*\d)/, (e) => ((t = e), '{0}'))),
              { v: o, n: ul(t), t: l }
            )
          })
        )
      }
      function Lt(e, t) {
        const { targetValues: l, targetRows: r, extendRows: o, extendCols: n } = e,
          s = _t(l),
          i = s[t ? 0 : s.length - 2],
          d = s[t ? 1 : s.length - 1],
          c = n.map((e, l) => {
            const r = i[l],
              o = d[l]
            return { offset: t ? r.n - o.n : o.n - r.n, num: t ? r.n : o.n }
          }),
          u = o.map((e, l) => {
            const o = l % r.length,
              i = s[t ? s.length - o - 1 : o]
            return n.map((e, t) => {
              const l = i[t],
                r = c[t],
                { num: o, offset: n } = r,
                s = o + n
              return !l.t && 0 >= s && (r.offset = a(n)), (r.num = s), hl(l.v, [s])
            })
          })
        return t && u.reverse(), u
      }
      function kt(e, t) {
        const { targetValues: l, targetCols: r, extendRows: o, extendCols: n } = e,
          s = _t(l),
          i = s.map((e) => {
            const l = e[t ? 0 : e.length - 2],
              r = e[t ? 1 : e.length - 1]
            return { offset: t ? l.n - r.n : r.n - l.n, num: t ? l.n : r.n }
          })
        return o.map((e, l) => {
          const o = i[l],
            d = s[l],
            c = n.map((e, l) => {
              const n = l % r.length,
                s = d[t ? d.length - n - 1 : n],
                { num: i, offset: c } = o,
                u = i + c
              return !s.t && 0 >= u && (o.offset = a(c)), (o.num = u), hl(s.v, [u])
            })
          return t && c.reverse(), c
        })
      }
      function Nt(t, l, r, o) {
        const n = Ll.value,
          { xegrid: a } = e,
          { rows: s, cols: i } = o,
          d = s.length > l.length,
          c = i.length > r.length,
          u = t.shiftKey,
          f = t.ctrlKey
        let g
        if (!u && (d || c)) {
          const {
              extendByCopy: t,
              extendByCalc: o,
              extendCalcMethod: u,
              extendSetMethod: m,
              beforeExtendSetMethod: w,
              afterExtendSetMethod: v,
            } = n,
            p = Wr(l[0]),
            C = Wr(s[0]),
            A = _r(r[0]),
            S = _r(i[0])
          let E = !1,
            x = !1,
            R = []
          if (d) {
            const n = !f && o && 2 <= l.length
            if (t || n) {
              let t = []
              ;(R = l.map((e) => r.map((t) => Ze(e, t, 0)))),
                C < p ? ((E = !0), (t = s.slice(0, s.length - l.length))) : (t = s.slice(l.length))
              const o = {
                rows: s,
                cols: i,
                targetValues: R,
                targetRows: l,
                targetCols: r,
                extendRows: t.slice(0),
                extendCols: r,
                direction: E ? 'up' : 'down',
                $table: e,
                $grid: a,
              }
              ;(w && !1 === w(o)) ||
                ((g = R),
                n && (u ? (g = u(o)) : (g = Lt(o, E))),
                ol(t, (e, l) => {
                  const n = E ? t.length % g.length : 0,
                    a = (n + l) % g.length,
                    s = g[a]
                  ol(r, (t, l) => {
                    const r = s[l % s.length]
                    if (m) m(qt({ cellValue: r, row: e, column: t }, o))
                    else {
                      const { field: l } = t
                      l && vl(e, l, r)
                    }
                  })
                }),
                v && v(qt({ extendValues: g }, o)))
            }
          } else if (c) {
            const n = !f && o && 2 <= r.length
            if (t || n) {
              let t = []
              S < A ? ((x = !0), (t = i.slice(0, i.length - r.length))) : (t = i.slice(r.length)),
                (R = l.map((e) => r.map((t) => Ze(e, t, 0))))
              const o = {
                rows: s,
                cols: i,
                targetValues: R,
                targetRows: l,
                targetCols: r,
                extendRows: l,
                extendCols: t.slice(0),
                direction: x ? 'left' : 'right',
                $table: e,
                $grid: a,
              }
              ;(w && !1 === w(o)) ||
                ((g = R),
                n && (u ? (g = u(o)) : (g = kt(o, x))),
                ol(l, (e, l) => {
                  const r = l % g.length,
                    n = g[r],
                    a = x ? t.length % n.length : 0
                  ol(t, (t, l) => {
                    const r = (a + l) % n.length,
                      s = n[r]
                    if (m) m(qt({ cellValue: s, row: e, column: t }, o))
                    else {
                      const { field: l } = t
                      l && vl(e, l, s)
                    }
                  })
                }),
                v && v(qt({ extendValues: g }, o)))
            }
          }
        }
      }
      function Ht(l, r) {
        const { button: o } = l,
          n = 0 === o
        if (!n) return
        const { fixed: s } = r,
          i = nr[Y](),
          d = sl(i, (e) => e.type === f)
        if ((Lr(), d)) {
          const o = u[F],
            n = u[M],
            i = Hl.value,
            c = l.clientX,
            f = l.clientY,
            g = Yl.value,
            w = Pl.value,
            v = Gl.value,
            p = g.$el,
            C = w ? w.$el : null,
            A = v ? v.$el : null,
            S = C || p,
            E = A || p,
            { rows: x, cols: R } = d,
            b = pl(R),
            T = pl(x),
            B = Cl(R),
            V = Cl(x),
            $ = d.top,
            O = d.left,
            W = d.width,
            _ = d.height,
            N = Se(b.fixed),
            H = Ur(N, '.vxe-table--cell-area'),
            U = H.children[2],
            X = S.scrollTop,
            Y = 'left' === s ? 0 : E.scrollLeft,
            j = Wr(V),
            G = _r(B),
            Z = ye(j, G)
          let K = 1,
            z = 1,
            J = 0,
            q = 0,
            ee = '',
            le = null,
            re = null
          const oe = Ur(N, '.vxe-body--row'),
            ne = oe[Q],
            ae = { type: m, cols: [], rows: [], top: 0, left: 0, width: 0, height: 0 },
            se = (t) => {
              const { offsetTop: l, offsetLeft: r } = bt(t, s, c, f, Y, X, E, S),
                o = a(l),
                n = a(r),
                i = E.scrollWidth,
                u = S.scrollHeight
              let g = _,
                m = W,
                w = $,
                v = O
              switch (ee) {
                case 'top':
                  if (l < -_) {
                    const e = te($, $e(T, b, Z, !1, o - _))
                    ;(w -= e), (g += e)
                  }
                  break
                case 'bottom':
                  0 < l && (g += te(u - $ - _, $e(V, B, Z, !0, o)))
                  break
                case 'left':
                  if (r < -W) {
                    const e = te(O, Oe(T, b, Z, !1, n - W))
                    ;(v -= e), (m += e)
                  }
                  break
                case 'right':
                  0 < r && (m += te(i - O - W, Oe(V, B, Z, !0, n)))
              }
              ;(ae.height = g),
                (ae.width = m),
                (ae.left = v),
                (ae.top = w),
                (ae.cols = Le(ne, ae)),
                (ae.rows = ke(oe, ae)),
                qt(ae, Ue(ae)),
                e[k](
                  'cell-area-extension-drag',
                  { rows: d.rows, cols: d.cols, targetRows: ae.rows, targetCols: ae.cols },
                  t
                )
            },
            ie = (t) => {
              Lr(),
                (lr = setTimeout(() => {
                  if (lr) {
                    const { clientHeight: l, clientWidth: r } = p,
                      { scrollLeft: o, scrollWidth: n } = E,
                      { scrollTop: a, scrollHeight: s } = S
                    let i = null,
                      d = null
                    null !== le && (le ? a + l < s && (i = a + K * D) : a && (i = a - K * D)),
                      null !== re && (re ? o + r < n && (d = o + z * D) : o && (d = o - z * D)),
                      zt(d) || zt(i) ? (e[P](d, i), ie(t), se(t)) : Lr()
                  }
                }, 50))
            }
          ;(u[F] = (e) => {
            Hr(e)
            const { clientY: t, clientX: l } = e,
              { clientHeight: r, clientWidth: o } = p,
              { offsetTop: n, offsetLeft: a } = bt(e, s, c, f, Y, X, E, S),
              i = Xr(p),
              d = i.top,
              u = i.left,
              g = O + W + a,
              m = $ + _ + n
            let w
            ;(le = null),
              (re = null),
              (J = t),
              (q = l),
              J < d
                ? ((w = !0), (le = !1), (K = d - J))
                : J > d + r && ((w = !0), (le = !0), (K = J - d - r)),
              q < u
                ? ((w = !0), (re = !1), (z = u - q))
                : q > u + o && ((w = !0), (re = !0), (z = q - u - o)),
              g < O
                ? m > $ && m < $ + _ && (ee = 'left')
                : g > O + W
                  ? m > $ && m < $ + _ && (ee = 'right')
                  : m < $
                    ? (ee = 'top')
                    : m > $ + _ && (ee = 'bottom'),
              w ? !lr && ie(e) : Lr(),
              se(e)
          }),
            (u[M] = () => {
              ;(u[F] = o), (u[M] = n), Lr(), Ae(i, 'drag--extend-range')
              const r = ke(oe, ae),
                a = Le(ne, ae)
              ct(r, a)
                ? ((d.rows = r), (d.cols = a))
                : bl && bl[L]({ message: Il(t('area.extendErr')), status: y, id: I }),
                qt(d, Ue(d)),
                pe(U),
                Nt(l, x, R, d),
                (rr = !1),
                e[k](
                  'cell-area-extension-end',
                  { rows: d.rows, cols: d.cols, targetRows: x, targetCols: R },
                  l
                )
            }),
            Ce(i, 'drag--extend-range'),
            (rr = !0),
            e[k]('cell-area-extension-start', qt({ targetRows: x, targetCols: R }, r), l)
        }
      }
      function Ut(t, l) {
        if (!br) return
        const { button: r } = t,
          o = 0 === r,
          n = 2 === r
        if (!(o || n)) return
        const s = Hl.value,
          { editStore: i } = Tl,
          d = Fl.value,
          c = Ll.value,
          { actived: g, selected: m } = i,
          { fixed: p, row: C, column: A, cell: S } = l,
          E = mo(A)
        if (E.length) return void e[k]('cell-area-selection-invalid', { row: C, column: A }, t)
        if (rr) return void kr(t)
        if (g.row === C && ('cell' === d.mode ? g.column === A : 'row' === d.mode)) return
        const x = t.ctrlKey,
          R = t.shiftKey,
          b = x && c.multiple,
          I = S.offsetTop,
          y = S.offsetLeft,
          T = S.offsetWidth,
          B = S.offsetHeight
        let V = nr[Y]()
        const $ = nr[X]()
        if ((Lr(), !n && R && $)) return void At(t, $, V, l)
        if (
          (g.column && (e[z] ? e[z](t) : e[J](t)),
          m.column && e.clearSelected(),
          !n || !ml(V, (e) => $r(e.rows, C) && Fr(e.cols, A)))
        ) {
          const r = {
            row: $ ? $.row : null,
            column: $ ? $.column : null,
            isTab: !1,
            isEnter: !1,
            isLeft: !1,
            isUp: !1,
            isRight: !1,
            isDown: !1,
          }
          if ((e[k]('active-cell-change-start', { ...r, activeArea: $ }, t), t.cancelBubble)) return
          b || ((V = []), nr[U]())
          const o = u[F],
            i = u[M],
            d = t.clientX,
            c = t.clientY,
            g = Yl.value,
            m = Pl.value,
            E = Gl.value,
            x = Be(t, S),
            R = g.$el,
            O = m ? m.$el : null,
            W = E ? E.$el : null,
            _ = O || R,
            L = W || R,
            N = Se(A.fixed),
            H = Ur(N, '.vxe-table--cell-area'),
            Y = H.children,
            G = Y[0],
            Z = Y[2],
            K = Y[3],
            z = S,
            J = z.parentNode,
            q = Ur(N, '.vxe-body--row'),
            ee = q ? q[Q] : null,
            le = _.scrollTop,
            re = 'left' === p ? 0 : L.scrollLeft
          let oe = null,
            ne = null,
            ae = 1,
            se = 1,
            ie = 0,
            de = 0
          const ce = { type: b ? w : f, cols: [], rows: [], top: 0, left: 0, width: 0, height: 0 },
            ue = {
              type: v,
              area: ce,
              column: A,
              row: C,
              top: S.offsetTop,
              left: S.offsetLeft,
              width: S.offsetWidth,
              height: S.offsetHeight,
            }
          if (b) {
            const e = p ? `${p}El` : 'el',
              t = sl(V, (e) => e.type === f)
            t && ((t.type = w), (t[e] = Ee(K)), Re(t[e], t)), (ce[e] = Ee(K))
          }
          nr[j](ue), V.push(ce)
          const fe = (t) => {
              const { offsetTop: l, offsetLeft: r } = bt(t, p, d, c, re, le, L, _),
                o = L.scrollWidth,
                n = _.scrollHeight,
                s = 0 <= l,
                i = 0 <= r
              let u,
                f,
                g = a(l),
                m = a(r)
              s
                ? ((u = We(C, A, J, s, g + x.offsetY)), (g = te(n - I, u.moveHeight)))
                : ((u = We(C, A, J, s, g - x.offsetY)), (g = te(I + B, u.moveHeight))),
                i
                  ? ((f = _e(C, A, S, i, m + x.offsetX)), (m = te(o - y, f.moveWidth)))
                  : ((f = _e(C, A, S, i, m - x.offsetX)), (m = te(y + T, f.moveWidth)))
              const { rows: w, cols: v } = Me(u.rows, f.cols)
              ;(ce.rows = w),
                (ce.cols = v),
                pe(Z),
                b ? (pe(G), Yr(K)) : (pe(K), Yr(G)),
                qt(ce, Ue(ce)),
                e[k]('cell-area-selection-drag', { rows: ce.rows, cols: ce.cols }, t)
            },
            he = (t) => {
              Lr(),
                (lr = setTimeout(() => {
                  if (lr) {
                    const { clientHeight: l, clientWidth: r } = R,
                      { scrollLeft: o, scrollWidth: n } = L,
                      { scrollTop: a, scrollHeight: s } = _
                    let i = null,
                      d = null
                    null !== oe && (oe ? a + l < s && (i = a + ae * D) : a && (i = a - ae * D)),
                      null !== ne && (ne ? o + r < n && (d = o + se * D) : o && (d = o - se * D)),
                      zt(d) || zt(i) ? (e[P](d, i), he(t), fe(t)) : Lr()
                  }
                }, 50))
            }
          n ||
            (u[F] = (e) => {
              Hr(e)
              const { clientY: t, clientX: l } = e,
                { clientHeight: r, clientWidth: o } = R,
                n = Xr(R),
                a = n.top,
                s = n.left
              let i
              ;(oe = null),
                (ne = null),
                (ie = t),
                (de = l),
                ie < a
                  ? ((i = !0), (oe = !1), (ae = a - ie))
                  : ie > a + r && ((i = !0), (oe = !0), (ae = ie - a - r)),
                de < s
                  ? ((i = !0), (ne = !1), (se = s - de))
                  : de > s + o && ((i = !0), (ne = !0), (se = de - s - o)),
                i ? !lr && he(e) : Lr(),
                fe(e)
            }),
            (u[M] = () => {
              ;(u[F] = o),
                (u[M] = i),
                Lr(),
                (ce.cols = Le(ee, ce)),
                (ce.rows = ke(q, ce)),
                Ae(s, 'drag--area'),
                e[k]('cell-area-selection-end', { rows: ce.rows, cols: ce.cols }, t)
            }),
            Ce(s, 'drag--area'),
            fe(t),
            (er.value = V),
            e[k]('cell-area-selection-start', l, t),
            e[k]('active-cell-change-end', { ...r, beforeActiveArea: $, activeArea: nr[X]() }, t)
        }
      }
      const Xt = h,
        Yt = ref,
        jt = nextTick,
        Pt = resolveComponent,
        {
          now: Gt,
          uniq: Zt,
          uniqueId: Kt,
          isNumber: zt,
          browse: Jt,
          assign: qt,
          slice: Qt,
          isBoolean: el,
          indexOf: tl,
          includes: ll,
          eachTree: rl,
          arrayEach: ol,
          hasOwnProp: nl,
          toInteger: al,
          find: sl,
          findLast: il,
          findLastIndexOf: dl,
          findIndexOf: cl,
          toNumber: ul,
          toValueString: fl,
          toFormatString: hl,
          filter: gl,
          some: ml,
          get: wl,
          set: vl,
          first: pl,
          last: Cl,
          isArray: Al,
          toStringJSON: Sl,
          template: El,
          sample: xl,
        } = XEUtils,
        Rl = Jt()
      const bl = N ? o[I ? 'modal' : ''] : X,
        Il = X ? o.t : ae,
        yl = k ? (o.globalStore ? o.globalStore : o.config) : ue,
        { props: Dl, reactData: Tl, internalData: Bl } = e,
        {
          computeSize: Vl,
          computeEditOpts: Fl,
          computeFNROpts: Ml,
          computeClipOpts: $l,
          computeKeyboardOpts: Ol,
          computeSeqOpts: Wl,
          computeMouseOpts: _l,
          computeAreaOpts: Ll,
          computeBodyMenu: kl,
          computeRowOpts: Nl,
        } = e.getComputeMaps(),
        {
          refElem: Hl,
          refLeftContainer: Ul,
          refRightContainer: Xl,
          refTableBody: Yl,
          refTableLeftBody: jl,
          refTableRightBody: Pl,
          refTableFooter: Gl,
        } = e.getRefMaps(),
        Zl = (fe ? 'table' : '') + 'Full' + (le ? 'Column' : ''),
        Kl = (z ? 'after' : '') + 'Full' + (X ? 'Data' : ''),
        zl = (O ? 'visible' : '') + (x ? 'Column' : ''),
        Jl = Yt({
          visible: !1,
          activeTab: '',
          findValue: '',
          replaceValue: '',
          isRE: !1,
          isWhole: !1,
          isSensitive: !1,
          showREErr: !1,
          showCount: !1,
          findCellRE: null,
          findCount: 0,
        }),
        ql = Yt([]),
        Ql = Yt(null),
        er = Yt([]),
        tr = [
          { value: 'find', label: t('fnr.tabs.find') },
          { value: 'replace', label: t('fnr.tabs.replace') },
        ]
      let lr,
        rr,
        or = null,
        nr = {},
        ar = {}
      const sr = s[c('ZGVjb2RlVVJJQ29tcG9uZW50')]
      let ir = 0
      const dr = Date.now(),
        cr = 'WEUwMDAwMjAXx',
        ur = zt(dr)
          ? [
              'JTdCJTIybm8l',
              'MjIlM0ElMjJ',
              'YRTAwMDAyMDEl',
              'MjIlMkMlM',
              'jJrZXklMjIlM0E',
              'lMjJqc3g4bGF1Z',
              'npmNW9maD',
              'E1JTIyJTJDJTI',
              'ydHlwZSUyMiU',
              'zQSUyMjEl',
              'MjIlMkMlMj',
              'JhdlR5cGUlMjI',
              'lM0ElMjJFbnRl',
              'cnByaXNlJTIyJ',
              'TJDJTIydWVUaW',
              '1lJTIyJTNBL',
              'TElMkMlMjJtJTIy',
              'JTNBJTVCJTIyZ2',
              '90b2ZyZWlnaH',
              'QuY29tJTIyJTJD',
              'JTIyc2hpaml6a',
              'G9uZ3l1bi5jb20l',
              'MjIlNUQlMk',
              'MlMjJzJTIyJTN',
              'BJTVCJTVEJTJDJ',
              'TIyaSUyMiU',
              'zQSU1QiU',
              'yMjEwLiouKi4',
              'qJTIyJTJDJTIy',
              'MTcyLjE2LiouKiU',
              'yMiUyQyUyMjE5Mi4',
              'xNjguKi4qJT',
              'IyJTVEJTdE',
            ].join('')
          : '0G58JFNSHCKSUSJ51OSIUDFF'
      let fr = !0
      const hr = 'https://vxetable.cn',
        gr = `${'https://vxetable.cn'}/plugins/`,
        mr = 4,
        wr = [
          '%',
          '5B',
          'v',
          'x',
          'e-ta',
          'bl',
          'e%2',
          '0v',
          '%7',
          'B%',
          '7B',
          '0%7',
          'D%',
          '7D%',
          '20pl',
          'ugi',
          'n%20',
          '%7',
          'B%7',
          'B1%7',
          'D%',
          '7D%5',
          'D%',
          '20Tr',
          'ia',
          'l%3A',
          '%7',
          'B%',
          '7B',
          '2%7',
          'D%7',
          'D',
          '%EF%',
          'BC%',
          '8C',
          '%E8',
          '%A',
          'F%',
          '95%',
          'E7',
          '%94',
          '%A8',
          '%E',
          '7%8',
          '9%',
          '8',
          '8%E',
          '5%8F',
          '%AA%',
          'E8%',
          '83',
          '%BD',
          '%E7',
          '%9',
          '4%A',
          '8%',
          'E4',
          '%BA%',
          '8E',
          '%E6%',
          'BC',
          '%9',
          '4%',
          'E7%A',
          '4%B',
          'A%',
          'EF',
          '%BC',
          '%8',
          'C%',
          'E5%',
          'A6%',
          '82',
          '%E',
          '9%9',
          'C%80',
          '%E6',
          '%A',
          'D%',
          'A3%E',
          '5%B',
          'C%',
          '8F%',
          'E4%',
          'BD%',
          'BF',
          '%E7',
          '%9',
          '4%A8',
          '%EF',
          '%B',
          'C%8',
          'C%',
          'E8%',
          'AF',
          '%B',
          '7%E5',
          '%8',
          '8%B0',
          '%E',
          '5%AE',
          '%9',
          '8%E',
          '7%',
          'BD',
          '%91',
          '%2',
          '0%7',
          'B%7',
          'B3%',
          '7D',
          '%7',
          'D%',
          '20%',
          'E8',
          '%B',
          '4%A',
          'D%E4',
          '%',
          'B9%',
          'B0',
          '%E',
          '6%',
          '8E%',
          '88',
          '%E',
          '6%9D',
          '%83',
        ],
        vr = [
          '%',
          '5Bv',
          'xe',
          '-tab',
          'le%2',
          '0v',
          '%7',
          'B%',
          '7B0',
          '%7',
          'D%7',
          'D%',
          '20plu',
          'gin',
          '%20',
          '%7',
          'B%7',
          'B1',
          '%7D%',
          '7D%',
          '5D',
          '%20',
          'Er',
          'ror%3A',
          '%7B',
          '%7B',
          '2%7',
          'D%7D',
          '%20',
          '%E6%',
          '9C',
          '%AA',
          '%E6',
          '%8E',
          '%8',
          '8%E',
          '6%9',
          'D%',
          '83%E',
          'F%B',
          'C%',
          '8C',
          '%E5',
          '%A6',
          '%8',
          '2%E',
          '9%',
          '9C%',
          '80%',
          'E7%B',
          'B%A',
          '7%E7%',
          'BB%',
          'AD',
          '%E',
          '4%B',
          'D%',
          'BF',
          '%E7%',
          '94%A',
          '8',
          '%EF%',
          'BC%',
          '8C%E',
          '8%A',
          'F%B',
          '7%E',
          '5%',
          '88',
          '%B',
          '0%',
          'E5',
          '%AE%',
          '98',
          '%E7%',
          'BD%',
          '91%',
          '20',
          '%7',
          'B%7',
          'B3%7',
          'D%7D',
          '%2',
          '0%E',
          '8%',
          'B4',
          '%AD',
          '%E',
          '4%',
          'B9',
          '%B',
          '0%E6',
          '%8E',
          '%8',
          '8%E6',
          '%9D',
          '%8',
          '3',
        ],
        pr = [
          '%5Bv',
          'x',
          'e-t',
          'ab',
          'le%2',
          '0v%',
          '7B%',
          '7B0%',
          '7D%7',
          'D%2',
          '0plu',
          'gin',
          '%20',
          '%7',
          'B%7',
          'B1%7',
          'D%',
          '7D%',
          '5D%',
          '20E',
          'xpi',
          'red',
          '%3',
          'A%7',
          'B%',
          '7B2',
          '%7D%',
          '7D',
          '%20',
          '%E5',
          '%B7%',
          'B2%',
          'E8',
          '%BF',
          '%8',
          '7%',
          'E6%',
          '9C',
          '%',
          '9F%E',
          'F%B',
          'C%',
          '8C%',
          'E5%',
          'A',
          '6%8',
          '2%E',
          '9%',
          '9C',
          '%8',
          '0%',
          'E7%',
          'BB',
          '%A7',
          '%E7',
          '%BB',
          '%AD',
          '%E4',
          '%BD',
          '%B',
          'F%E',
          '7%',
          '94%',
          'A8%',
          'EF',
          '%BC%8',
          'C%E',
          '8%AF',
          '%B7',
          '%E',
          '5%8',
          '8%',
          'B0%E',
          '5%',
          'AE',
          '%98',
          '%E7',
          '%B',
          'D%91',
          '%20',
          '%7',
          'B%',
          '7B',
          '3%',
          '7D%',
          '7D',
          '%2',
          '0%E8',
          '%B',
          '4%A',
          'D%',
          'E4',
          '%B9',
          '%B',
          '0%E6',
          '%8',
          'E%',
          '88',
          '%E6',
          '%9D',
          '%8',
          '3',
        ],
        Cr = [
          '%',
          '5B',
          'vx',
          'e-t',
          'abl',
          'e',
          '%20',
          'v%',
          '7B%',
          '7B0',
          '%7D',
          '%7',
          'D%2',
          '0pl',
          'ug',
          'in',
          '%2',
          '0%',
          '7B%',
          '7B',
          '1%7',
          'D%7D',
          '%5D',
          '%20',
          'Suc',
          'ce',
          'ss%',
          '3A%',
          '7B%7',
          'B2%',
          '7D%7',
          'D%E',
          'F%',
          'BC%',
          '8CA',
          'PI',
          '%EF',
          '%BC%',
          '9A%',
          '7B%7',
          'B3%',
          '7D%',
          '7D',
        ],
        Ar = ul('-1'),
        Sr = ul('-1')
      let Er = !0
      const xr = !!fl(''),
        Rr = !!fl(''),
        br = Gt(),
        Ir = function (e) {
          return e ? e.toLowerCase() : ''
        },
        yr = (Er ? 'ena' : '') + 'bled',
        Dr = (e) => e && !1 !== e[yr],
        Tr = (e, t) => {
          const { key: l } = e
          return (t = Ir(t)), t === Ir(l) || !!(A[l] && Ir(A[l]) === t)
        },
        Br = (e, t) => e.type === t,
        Vr = (e, t) => cl(e, (e) => e.id === t.id),
        Fr = (e, t) => -1 < Vr(e, t),
        Mr = (t, l) => e.findRowIndexOf(t, l),
        $r = (e, t) => -1 < Mr(e, t),
        Or = (e) => (e === g ? 1 : e === m ? 2 : e === w ? 3 : e === v ? 4 : 0),
        Wr = (t) => e.getVTRowIndex(t),
        _r = (t) => e.getVTColumnIndex(t),
        Lr = () => {
          clearTimeout(lr), (lr = null)
        },
        kr = (e) => {
          e.preventDefault()
        },
        Nr = (e) => {
          e.stopPropagation()
        },
        Hr = (e) => {
          Nr(e), kr(e)
        },
        Ur = (e, t) => (e ? e.querySelector(t) : null),
        Xr = (e) => e.getBoundingClientRect(),
        Yr = (e) => {
          e && (e.style.display = 'block')
        }
      let jr = 0
      const Pr = '\t',
        Gr = (e) => {
          const t = Bl[Kl],
            l = pl(t),
            r = []
          rl(
            [e],
            (e) => {
              ;(e.children && e.children.length) || r.push(e)
            },
            ee
          )
          const o = pl(r),
            n = Cl(r)
          return (
            jt(() => {
              nr[H]([{ type: f, startColumn: o, endColumn: n, startRow: l, endRow: Cl(t) }], {
                column: o,
                row: l,
              })
            }),
            { cols: r, rows: t }
          )
        },
        Zr = (e, t) => {
          const l = Bl[zl]
          let r
          for (let o = t - 1; 0 <= o; o--) {
            const e = l[o],
              t = mo(e)
            if (!t.length) {
              r = e
              break
            }
          }
          return r
        },
        Kr = (e, t, l, r) => {
          const o = Bl[zl],
            n = Bl[Kl]
          let a,
            s = Zr(l, r)
          return (
            s ||
              ((a = n[t ? t - 1 : n.length - 1]),
              (s = il(o, (e) => {
                const t = mo(e)
                return !t.length
              }))),
            { offsetRow: a || e, offsetColumn: s || l }
          )
        },
        zr = (e, t) => {
          const l = Bl[zl]
          let r
          for (let o = t + 1; o < l.length; o++) {
            const e = l[o],
              t = mo(e)
            if (!t.length) {
              r = e
              break
            }
          }
          return r
        },
        Jr = (e, t, l, r) => {
          const o = Bl[zl],
            n = Bl[Kl]
          let a,
            s = zr(l, r)
          return (
            s ||
              ((a = n[t < n.length - 1 ? t + 1 : 0]),
              (s = sl(o, (e) => {
                const t = mo(e)
                return !t.length
              }))),
            { offsetRow: a || e, offsetColumn: s || l }
          )
        }
      let qr
      const Qr = (t, l) => {
          const r = Jl.value
          r.activeTab !== l.value &&
            ((r.activeTab = l.value), Vt(), e[k]('fnr-change', { tab: r.activeTab }, t))
        },
        eo = (t, l) => {
          const r = Bl[zl],
            o = Bl[Kl],
            n = o[l.row],
            a = r[l.col]
          n &&
            a &&
            (Bt(),
            (l.isActived = !0),
            e[G](n, a),
            nr[H]([{ type: f, startRow: n, endRow: n, startColumn: a, endColumn: a }]))
        },
        to = (l) => {
          const r = !1,
            o = Jl.value,
            n = nr[X](),
            a = fl(o.findValue),
            s = fl(o.replaceValue)
          if (It(a) && (a || s))
            if (n) {
              const o = Ml.value,
                { xegrid: i } = e,
                { beforeReplaceMethod: d, replaceMethod: c, afterReplaceMethod: u } = o,
                { row: f, column: g } = n
              if (d && !1 === d({ isAll: r, findValue: a, replaceValue: s, $table: e, $grid: i }))
                return
              yt(f, g, a)
                ? (c ? c({ row: f, column: g, cellValue: s }) : vl(f, g.field, s),
                  Ft(),
                  Bt(),
                  u &&
                    u({
                      isAll: r,
                      findValue: a,
                      replaceValue: s,
                      result: [{ row: f, _rowIndex: Wr(f), column: g, _columnIndex: _r(g) }],
                      $table: e,
                      $grid: i,
                    }),
                  e[k]('fnr-replace', { findValue: a, replaceValue: s, row: f, column: g }, l))
                : bl && bl[L]({ message: Il(t('fnr.notCell')), status: 'warning', id: 'operWarn' })
            } else bl && bl[L]({ message: Il(t('fnr.notCell')), status: 'warning', id: 'operWarn' })
        },
        lo = (e) => {
          const { $event: t } = e,
            l = Tr(t, R)
          l && to(t)
        },
        ro = (l) => {
          const r = !0,
            o = Jl.value,
            n = Ml.value,
            a = fl(o.findValue),
            s = fl(o.replaceValue)
          if (a || s) {
            const { xegrid: i } = e,
              { beforeReplaceMethod: d, replaceMethod: c, afterReplaceMethod: u } = n
            if (o.showREErr) return
            if (d && !1 === d({ isAll: r, findValue: a, replaceValue: s, $table: e, $grid: i }))
              return
            const f = Ft(!0)
            if ((Bt(), f.length)) {
              const o = []
              ol(f, (e) => {
                const { offsetRow: t, _rowIndex: l, offsetColumn: r, _columnIndex: n } = e
                c ? c({ row: t, column: r, cellValue: s }) : vl(t, r.field, s),
                  o.push({ row: t, _rowIndex: l, column: r, _columnIndex: n })
              }),
                u && u({ isAll: r, findValue: a, replaceValue: s, result: o, $table: e, $grid: i }),
                e[k]('fnr-replace-all', { findValue: a, replaceValue: s, result: o }, l),
                bl &&
                  bl[L]({
                    message: Il(t('fnr.replaceSuccess'), [f.length]),
                    status: 'success',
                    id: 'operEnd',
                  })
            } else bl && bl[L]({ message: Il(t('fnr.notCell')), status: 'warning', id: 'operWarn' })
          }
        },
        oo = (l) => {
          const r = !1,
            o = Jl.value,
            n = Ml.value,
            { xegrid: a } = e,
            { beforeFindMethod: s, afterFindMethod: i } = n,
            d = fl(o.findValue)
          if (!o.showREErr && !(s && !1 === s({ isAll: !1, findValue: d, $table: e, $grid: a }))) {
            const r = Ft()
            if ((Bt(), !r.length))
              return void (
                bl && bl[L]({ message: Il(t('fnr.notCell')), status: 'warning', id: 'operWarn' })
              )
            const o = r[0]
            i &&
              i({
                isAll: !1,
                findValue: d,
                result: r.map((e) => ({
                  row: e.offsetRow,
                  _rowIndex: e._rowIndex,
                  column: e.offsetColumn,
                  _columnIndex: e._columnIndex,
                })),
                $table: e,
                $grid: a,
              }),
              e[k]('fnr-find', { findValue: d, row: o.offsetRow, column: o.offsetColumn }, l)
          }
        },
        no = (e) => {
          const { $event: t } = e,
            l = Jl.value,
            r = Tr(t, R)
          r && ('replace' === l.activeTab ? to(t) : oo(t))
        },
        ao = (l) => {
          const r = !0,
            o = Jl.value,
            n = Ml.value,
            { xegrid: a } = e,
            { beforeFindMethod: s, afterFindMethod: i } = n,
            d = fl(o.findValue)
          if (
            ((ql.value = []), !o.showREErr) &&
            !(s && !1 === s({ isAll: !0, findValue: d, $table: e, $grid: a }))
          ) {
            const n = Ft(!0)
            if (((o.findCount = n.length), (o.showCount = !0), n.length)) {
              const o = [],
                s = n.map((e, l) => {
                  const { offsetRow: r, offsetColumn: n, _rowIndex: a, _columnIndex: s } = e,
                    i = fl(wl(r, n.field)),
                    d = i || Il(t('fnr.empty'))
                  return (
                    o.push({ row: r, _rowIndex: a, column: n, _columnIndex: s }),
                    { seq: l + 1, row: a, col: s, isActived: !1, value: d }
                  )
                })
              setTimeout(() => {
                ;(ql.value = s), i && i({ isAll: r, findValue: d, result: o, $table: e, $grid: a })
              }, 10),
                e[k]('fnr-find-all', { findValue: d, result: o }, l)
            } else bl && bl[L]({ message: Il(t('fnr.notCell')), status: 'warning', id: 'operWarn' })
          }
        },
        so = (t, l) => {
          if (!ve()) return
          const r = Jl.value,
            o = Ml.value,
            n = sl(tr, (e) => e.value === l) || tr[0]
          ;(('find' !== n.value || !o.isFind) && ('replace' !== n.value || !o.isReplace)) ||
            (Ot(n), e[k]('open-fnr', { tab: r.activeTab }, t))
        },
        io = (t) => {
          const l = Bl[zl],
            r = Bl[Kl]
          r.length &&
            l.length &&
            (kr(t),
            e[k]('cell-area-selection-all-start', {}, t),
            nr[H]([
              { type: f, startRow: pl(r), endRow: Cl(r), startColumn: pl(l), endColumn: Cl(l) },
            ]),
            e[k]('cell-area-selection-all-end', { rows: Qt(r, 0), cols: Qt(l, 0) }, t))
        },
        co = (t, l) => {
          t.length &&
            e.recalculate().then(() => {
              const e = Bl[zl],
                r = Bl[Kl],
                o = []
              if (
                (ol(t, (t) => {
                  const { type: l, cols: n, rows: a } = t,
                    s = sl(n, (t) => Fr(e, t)),
                    i = sl(a, (e) => $r(r, e))
                  if (i && s) {
                    const t = il(n, (t) => Fr(e, t)),
                      d = il(a, (e) => $r(r, e))
                    o.push({ type: l, startColumn: s, endColumn: t, startRow: i, endRow: d })
                  }
                }),
                o.length)
              ) {
                let e
                l && (e = { area: o[tl(t, l.area)], row: l.row, column: l.column }), nr[H](o, e)
              } else nr[U]()
              if (or) {
                const t = or.cellAreas[0],
                  { cols: l, rows: o } = t,
                  n = cl(l, (t) => Fr(e, t)),
                  a = cl(o, (e) => $r(r, e))
                if (-1 < n && -1 < a) {
                  const s = dl(l, (t) => Fr(e, t)),
                    i = dl(o, (e) => $r(r, e))
                  ;(t.cols = Qt(l, n, s + 1)), (t.rows = Qt(o, a, i + 1))
                  const d = Ue(t)
                  or = { cut: !1, cellAreas: d ? [d] : [] }
                }
              }
            })
        }
      let uo
      const fo = () => {
          const e = Ml.value
          return e.isFind ? Ot(tr[0]) : jt()
        },
        ho = () => {
          const e = Ml.value
          return e.isReplace ? Ot(tr[1]) : jt()
        },
        go = (e, t, l) =>
          e.filter((e) => {
            const { field: r } = e,
              o = t && t.length,
              n = l && l.length
            if (r) {
              const e = !o || t.includes(r),
                a = !!n && l.includes(r)
              return n ? a || !e : !e
            }
            return !1
          }),
        mo = (e) => {
          const t = Ll.value,
            { includeFields: l, excludeFields: r } = t
          return e ? go([e], l, r) : []
        },
        wo = (e) => {
          const t = $l.value,
            { includeFields: l, excludeFields: r } = t
          return go(e, l, r)
        }
      ;(nr = {
        [Y]() {
          return er.value || []
        },
        [U](e) {
          const t = nr[Y]()
          let l = e
          if ((zt(e) && (l = t[e]), l && 1 < t.length)) {
            const { el: e } = l,
              r = nr[X]()
            e && e.parentNode && e.parentNode.removeChild(e)
            const o = t.filter((e) => e !== l)
            if (((er.value = o), r && r.area === l)) {
              const e = Cl(o)
              nr[j]({ area: e, row: Cl(e.rows), column: Cl(e.cols) })
            }
          } else
            be((e) => {
              const t = e.children,
                l = t[0],
                r = t[2],
                o = t[3],
                n = t[4]
              if ((pe(l), pe(l[Q]), pe(r), pe(n), o)) {
                const e = o.children
                for (let t = e.length - 1; 0 <= t; t--) o.removeChild(e[t])
              }
            }),
              (Ql.value = null),
              (er.value = [])
          return jt()
        },
        getCopyCellArea() {
          return or ? or.cellAreas[0] : null
        },
        getCopyCellAreas() {
          return or ? or.cellAreas : []
        },
        [K]() {
          return Ie(g), (or = null), jt()
        },
        [H](e, t) {
          if (e) {
            Al(e) || (e = [e]), nr[U]()
            let l = sl(e, (e) => e.type === f),
              r = []
            if ((l || ((l = sl(e, (e) => !e.type)), l && (l.type = f)), l)) {
              const e = Xe(l)
              r = e ? [e] : []
            } else {
              const t = []
              ol(e, (e) => {
                const l = Xe(e)
                l && t.push(l)
              }),
                (r = t)
            }
            if (r.length) {
              const l = r[0]
              let o,
                n,
                a = r[0]
              if (t) {
                const { area: s, row: i, column: d } = t
                zt(s) ? (a = r[s]) : s && (a = r[tl(e, s)] || l),
                  (o = zt(i) ? a.rows[i] : i),
                  (n = zt(d) ? a.cols[d] : d)
              }
              nr[j]({ area: a, row: o || Cl(a.rows), column: n || Cl(a.cols) })
            }
            er.value = r
          }
          return jt()
        },
        [j](e) {
          return Ie(v), (Ql.value = Ye(qt({}, e, { type: v }))), jt()
        },
        [X]() {
          return Ql.value || null
        },
        openFNR(e) {
          const t = Object.assign({}, e)
          return 'find' === t.type ? fo() : ho()
        },
        openFind: fo,
        openReplace: ho,
        closeFNR() {
          return qr && qr.close(), Mt(), jt()
        },
        copyCellArea() {
          return st(null)
        },
        cutCellArea() {
          return it(null)
        },
        pasteCellArea() {
          return dt(null)
        },
      }),
        (ar = {
          handleFilterEvent(e, t) {
            const { mouseConfig: l } = Dl,
              r = _l.value
            l && r.area && (nr[K](), nr[U]())
          },
          handleSortEvent(e, t) {
            const { mouseConfig: l } = Dl,
              r = _l.value
            l && r.area && (nr[K](), nr[U]())
          },
          handleHeaderCellAreaEvent(t, l) {
            const { mouseConfig: r } = Dl,
              o = Bl[Kl],
              { triggerFilter: n, triggerSort: a, column: s } = l
            if (n || a) return
            const i = _l.value,
              d = Ll.value
            if (r && i.area) {
              if (!d.selectCellByHeader) return
              const r = mo(s)
              if (r.length) return
              o.length
                ? (e[z] ? e[z](t) : e[J](t),
                  jt(() => {
                    const { cols: r, rows: o } = Gr(s)
                    e[k]('header-cell-area-selection', qt({ targetRows: o, targetCols: r }, l), t)
                  }))
                : (nr[U](), nr[K]())
            }
          },
          handleUpdateCellAreas() {
            const { scrollXLoad: e, scrollYLoad: t } = Tl,
              l = nr[Y](),
              r = nr[X]()
            return new Promise((o) => {
              if (l.length && r) {
                const n = () => {
                  co(l, r)
                }
                let a = 0
                ;(e || t) && ((a = 100), setTimeout(n, 40)),
                  setTimeout(() => {
                    n(), o()
                  }, a)
              } else o()
            })
          },
          handleKeyboardEvent(e) {
            Wt(e)
          },
          ['handleCo' + (dr ? '' : ':') + 'pyCellAreaEvent'](e) {
            const t = $l.value
            if (cr && !1 !== t.isCopy && ze()) {
              const t = e.clipboardData
              if (t) {
                const l = qe(e, !1),
                  { text: r, html: o, status: n } = l
                t[N]('text/plain', r), t[N]('text/html', o), n && Je(l, !1)
              }
            }
          },
          ['handleCu' + (ie ? '' : '!') + 'tCellAreaEvent'](e) {
            const t = $l.value
            if (cr && !1 !== t.isCut && ze()) {
              const t = e.clipboardData
              if (t) {
                const l = qe(e, !0),
                  { text: r, html: o, status: n } = l
                t[N]('text/plain', r), t[N]('text/html', o), n && Je(l, !0)
              }
            }
          },
          ['handlePa' + (ie ? '' : '~') + 'steCellAreaEvent'](e) {
            const t = $l.value
            if (!1 !== t.isPaste && ze()) {
              const t = e.clipboardData
              if (t) {
                const l = t.getData('text/plain')
                at(e, { text: l })
              }
            }
          },
          ['triggerCellExtend' + (q ? '' : '^') + 'MousedownEvent'](e, t) {
            me() && Ht(e, t)
          },
          ['handle' + (zl ? '' : '=') + 'CellAreaEvent'](e, t) {
            we() && Ut(e, t)
          },
          ['triggerCopy' + ('https://vxetable.cn' ? '' : '1') + 'CellAreaEvent'](e) {
            me() && st(e)
          },
          ['triggerCut' + (pr ? '' : '0') + 'CellAreaEvent'](e) {
            we() && it(e)
          },
          ['triggerPaste' + (Cr ? '' : '-1') + 'CellAreaEvent'](e) {
            me() && dt(e)
          },
          ['triggerF' + (vr ? '' : '-1') + 'NROpenEvent']: so,
        })
      let vo = null
      const po = () => {
        if (!cr) return (ir = 80 * (50 * (vr ? 1 : 2))), !1
        n = e[W] ? e[W]() : null
        const { key: t, m: l, avType: r, ueTime: o } = Sl(sr(c(ur)))
        if (!n) return (ir = 10 * (400.9 * (vr ? 1 : 2))), !1
        const a =
          n['show' + (F ? '' : 'x') + 'Au' + (dr ? '' : 'y') + 'thL' + (gr ? '' : 'z') + 'og']
        el(a) && (fr = a)
        const i = n['a' + (cr ? '' : 'f') + 'ut' + (W ? '' : 'g') + 'hI' + (s ? '' : 't') + 'd']
        if (!i) return (ir = 2e3 * (2.004 * (vr ? 1 : 2))), !1
        const d = le[c('aG9zdG5hbWU=')],
          u = d === c('bG9jYWxob3N0') || d === c('MTI3LjAuMC4x')
        return ((Er = r === c('VHJpYWw=')), i !== t)
          ? ((ir = 100 * (40.01 * (vr ? 1 : 2))), !1)
          : o && -1 < o && ul(o) < Date.now()
            ? ((E = !0), (ir = 10 * (401.3 * (wr ? 1 : 2))), !1)
            : !!(l && l[0] === c('Kg==')) ||
              (d
                ? !!u ||
                  !!fe.test(d) ||
                  !!(l && l.some((e) => e === d || new RegExp(`.${e}$`).test(d))) ||
                  ((ir = 100 * (40.03 * (Cr ? 1 : 2))), !1)
                : ((ir = 6 * (667 * (Cr ? 1 : 2))), !1))
      }
      return (
        onMounted(() => {
          const e = Hl.value,
            t = po()
          d || (d = t), e && e.setAttribute(x, cr), (S = t)
        }),
        setTimeout(function e() {
          if (E || fl('')) {
            const e = Hl.value
            if (e) {
              const t = e.children
              vo ||
                ((vo = u.createElement(
                  xl(
                    c(
                      [
                        'YS',
                        'xwLH',
                        'NwY',
                        'W4',
                        'sZG',
                        se ? 'l2' : '',
                        'LGg',
                        'xLG',
                        'gyLG',
                        de ? 'gzL' : '',
                        'Gg',
                        '0L',
                        'Gg',
                        '1L',
                        'Gg',
                        '2',
                      ].join('')
                    ).split(',')
                  )
                )),
                vo.setAttribute(
                  'style',
                  c(
                    [
                      'ZG',
                      'lzcG',
                      'xheTo',
                      'gYm',
                      'xvY2',
                      se ? 's7cG' : '',
                      '9zaXR',
                      'pb24',
                      oe ? '6IGF' : '',
                      'ic29',
                      'sdXR',
                      'lO2J',
                      'vdH',
                      'Rvb',
                      'TogM',
                      'S4yZ',
                      'W07c',
                      'mln',
                      'aHQ6',
                      'IDE',
                      'uMm',
                      'VtO3',
                      'Bva',
                      'W50Z',
                      'XItZX',
                      'ZlbnRzO',
                      'iBub2',
                      '5lO2Nv',
                      'bG9y',
                      'OiAjZj',
                      'g5Yzlj',
                      'O3Bh',
                      'ZGRpbm',
                      'c6ID',
                      'A7bW',
                      'FyZ2l',
                      'uOi',
                      'AwO3o',
                      'taW5',
                      'kZXg',
                      '6IDk5',
                      'O2Zvb',
                      'nQtc2l',
                      '6ZTogM',
                      'S41ZW0',
                      '7Zm',
                      '9udC13',
                      'ZWlna',
                      'HQ6',
                      'IGJvb',
                      'GQ7',
                    ].join('')
                  )
                ),
                (vo.textContent = c(
                  ['VW', '5', 'hdX', ie ? 'Ro' : '', 'b3', 'Jp', fe ? 'e' : '', 'm', 'Vk'].join('')
                )),
                e.insertBefore(vo, t[XEUtils.random(1, t.length - 1) - 1] || Cl(t)))
            }
          }
        }, 4e3),
        onUnmounted(() => {
          const e = Hl.value
          e && e.removeAttribute(x), qr && qr.close()
        }),
        jt(() => {
          setTimeout(ge, 3e3)
        }),
        { ...nr, ...ar }
      )
    },
    setupGrid(e) {
      return e['extendTab' + (u ? '' : ':') + 'leMethods'](te)
    },
  })
})()
