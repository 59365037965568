export default {
  '签约航线': 'Signed route',
  '国家/地区下拉邮编': 'Drop-down Postcode List',
  'DDU产品管理': 'DDU Product Management',
  'DDU菜单管理': 'DDU Menu Management',
  '邮编对应机场': 'Recommended Airport- POD Postcode',
  'DDP门到门交货地': 'Delivery Place of DDP Door to Door',
  'DDU批量运价过滤': 'Batch Freight Rate Filtering',
  'DDU后段费用': 'Charge of Posterior Segment',
  // 邮编: 'Postcode',
  '邮编': 'zipcode',
  '机场代码': 'airportcode',
  '机场中文名称': 'airportCn',
  '机场英文名称': 'airportEn',
  '邮编区间起值': 'zipcodestart',
  '邮编区间止值': 'zipcodeend',
  '生效位数': 'Effective digit',
  'DDP交货地': 'DDP delivery palce',
  '交货地': 'delivery palce',
  'DDU派送费': 'DDU Delivery Charge',
  'DDU本地费': 'DDU Local  Charge',
  '国家代理代码': 'Country Agent Code',
  '代理代码': 'Agent Code',
  '代理备注': 'Agent note',
  '代理英文名': 'Agent English ',
  '港口三字码': 'three code of port',
  '计费重比例': 'Billing weight ratio',
  '金额': 'Amount',
  '最低费用': 'Min',
  '查看配置信息': 'Viewing Config',
  '代理': 'Agent',
  '港口': 'Port',
  '计费模式': 'charging mode',
  '派送范围': 'Delivery scope',
  '代理客户': 'agent',
  '是否禁用': 'Forbidden or not',
  '区间段': 'Config',
  '首续重': 'First weight',
  '产品名称': 'Product Name',
  '产品代码': 'Product Code',
  '材积': 'volume',
  '国家备注': 'country notes',
  '产品备注': 'product  notes',
  '最快时效': 'fast ageing',
  '最慢时效': 'slow ageing',
  '批量处理备注': 'Batch processing notes',
  '暂无数据': 'No data',
  '价格过滤': 'Tariff filtering',
  '最快到达天数': 'ArrviedDays',
  '路径': 'Route',
  '转运备注': 'Transport notes',
  '未签约航线': 'not signed',
  '已签约航线': 'signed',
  '批量运价过滤': 'Batch Freight Rate Filtering',
  'DDU运价过滤': 'Freight Rate Filtering',
  '全部启用': 'all start using',
  '全部禁用': 'all disabled',
  '排仓时间': 'Warehouse arrangement ',
  '重量段': 'Weight segment',
  '单票': 'By ticket',
  '首重': 'First weight',
  '大于': 'more than',
  '等于': 'equal',
  '小于': 'less than',
  '时限': 'time limit',
  '产品名称备注': 'product notes',
  '产品': 'Product',
  '价格有效日期': 'Pricing Validity',
  '另行通知': 'until  further notice',
  '以上报价已含燃油、战险、地勤，不含杂费':
    'The above quotation includes fuel, war insurance, ground handling, and does not include miscellaneous fees',
  '至': 'To',
  '第': 'No.',
  '有输入项': 'has entry',
  '请不要输入非数字': 'Please do not enter non-numbers',
  '请不要输入非法字符': 'Please do not enter illegal characters',
  '有必填项未填写': 'There are required items not filled',
  '启用': 'using',
  '禁用': 'disabled',
  '区域地址': 'RegionalAddress',
  'DDU整柜派送费': 'DDU FCL Delivery',
  '整柜派送费成本': 'FCL Delivery Cost',
  '整柜派送费卖价': 'FCL Delivery FEE Price',
  '整柜无关应收本地费用': 'AR FCL Local Charge (customized)',
  '整柜相关应收本地费用': 'AP FCL  Local Charge (standard)',
  '整柜提货费成本': 'FCL Pick-up Cost Charge',
  '海运整柜提货费': 'Sea FCL Pick-up Charge',
  '整柜FOB提货费卖价': 'FCL FOB Pick-up Cost Charge',
  '整柜CIF提货费卖价': 'FCL CIF Pick-up Cost Charge',
  '始发港口': 'POL',
  '贸易条款': 'Trade Terms',
  '整柜DDU目的港本地费用': 'DDU FCL POD Local Fee',
}
