export default {
  '首页': '首页',
  '看板': '看板',
  '工作台': '工作台',
  '仓库': '仓库',
  '更新日志': '更新日志',
  '配置': '配置',
  '无分栏': '无分栏',
  '个人中心': '个人中心',
  '用户管理': '用户管理',
  '角色管理': '角色管理',
  '部门管理': '部门管理',
  '菜单管理': '菜单管理',
  '任务管理': '任务管理',
  '系统日志': '系统日志',
  '组件': '组件',
  '其他': '其他',
  '角色权限': '角色权限',
  '外链': '外链',
  '图标': '图标',
  '常规图标': '常规图标',
  '小清新图标': '小清新图标',
  '图标选择器': '图标选择器',
  '表格': '表格',
  '综合表格': '综合表格',
  '行内编辑表格': '行内编辑表格',
  '自定义表格': '自定义表格',
  '动态表格': '动态表格',
  '描述': '描述',
  '日历': '日历',
  '表单': '表单',
  '综合表单': '综合表单',
  '分步表单': '分步表单',
  '按钮': '按钮',
  '文字链接': '文字链接',
  '单选框': '单选框',
  '多选框': '多选框',
  '输入框': '输入框',
  '计数器': '计数器',
  '选择器': '选择器',
  '开关': '开关',
  '滑块': '滑块',
  '时间选择器': '时间选择器',
  '日期选择器': '日期选择器',
  '日期时间选择器': '日期时间选择器',
  '评分': '评分',
  '工作流': '工作流',
  '图表': '图表',
  '打印': '打印',
  '手机预览': '手机预览',
  '头像裁剪': '头像裁剪',
  '时间线': '时间线',
  '数字自增长': '数字自增长',
  '多标签': '多标签',
  '分享': '分享',
  '动态锚点': '动态锚点',
  '动态Meta': '动态Meta',
  '动态路径参数': '动态路径参数',
  '列表': '列表',
  '多级路由缓存': '多级路由缓存',
  '多级路由1-1': '多级路由1-1',
  '多级路由1-1-1': '多级路由1-1-1',
  '拖拽': '拖拽',
  '卡片拖拽': '卡片拖拽',
  '无框': '无框',
  '加载': '加载',
  '视频播放器': '视频播放器',
  '编辑器': '编辑器',
  '富文本编辑器': '富文本编辑器',
  '腾讯文档': '腾讯文档',
  '上传': '上传',
  '错误日志模拟': '错误日志模拟',
  'Excel': 'Excel',
  '导出Excel': '导出Excel',
  '导出选中行Excel': '导出选中行Excel',
  '导出合并Excel': '导出合并Excel',
  'Css动画': 'Css动画',
  '第三方登录': '第三方登录',
  '物料源': '物料源',
  '物料市场': '物料市场',
  '错误页': '错误页',
  '工具': '工具',
  '403': '403',
  '404': '404',
  '邮件': '邮件',
  '清空消息': '清空消息',
  '更多': '更多',
  '关闭其他': '关闭其他',
  '关闭左侧': '关闭左侧',
  '关闭右侧': '关闭右侧',
  '关闭全部': '关闭全部',
  '退出登录': '退出登录',
  '登录': '登录',
  '验证码': '验证码',
  '注册': '注册',
  '主题配置': '主题配置',
  '主题': '主题',
  '常用设置': '常用设置',
  '其它设置': '其它设置',
  '分栏布局时生效': '分栏布局时生效',
  '标签开启时生效': '标签开启时生效',
  '布局配置仅在电脑视窗下生效，手机视窗时将默认锁定为纵向布局':
    '布局配置仅在电脑视窗下生效，手机视窗时将默认锁定为纵向布局',
  '支持纵向布局、分栏布局、综合布局、常规布局，不支持横向布局、浮动布局':
    '支持纵向布局、分栏布局、综合布局、常规布局，不支持横向布局、浮动布局',
  '默认': '默认',
  '海洋之心': '海洋之心sss',
  '绿荫草场': '绿荫草场',
  '碰触纯白': '碰触纯白',
  '月上重火': '月上重火',
  '蓝黑': '蓝黑',
  '蓝白': '蓝白',
  '绿黑': '绿黑',
  '绿白': '绿白',
  '红黑': '红黑',
  '红白': '红白',
  '渐变': '渐变',
  '布局': '布局',
  '分栏': '分栏',
  '纵向': '纵向',
  '横向': '横向',
  '综合': '综合',
  '常规': '常规',
  '浮动': '浮动',
  '菜单背景': '菜单背景',
  '菜单宽度': '菜单宽度',
  '分栏风格': '分栏风格',
  '箭头': '箭头',
  '头部固定': '头部固定',
  '固定': '固定',
  '不固定': '不固定',
  '标签': '标签',
  '标签风格': '标签风格',
  '标签图标': '标签图标',
  '卡片': '卡片',
  '灵动': '灵动',
  '圆滑': '圆滑',
  '国际化': '国际化',
  '进度条': '进度条',
  '刷新': '刷新',
  '通知': '通知',
  '全屏': '全屏',
  '锁屏': '锁屏',
  '页面动画': '页面动画',
  '搜索': '搜索',
  '开启': '开启',
  '关闭': '关闭',
  '随机换肤': '随机换肤',
  '购买源码': '购买源码',
  '拷贝源码': '拷贝源码',
  '清理缓存': '清理缓存',
  '恢复默认': '恢复默认',
  '保存': '保存',
  '欢迎来到': '欢迎来到',
  '请输入用户名': '请输入用户名',
  '请输入密码': '请输入密码',
  '用户名不能为空': '用户名不能为空',
  '密码不能少于6位': '密码不能少于6位',
  '请输入正确的手机号': '请输入正确的手机号',
  '请输入手机号': '请输入手机号',
  '请输入手机验证码': '请输入手机验证码',
  '获取验证码': '获取验证码',
  '屏幕已锁定': '屏幕已锁定',
  '解锁': '解锁',
  '切换壁纸': '切换壁纸',
  '修改密码': '修改密码',
  '列表高度': '列表高度',
  '新增': '新增',
  '删除': '删除',
  '导入': '导入',
  '导出': '导出',
  '下载模板': '下载模板',
  '查询': '查询',
  '重置': '重置',
  '操作': '操作',
  '修改': '修改',
  '件数': '件数',
  '重量': '重量',
  '体积': '体积',
  '体积重': '体积重',
  '分泡比例': '分泡比例',
  '分泡计费重': '分泡计费重',
  '添加': '添加',
  '清空': '清空',
  '长(CM)': '长(CM)',
  '宽(CM)': '宽(CM)',
  '高(CM)': '高(CM)',
  '算泡工具': '算泡工具',
  '已选中': '已选中',
  '条': '条',
  '数据加载中': '数据加载中',
  '已选择': '已选择',
  '空数据': '空数据',
  '请输入': '请输入',
  '请选择': '请选择',
  '不能输入中文': '不能输入中文',
  '列设置': '列设置',
  '宽松': '宽松',
  '舒适': '舒适',
  '紧凑': '紧凑',
  '反选': '反选',
  '展开': '展开',
  '收起': '收起',
  '最多打开20个标签页': '最多打开20个标签页',
  '删除成功': '删除成功',
  '是否确认删除？': '是否确认删除？',
  '暂无搜索结果': '暂无搜索结果',
  '字段信息': '字段信息',
  '系统字段': '系统字段',
  '已选字段': '已选字段',
  '自定义列清空': '清空',
  '自定义列删除': '删除',
  '自定义列置顶': '置顶',
  '自定义列固定': '固定',
  '最多可固定7列': '最多可固定7列',
  '置顶': '置顶',
  '自定义列': '自定义列',
  '全选': '全选',
  '加载中': '加载中',
  '无数据': '无数据',
  '计算结果': '计算结果',
  '计算方式': '计算方式',
  '请先勾选数据并且价格只能输入小数点前十位，后两位！':
    '请先勾选数据并且价格只能输入小数点前十位，后两位！',
  '更新提示': '更新提示',
  '重新加载': '重新加载',
  '保存成功': '保存成功',
  '新内容可用，点击重新加载按钮更新': '新内容可用，点击\'重新加载\'按钮更新',
}
